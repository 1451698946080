import { FileUploaderService } from './../../../../services/file-uploader.service';
import { environment } from './../../../../../environments/environment';
import { ActivatedRoute } from '@angular/router';
import { News } from './../../../../shared/models/news/news';
import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { NewsService } from 'src/app/services/news.service';
import { NotificationService } from 'src/app/services/notification.service';
import { NotificationMessages } from 'src/app/shared/constants/notification-messages';
import { EntityType } from 'src/app/shared/enums/entity-type';
import * as Editor from 'src/app/shared/ckeditor/build/ckeditor';
import { UploadAdapter } from 'src/app/shared/helpers/upload-adapter';
import { EditorConfig } from 'src/app/shared/constants/editor-config';

@Component({
  selector: 'app-edit-news',
  templateUrl: './edit-news.component.html',
  styleUrls: ['./edit-news.component.css'],
})
export class EditNewsComponent implements OnInit {
  loadingData: boolean;
  editForm: FormGroup;
  newsId: string;
  news: News;
  newsImagePath: string;

  public Editor = Editor;

  public get editorConfig(): typeof EditorConfig{
    return EditorConfig;
  }

  constructor(
    private newsService: NewsService,
    private notificationService: NotificationService,
    private fb: FormBuilder,
    private uploadService: FileUploaderService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.listenToImageUpload();
  }

  listenToImageUpload() {
    this.uploadService.listenImageUpload().subscribe((response: any) => {
      this.getNews();
    },
      (error) => {
        this.notificationService.errorMessage(NotificationMessages.SYNC_ERROR);
      }
    );
  }

  public get entityType(): typeof EntityType {
    return EntityType;
  }

  ngOnInit() {
    this.loadingData = true;
    this.getNews();
  }

  getNews(): void {
    this.newsId = this.route.snapshot.paramMap.get('id');

    this.newsService.getSingleNews(this.newsId).subscribe(
      (response: News) => {
        this.news = response as News;
        this.setEditForm();
      },
      (error) => {
        this.notificationService.errorMessage(NotificationMessages.GET_ERROR);
      }
    );
  }

  onReady(eventData) {
    eventData.plugins.get(
      'FileRepository'
    ).createUploadAdapter = function (loader: { file: string }) {
      return new UploadAdapter(loader);
    };
  }

  setEditForm(): void {
    this.newsImagePath = environment.apiImagesPath + this.news.featuredImage;

    this.editForm = this.fb.group({
      title: new FormControl(this.news.title, Validators.required),
      content: new FormControl(this.news.content, Validators.required),
      featuredImage: new FormControl(this.newsImagePath),
    });

    this.loadingData = false;
  }

  onSubmit(): void {
    this.loadingData = true;

    this.newsService.editNews(this.newsId, this.editForm.value).subscribe(
      (response: any) => {
        this.notificationService.successMessage(
          NotificationMessages.UPDATE_SUCCESS
        );
        this.router.navigateByUrl('/admin/news');
      },
      (error) => {
        this.notificationService.errorMessage(
          NotificationMessages.UPDATE_ERROR
        );
      }
    );

    this.loadingData = false;
  }
}
