import { NotificationMessages } from 'src/app/shared/constants/notification-messages';
import { NotificationService } from '../../../../services/notification.service';
import { Component, Input, OnInit } from '@angular/core';
import {
  FormGroup,
  FormBuilder, 
  FormControl,
  Validators,
} from '@angular/forms';
import { ResearchProblemService } from 'src/app/services/research-problem.service';
import { EntityType } from 'src/app/shared/enums/entity-type';
import { ResearchProblem } from 'src/app/shared/models/research-problem/research-problem';
  
@Component({
  selector: 'app-create-research-problem',
  templateUrl: './create-research-problem.component.html',
  styleUrls: ['./create-research-problem.component.css'],
})
export class CreateResearchProblemComponent implements OnInit {
  @Input() entityType: EntityType;
  @Input() entityId: string;

  createForm: FormGroup;
  loadingData: boolean;

  constructor(
    private fb: FormBuilder,
    private researchProblemService: ResearchProblemService,
    private notificationService: NotificationService
  ) {}

  ngOnInit() {
    this.loadingData = true;
    this.setCreateForm();
  }

  setCreateForm(): void {
    this.createForm = this.fb.group({
      title: new FormControl('', Validators.required),
      description: new FormControl('', Validators.required),
      categories: new FormControl([], Validators.required),
      });

    this.loadingData = false;
  }

  onSubmit(): void {
    this.loadingData = true;

    this.prepareCategoriesBeforeSubmit();

    this.researchProblemService
      .createResearchProblem(
        this.createForm.value,
        this.entityType,
        this.entityId
      )
      .subscribe(
        (response: ResearchProblem) => {
          this.notificationService.successMessage(
            NotificationMessages.CREATE_SUCCESS
          );
          this.researchProblemService.pingNewResearchProblem();

          this.createForm.reset();
        },
        (error) => {
          this.notificationService.errorMessage(
            NotificationMessages.CREATE_ERROR
          );
        }
      );

    this.loadingData = false;
  }

  prepareCategoriesBeforeSubmit(): void {
    const realCategories = this.createForm
      .get('categories')
      .value.map((x) => x.id);
    this.createForm.get('categories').setValue(realCategories);
  }
}
