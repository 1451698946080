import { DomSanitizer } from '@angular/platform-browser';
import { AuthService } from './../../../services/auth.service';
import { EntityType } from 'src/app/shared/enums/entity-type';
import { MatDialog } from '@angular/material';
import { PublicService } from 'src/app/services/public.service';
import { Component, OnInit } from '@angular/core';
import { Company } from 'src/app/shared/models/company/company';
import { ActivatedRoute } from '@angular/router';
import { NotificationService } from 'src/app/services/notification.service';
import { NotificationMessages } from 'src/app/shared/constants/notification-messages';
import { trigger, transition, useAnimation } from '@angular/animations';
import { FadeAnimation } from 'src/app/shared/animations/fade-animation';
import { SendMessageComponent } from 'src/app/components/send-message/send-message.component';
import { WarningWindowComponent } from 'src/app/components/warning-window/warning-window.component';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-company-details',
  templateUrl: './company-details.component.html',
  styleUrls: ['./company-details.component.css'],
  animations: [
    trigger('fadeAnimation', [
      transition('void => *', [useAnimation(FadeAnimation)]),
    ]),
  ],
})
export class CompanyDetailsComponent implements OnInit {
  companyId: string;
  company: Company;

  userLogged: boolean;
  loadingData: boolean;

  constructor(
    private route: ActivatedRoute,
    private notificationService: NotificationService,
    private publicService: PublicService,
    private authService: AuthService,
    private dialog: MatDialog,
    private sanitizer: DomSanitizer,
    private titleService: Title
  ) {}

  ngOnInit(): void {
    this.loadingData = true;
    this.getCompany();
  }

  openSendMessage(): void {
    return this.userLogged ? this.openContactDialog() : this.openInfoDialog();
  }

  openInfoDialog(): void {
    this.dialog.open(WarningWindowComponent);
  }

  openContactDialog(): void {
    const dialogRef = this.dialog.open(SendMessageComponent, {
      panelClass: 'send-message-dialog',
    });

    dialogRef.componentInstance.receiverId = this.company.id;
    dialogRef.componentInstance.receiverType = EntityType[0].toString();

    dialogRef.afterClosed().subscribe((result) => {
      this.loadingData = true;
      this.getCompany();
    });
  }

  getCompany(): void {
    this.companyId = this.route.snapshot.paramMap.get('id');

    this.publicService.getCompany(this.companyId).subscribe(
      (company: Company) => {
        this.company = company as Company;
        this.userLogged = this.authService.userLogged();

        this.sanitizer.bypassSecurityTrustHtml(this.company.toString());

        this.titleService.setTitle(this.company.name + ' | RIMAP');

        this.loadingData = false;
      },
      (error) => {
        this.notificationService.errorMessage(NotificationMessages.GET_ERROR);
      }
    );
  }
}
