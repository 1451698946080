
<app-loader *ngIf="loadingData"></app-loader>

<div [formGroup]="parentForm" *ngIf="!loadingData">
  <mat-form-field appearance="outline"> 
    <mat-label>Odaberite pripadne kategorije</mat-label>
    <mat-select formControlName="categories" multiple (selectionChange)="onSelectedCategory()">
      <mat-option *ngFor="let category of categoriesList" [value]="category.id">
        {{
            category.name
        }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>

