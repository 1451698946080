import { VisibilityDialogComponent } from './../../../../components/visibility-dialog/visibility-dialog.component';
import { Router } from '@angular/router';
import { NotificationMessages } from 'src/app/shared/constants/notification-messages';
import { NotificationService } from './../../../../services/notification.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { News } from 'src/app/shared/models/news/news';
import { MatDialog, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { NewsService } from 'src/app/services/news.service';
import { DeleteDialogComponent } from 'src/app/components/delete-dialog/delete-dialog.component';

const COLUMNS: string[] = [
  'edit-buttons',
  'title',
  'created-at',
  'numberOfViews',
  'delete-buttons'
];

@Component({
  selector: 'app-news-list',
  templateUrl: './news-list.component.html',
  styleUrls: ['./news-list.component.css'],
})

export class NewsListComponent implements OnInit {

  loadingData: boolean;
  displayedColumns: string[] = COLUMNS;
  news: News[];
  dataSource: MatTableDataSource<News[]>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private router: Router,
    private newsService: NewsService,
    private notificationService: NotificationService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.loadingData = true;
    this.getNews();
  }

  initPaginator() {
    this.paginator._intl.itemsPerPageLabel = 'Rezultata po stranici';
    this.paginator._intl.nextPageLabel = 'Slijedeća stranica';
        this.paginator._intl.previousPageLabel = 'Prethodna stranica';
    this.paginator._intl.getRangeLabel = (
      page: number,
      pageSize: number,
      length: number
    ) => {
      const start = page * pageSize + 1;
      const end = (page + 1) * pageSize;
      return `${start} - ${end} od ${this.paginator.length}`;
    };
    this.paginator._intl.getRangeLabel = (
      page: number,
      pageSize: number,
      length: number
    ) => {
      const start = page * pageSize + 1;
      const end = (page + 1) * pageSize;
      return `${start} - ${end} od ${this.paginator.length}`;
    };
  }


  getNews(): void {
    this.newsService.getNews().subscribe((news: any) => {
      this.dataSource = new MatTableDataSource<News[]>(news);
      this.loadingData = false;
      this.dataSource.paginator = this.paginator;
      this.initPaginator();
      this.dataSource.sort = this.sort;
    },
      (error) => {
        this.notificationService.errorMessage(NotificationMessages.GET_ERROR);
      }
    );
  }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  goToEditPage(newsId: string): void {
    this.router.navigateByUrl('/admin/news/edit/' + newsId);
  }

  openVisibilityDialog(newsId: string): void {
    const dialogRef = this.dialog.open(VisibilityDialogComponent);

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.loadingData = true;
        this.flipActiveNews(newsId);
        this.notificationService.successMessage(
          NotificationMessages.UPDATE_SUCCESS
        );
      } else {
        this.notificationService.errorMessage(
          NotificationMessages.UPDATE_ERROR
        );
      }
    });
  }

  goToCreatePage(): void {
    this.router.navigateByUrl('/admin/news/create');
  }

  flipActiveNews(newsId: string): void {
    this.newsService.flipNewsActive(newsId).subscribe(
      (response: any) => {
        this.getNews();
      },
      (error) => {
        this.notificationService.errorMessage(NotificationMessages.GET_ERROR);
      }
    );
  }
}
