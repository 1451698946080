
<h1 mat-dialog-title>Zaboravljena lozinka?</h1>

<form [formGroup]="resetPasswordForm" (submit)="onSubmit()">  
<div mat-dialog-content>
  <p>Molimo da u donje polje upišite točan naziv<br>
    svoje email adrese koju koristite za prijavu u sustav<br>
    Kako bismo Vam mogli poslati poveznicu za prijavu.</p>
    <p>
        <b>Preporuka:</b>     
        Nakon prijave u sustav putem <br>  
        poveznice promijenite svoju lozinku.
    </p>

  <mat-form-field appearance="outline">
    <mat-label>Email</mat-label>
    <input matInput formControlName="email">
    <mat-error>Email adresa je obavezna</mat-error>
  </mat-form-field>
</div>
<div mat-dialog-actions align="end">
  <button type="button" mat-flat-button color="warn" (click)="onNoClick()">Odustani</button>
  <button mat-flat-button color="primary" [disabled]="resetPasswordForm.invalid" cdkFocusInitial>Pošalji email!</button>
</div>
</form>