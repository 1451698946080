import { SpecificKnowledge } from './../shared/models/specific-knowledge/specific-knowledge';
import { ResearchProblem } from 'src/app/shared/models/research-problem/research-problem';
import { EntityType } from '../shared/enums/entity-type';
import {
  HttpClient,
  HttpRequest,
  HttpEvent,
  HttpEventType,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map, tap, last } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class FileUploaderService {
  public progressSource = new BehaviorSubject<number>(0);

  newImageUpload = new Subject<any>();

  constructor(private http: HttpClient) {}

  upload(
    file: File,
    entityType: EntityType,
    entityId: string,
    parentEntityType: EntityType,
    parentEntityId: string
  ) {
    const formData = new FormData();
    formData.append('Image', file);

    const url = this.getUrlForFileUpload(
      entityType,
      entityId,
      parentEntityType,
      parentEntityId
    );

    const req = new HttpRequest('POST', url, formData, {
      reportProgress: true,
    });

    return this.http.request(req).pipe(
      map((event) => this.getEventMessage(event, file)),
      tap((envelope: any) => this.processProgress(envelope)),
      last()
    );
  }

  getUrlForFileUpload(
    entityType: EntityType,
    entityId: string,
    parentEntityType: EntityType,
    parentEntityId: string
  ): string {
    switch (entityType) {
      case EntityType.Scientist:
        return environment.apiUrl + '/scientists/addImage/' + entityId;
      case EntityType.Company:
        return environment.apiUrl + '/companies/addImage/' + entityId;
      case EntityType.PublicOrganization:
        return environment.apiUrl + '/publicOrganizations/addImage/' + entityId;
      case EntityType.News:
        return environment.apiUrl + '/news/addImage/' + entityId;
      case EntityType.Equipment:
        return (
          environment.apiUrl +
          '/scientists/' +
          parentEntityId +
          '/addImageForEquipment/' +
          entityId
        );
      case EntityType.ResearchProblem:
        return (
          environment.apiUrl +
          this.getParentEntityUrl(parentEntityType) +
          parentEntityId +
          '/addImageForResearchProblem/' +
          entityId
        );
      case EntityType.JobOffer:
        return (
          environment.apiUrl +
          '/companies/' +
          parentEntityId +
          '/addImageForJobOffer/' +
          entityId
        );
      case EntityType.SpecificKnowledge:
        return (
          environment.apiUrl +
          '/scientists/' +
          parentEntityId +
          '/addImageForSpecificKnowledge/' +
          entityId
        );
    }
  }

  getParentEntityUrl(entityType: EntityType): string {
    switch (entityType) {
      case EntityType.PublicOrganization:
        return '/publicOrganizations/';
      case EntityType.Company:
        return '/companies/';
    }
  }

  pingImageUploaded() {
    this.newImageUpload.next({ success: true });
  }

  listenImageUpload(): Observable<any> {
    return this.newImageUpload.asObservable();
  }

  processProgress(envelope: any): void {
    if (typeof envelope === 'number') {
      this.progressSource.next(envelope);
    }
  }

  private getEventMessage(event: HttpEvent<any>, file: File) {
    switch (event.type) {
      case HttpEventType.Sent:
        return `Učitavanje datoteke '${file.name}' veličine ${file.size}.`;
      case HttpEventType.UploadProgress:
        return Math.round((100 * event.loaded) / event.total);
      case HttpEventType.Response:
        return `Datoteka '${file.name}' je uspješno učitana!`;
    }
  }
}
