<!-- Call to Action -->
<section class="call-to-action newsletter">
  <div class="overlay"></div>
  <div class="container">
    <div class="row newsletter-container">
      <div class="col-xl-9 mx-auto">
        <h2 class="mb-4">Prijavite se na email obavijesti</h2>
        <p>Pretplatom na naš newsletter bit ćete obavještavani o novim registriranim tvrtkama, istraživačima i javnim
          organizacijama kao i novim razvojnim prijedlozima, opremi i korisnim obavijestima na platformi u svrhu
          poticanja poslovnog umrežavanja.</p>
      </div>
      <div class="mx-auto">
        <app-new-subscriber></app-new-subscriber>
      </div>
    </div>  
  </div>
</section>

<!-- Footer -->
<footer>
  <div class="container">
    <div class="impressum h-100 text-center text-lg-left mb-4">
      <a class="logo-rimap" href="https://rimap.uniri.hr/">
        <img src="assets/logo/rimap-logo-bijeli.svg" />
      </a>
      <a class="logo-uniri" href="https://uniri.hr/">
        <img src="assets/logo/uniri-logo.svg" />
      </a>
      <p class="opis">
        Regional innovation matchmaking platform powered by 
        <a href="https://uniri.hr/" target="_blank" class="uniri-link">UNIRI</a>.
      </p>
      <p class="kontakt"><b>Kontakt: </b>
        <a href="mailto:rimap@uniri.hr">rimap@uniri.hr</a></p>
    </div>
    <div class="links h-100 text-center text-lg-left">
      <h2>Korisne poveznice</h2>
      <ul>
        <li><a href="#">Naslovna</a></li>
        <li><a [routerLink]="['/about']">O nama</a></li>
        <li><a [routerLink]="['/news']">Novosti</a></li>
        <li><a [routerLink]="['/user-login']">Prijava</a></li>
        <li><a [routerLink]="['/registration']">Registracija</a></li>
      </ul>
    </div>
    <div class="links-pregled h-100 text-center text-lg-left">
      <h2>Pregled</h2>
      <ul>
        <li><a [routerLink]="['/companies']">Tvrtki</a></li>
        <li><a [routerLink]="['/scientists']">Istraživača</a></li>
        <li><a [routerLink]="['/public-organizations']">Javnih organizacija</a></li>
        <li><a [routerLink]="['/companies/research-problems']">Razvojnih prijedloga tvrtki</a></li>
        <li><a [routerLink]="['/public-organizations/research-problems']">Razvojnih prijedloga javnog sektora</a></li>
        <li><a [routerLink]="['/companies/job-offers']">Potreba za ljudskim resursima</a></li>
        <li><a [routerLink]="['/scientists/equipment']">Opreme</a></li>
        <li><a [routerLink]="['/scientists/specific-knowledges']">Usluga i kompetencija</a></li>
      </ul>
    </div>
    <p class="copy">© 2021, Sva prava pridržana | RIMAP</p>
  </div>
</footer>