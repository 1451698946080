import { trigger, transition, useAnimation } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { FadeAnimation } from 'src/app/shared/animations/fade-animation';

@Component({
  selector: 'app-thank-you',
  templateUrl: './thank-you.component.html',
  styleUrls: ['./thank-you.component.css'],
  animations: [
    trigger('fadeAnimation', [
      transition('void => *', [useAnimation(FadeAnimation)]),
    ]),
  ],
})
export class ThankYouComponent implements OnInit {

  constructor(private titleService: Title) {
    this.titleService.setTitle('Hvala | RIMAP');
  }

  ngOnInit(): void {
  }

}
