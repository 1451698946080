<header class="masthead header-public-organizations">
    <div class="container-fluid h-100">
        <div class="row h-100 align-items-center">
            <div class="container text-center">
                <h1 class="page-header-title">Pretraga javnog sektora</h1>
            </div>
        </div>
    </div>
</header>

<div class="container">
    <section class="organizacije pb-5">
        <div class="container">


            <div class="d-flex justify-content-center">
                <mat-form-field appearance="outline">
                    <input matInput type="text" autocomplete="off" (keyup)="applyFilter($event)"
                        placeholder="Pretraga...">
                </mat-form-field>
            </div>

            <div class="d-flex justify-content-center">
                <div [formGroup]="searchForm">
                    <app-category-search-picker [parentForm]="searchForm"></app-category-search-picker>
                </div>
            </div>

            <app-loader *ngIf="loadingData"></app-loader>

            <div class="row">
                <div [hidden]="loadingData" [@fadeAnimation]
                    *ngFor="let publicOrganization of publicOrganizations | async" class="col-xs-12">

                    <div class="image-flip">
                        <div class="mainflip flip-0">
                            <div class="frontside">
                                <div class="card">
                                    <div class="card-body text-left">
                                        <p>
                                            <a (click)="openPublicOrganization(publicOrganization.id)">
                                                <img class=" img-fluid entity-profile-picture"
                                                    [src]="publicOrganization.logo || 'assets/logo/image-default.png'"
                                                    alt="card image">
                                            </a>
                                        </p>
                                        <h3 class="card-title">
                                            <a (click)="openPublicOrganization(publicOrganization.id)">
                                                {{publicOrganization.name}}
                                            </a>
                                        </h3>
                                        <div class="card-text">
                                            <p class="news-text-time">
                                                <span *ngIf="publicOrganization.categoryTags?.length === 0">-</span>
                                                <span *ngIf="publicOrganization.categoryTags?.length > 0">
                                                    <mat-chip-list>
                                                        <mat-chip
                                                            *ngFor="let tag of publicOrganization.categoryTags?.split(';').slice(0, -1) | slice:0:3">
                                                            {{tag}}
                                                        </mat-chip>
                                                    </mat-chip-list>
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="backside">
                                <div class="card">
                                    <div class="card-body text-left ">
                                        <div class="card-text">
                                            <p class="adresa" *ngIf="publicOrganization.address !== ''">
                                                <strong>Adresa:</strong>
                                                {{publicOrganization.address}}
                                            </p>
                                            <p class="web" *ngIf="publicOrganization.website !== ''">
                                                <strong>Web stranica: </strong>
                                                <a [href]="publicOrganization.website">{{publicOrganization.website}}</a>
                                            </p>
                                            <div class="card-text">
                                                <p class="razvojni" *ngIf="publicOrganization.researchProblems?.length > 0">
                                                    <strong>Razvojni prijedlozi: </strong>
                                                    <span>{{publicOrganization.researchProblems?.length}}</span>
                                                </p>
                                            </div>
                                        </div>
                                        <button color="primary" (click)="openPublicOrganization(publicOrganization.id)"
                                            mat-raised-button>
                                            Više detalja
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <mat-paginator #publicOrganizationsPaginator [pageSizeOptions]="[6, 9, 12, 15]"></mat-paginator>
        </div>
    </section>
</div>