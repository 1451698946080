import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-public-organizations',
  templateUrl: './public-organizations.component.html',
  styleUrls: ['./public-organizations.component.css']
})
export class PublicOrganizationsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
