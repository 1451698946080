import { DateAdapter, MatDialogRef } from '@angular/material';
import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { JobOfferService } from 'src/app/services/job-offer.service';
import { NotificationService } from 'src/app/services/notification.service';
import { PlaceService } from 'src/app/services/place.service';
import { NotificationMessages } from 'src/app/shared/constants/notification-messages';
import { JobOffer } from 'src/app/shared/models/job-offer/job-offer';
import { Place } from 'src/app/shared/models/place/place';

@Component({
  selector: 'app-create-job-offer-dialog',
  templateUrl: './create-job-offer-dialog.component.html',
  styleUrls: ['./create-job-offer-dialog.component.css']
})
export class CreateJobOfferDialogComponent implements OnInit {

  @Input() companyId: string;

  createForm: FormGroup;
  loadingData: boolean;

  places: Place[];

  constructor(
    private fb: FormBuilder,
    private jobOfferService: JobOfferService,
    private notificationService: NotificationService,
    private dialogRef: MatDialogRef<CreateJobOfferDialogComponent>,
    private placeService: PlaceService,
    private dateAdapter: DateAdapter<any>
  ) {}

  ngOnInit() {
    this.loadingData = true;
    this.setCreateForm();
  }

  setCreateForm(): void {
    this.createForm = this.fb.group({
      name: new FormControl('', Validators.required),
      description: new FormControl('', Validators.required),
      location: new FormControl('', Validators.required),
      deadline: new FormControl(''),
      experience: new FormControl(''),
      education: new FormControl(''),
      specialConditions: new FormControl(''),
      duration: new FormControl(''),
      applyMethod: new FormControl(''),
      additional: new FormControl(''),
      categories: new FormControl([], Validators.required),
    });

    this.dateAdapter.setLocale('hr');

    this.getPlaces();

    this.loadingData = false;
  }

  getPlaces() {
    this.placeService.getPlaces().subscribe(
      (response: Place[]) => {
        this.places = response as Place[];
      });
  }

  onSubmit(): void {
    this.loadingData = true;

    this.prepareCategoriesBeforeSubmit();

    this.jobOfferService
      .createJobOffer(this.companyId, this.createForm.value)
      .subscribe(
        (response: JobOffer) => {
          this.notificationService.successMessage(
            NotificationMessages.CREATE_SUCCESS
          );
          this.jobOfferService.pingJobOffers();
          this.loadingData = false;
          this.dialogRef.close();
        },
        (error) => {
          this.notificationService.errorMessage(
            NotificationMessages.CREATE_ERROR
          );
          this.loadingData = false;
        }
      );
  }

  prepareCategoriesBeforeSubmit(): void {
    const realCategories = this.createForm
      .get('categories')
      .value.map((x) => x.id);
    this.createForm.get('categories').setValue(realCategories);
  }

}
