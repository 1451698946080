<form [formGroup]="createForm" autocomplete="off" (submit)="onSubmit()">
  <div class="form-row">
    <div class="col-12 col-md-5 mb-2 mb-md-0">
      <mat-form-field appearance="outline">
        <mat-label>Email</mat-label>
        <input matInput formControlName="email" />
        <mat-error>Email je obavezan podatak. </mat-error>
      </mat-form-field>
    </div>

    <div class="col-12 col-md-5 mb-2 mb-md-0">
      <app-category-picker [parentForm]="createForm" [categoriesEnabled]="true" [newsLettersEnabled]="false">
      </app-category-picker>
    </div>

    <div class="col-12 col-md-2 mb-2 mb-md-0">
      <button type="submit" mat-raised-button color="primary" class="homepage-buttons newsletter-button"
        [disabled]="createForm.invalid">
        Pretplati se
      </button>
    </div>
  </div>
