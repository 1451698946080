import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { NewSubscriber } from '../shared/models/new-subscriber';

@Injectable({
  providedIn: 'root',
})
export class NewsletterService {
  newslettersUrl = environment.apiUrl + '/newsletters';

  constructor(private http: HttpClient) {}

  getNewsletters(): Observable<any[]> {
    return this.http.get<any[]>(this.newslettersUrl + '/get');
  }

  subscribe(newSubscriber: NewSubscriber): any {
    return this.http.post(this.newslettersUrl + '/subscribe', newSubscriber);
  }

  mailExists(email: string) {
    return this.http.post(this.newslettersUrl + '/mailExists', {
      email,
    });
  }
}
