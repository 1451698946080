import { AuthService } from './../../../../services/auth.service';
import { FileUploaderService } from './../../../../services/file-uploader.service';
import { websiteValidator } from './../../../../shared/custom-validators/website-validator';
import { EntityType } from '../../../../shared/enums/entity-type';
import { emailValidator } from './../../../../shared/custom-validators/email-validator';
import { NotificationMessages } from './../../../../shared/constants/notification-messages';
import { NotificationService } from './../../../../services/notification.service';
import { Component, Input, OnInit } from '@angular/core';
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ScientistService } from 'src/app/services/scientist.service';
import { Scientist } from 'src/app/shared/models/scientist/scientist';

@Component({
  selector: 'app-edit-scientist',
  templateUrl: './edit-scientist.component.html',
  styleUrls: ['./edit-scientist.component.css'],
})
export class EditScientistComponent implements OnInit {
  @Input() id: string;
  @Input() formTitle: string;

  tagPickerTitle = 'Ključne riječi koje opisuju Vaš istraživački interes';
  categoryPickerTitle = 'Područja primjene Vaših istraživačkih kompetencija';

  scientist: Scientist;
  scientistId: string;
  editFormTitle: string;
  editForm: FormGroup;
  admin: boolean;

  loadingData: boolean;
  passwordConfirmed: boolean;
  currentUrl: string;

  constructor(
    private scientistService: ScientistService,
    private notificationService: NotificationService,
    private uploadService: FileUploaderService,
    private authService: AuthService,
    private router: Router,
    private fb: FormBuilder,
    private route: ActivatedRoute
  ) {
    this.listenToImageUpload();
    this.admin = this.authService.isSuperAdminLogged();
  }

  public get entityType(): typeof EntityType { return EntityType; }

  ngOnInit(): void {
    this.loadingData = true;
    this.currentUrl = this.router.url;
    this.setFormControls();
    this.getScientist();
  }

  listenToImageUpload() {
    this.uploadService.listenImageUpload().subscribe((response: any) => {
      this.getScientist();
    },
      (error) => {
        this.notificationService.errorMessage(NotificationMessages.SYNC_ERROR);
      }
    );
  }

  setFormControls(): void {
    this.scientistId =
      this.id === undefined ? this.route.snapshot.paramMap.get('id') : this.id;
    this.editFormTitle =
      this.formTitle === undefined ? 'Uređivanje istraživača' : this.formTitle;
  }

  getScientist() {
    this.scientistService.getScientist(this.scientistId).subscribe(
      (scientist: Scientist) => {
        this.scientist = scientist as Scientist;
        this.setEditScientistForm();
      },
      (error) => {
        this.notificationService.errorMessage(NotificationMessages.GET_ERROR);
      }
    );
  }

  setEditScientistForm() {
    this.editForm = this.fb.group({
      title: new FormControl(this.scientist.title, Validators.required),
      firstname: new FormControl(this.scientist.firstname, Validators.required),
      lastname: new FormControl(this.scientist.lastname, Validators.required),
      description : new FormControl(this.scientist.description),
      email: new FormControl(this.scientist.email, emailValidator),
      website: new FormControl(this.scientist.website, websiteValidator),
      employmentCollege: new FormControl(this.scientist.employmentCollege),
      googleScholarLink: new FormControl(this.scientist.googleScholarLink),
      profilePicture: new FormControl(this.scientist.profilePicture),
      categoryTags: new FormControl(
        this.scientist.categoryTags?.split(';').slice(0, -1)
      ),
      categories: new FormControl(this.scientist.categories),
      newsletterCategories: new FormControl(
        this.scientist.newsletterCategories
      )
    });
    this.loadingData = false;
  }

  onSubmit(): void {
    this.loadingData = true;

    this.prepareCategoriesBeforeSubmit();

    this.scientistService
      .editScientist(this.scientistId, this.editForm.value)
      .subscribe(
        (response: any) => {
          this.scientist = response as Scientist;
          this.setEditScientistForm();
          this.notificationService.successMessage(
            NotificationMessages.UPDATE_SUCCESS
          );
        },
        (error) => {
          this.notificationService.errorMessage(
            NotificationMessages.UPDATE_ERROR
          );
          this.loadingData = false;
        }
      );
  }

  prepareCategoriesBeforeSubmit(): void {
    const realCategories = this.editForm.get('categories').value.map((x) => x.id);
    const realnewsCategories = this.editForm
      .get('newsletterCategories')
      .value.map((x) => x.id);
    this.editForm.get('categories').setValue(realCategories);
    this.editForm.get('newsletterCategories').setValue(realnewsCategories);
  }
}
