<h1 mat-dialog-title>Nova usluga i kompetencija</h1>

<app-loader *ngIf="loadingData"></app-loader>

<div mat-dialog-content *ngIf="!loadingData">

    <p class="news-text-time warning-text">
        Nova znanstvena kompetencija bit će vidljiva nakon što ju pregleda i odobri
        administrator RIMAP-a. Hvala na razumijevanju!
    </p>

    <form [formGroup]="createForm" autocomplete="off" (submit)="onSubmit()">
        <mat-form-field class="full-width" appearance="outline">
            <mat-label>Naziv usluge i kompetencije istraživača</mat-label>
            <input matInput formControlName="title" />
            <mat-error>Naziv usluge i kompetencije je obavezan.</mat-error>
        </mat-form-field>
        <mat-form-field class="full-width" appearance="outline">
            <mat-label>Opis usluge i kompetencije istraživača</mat-label>
            <textarea rows="10" cols="40" matInput formControlName="description"></textarea>
            <mat-error>Opis usluge i kompetencije je obavezan.</mat-error>
        </mat-form-field>

        <app-category-picker [categoriesEnabled]="true" [parentForm]="createForm" [newsLettersEnabled]="false">
        </app-category-picker>

        <div mat-dialog-actions align="end">
            <button type="submit" mat-raised-button color="primary" [disabled]="createForm.invalid">
                Spremi novu uslugu i kompetenciju
            </button>
        </div>
    </form>
</div>