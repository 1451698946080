<header class="masthead header-news">
    <div class="container-fluid h-100">
        <div class="row h-100 align-items-center">
            <div class="container text-center">
                <h1 class="page-header-title">Novosti</h1>
            </div>
        </div>
    </div>
</header>

<div class="container">
    <section class="novosti pb-5">
        <div class="container">

            <div class="d-flex justify-content-center">
                <mat-form-field appearance="outline">
                    <input matInput type="text" autocomplete="off" (keyup)="applyFilter($event)"
                        placeholder="Pretraga...">
                </mat-form-field>
            </div>

            <app-loader *ngIf="loadingData"></app-loader>

            <div class="row">
                <div [hidden]="loadingData" [@fadeAnimation] *ngFor="let singleNews of news | async" class="col-xs-12">

                    <div class="image-flip">
                        <div class="mainflip flip-0">
                            <div class="frontside">
                                <div class="card">
                                    <div class="card-body text-left">
                                        <p>
                                            <a (click)="openNews(singleNews.id)">
                                                <img class=" img-fluid entity-profile-picture"
                                                    [src]="singleNews.featuredImage || 'assets/logo/image-default.png'"
                                                    alt="card image">
                                            </a>
                                        </p>
                                        <h3 class="card-title">
                                            <a (click)="openNews(singleNews.id)">
                                                {{singleNews.title}}
                                            </a>
                                        </h3>
                                        <div class="card-text">
                                            <p class="news-text-time">
                                                Objavljeno: {{singleNews.createdAt | date: 'dd.MM.yyyy. HH:mm' }}
                                            </p>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div class="backside ">
                                <div class="card ">
                                    <div class="card-body text-left">
                                        <button color="primary" (click)="openNews(singleNews.id)" mat-raised-button>
                                            Pročitaj više
                                        </button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <mat-paginator #newsPaginator [pageSizeOptions]="[6, 9, 12, 15]"></mat-paginator>
        </div>
    </section>
</div>