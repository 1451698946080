
<app-loader *ngIf="loadingData"></app-loader>

<div [formGroup]="parentForm" *ngIf="!loadingData">
  <mat-form-field appearance="outline" *ngIf="categoriesEnabled">
    <mat-label>{{categoryTitle}}</mat-label>
    <mat-select [compareWith]="compareFunction" formControlName="categories" multiple>
      <mat-option *ngFor="let category of categoriesList" [value]="category">
        {{
            category.name
        }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field appearance="outline" *ngIf="newsLettersEnabled">
    <mat-label>Newsletter kategorije</mat-label>
    <mat-select [compareWith]="compareFunction" formControlName="newsletterCategories" multiple>
      <mat-option *ngFor="let category of categoriesList" [value]="category">
        {{
            category.name
        }}
      </mat-option>
    </mat-select>
    <mat-error>Odabir barem jedne kategorije je obavezan za aktiviranje pretplate.</mat-error>
  </mat-form-field>
</div>

