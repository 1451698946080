
<div class="container-fluid file-uploader-container">
  <div class="row">
    <article class="message" *ngIf="infoMessage">
      <div>
        {{ infoMessage }}
      </div>
    </article>

    <!-- <div class="col-md-4" *ngIf="!infoMessage">
      <progress class="progress is-primary" [attr.value]="progress" max="100"></progress>
    </div> -->
  </div>
</div>

<div class="container file-uploader-container">
   <div class="row">
    <div class="col-md-6 col-sm-12 col-xs-12">
      <figure>
        <img class="example-picture" [src]="imageUrl" />
      </figure>
    </div>

    <div class="col-md-6 col-sm-12 col-xs-12">
      <div>
        <label>
          <input type="file" name="file" #fileInput (change)="onChange(fileInput.files[0])" />
        </label>
      </div>
      <div>
        <div>
          <button mat-raised-button color="primary" (click)="onUpload()" [disabled]="isUploading || !activeUploadButton">
            Učitaj sliku
          </button>
        </div>
      </div>
    </div>
  </div>
</div>