import { PasswordResetComponent } from './../password-reset/password-reset.component';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { NgForm } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  
  wrongLogin = false;
  loginSubmited = false;

  loginModel = (
    {
      username: '',
      password: ''
    }
  );

  constructor(private service: AuthService, 
              private router: Router,
              private dialog: MatDialog,
              private titleService: Title) {
                this.titleService.setTitle('Admin prijava | RIMAP');
               }

  ngOnInit(): void {
    const alreadyLogged = this.service.userLogged();

    if(alreadyLogged){
      this.router.navigateByUrl('/admin');
    }
  }

  onSubmit(loginForm: NgForm): void {
    this.loginSubmited = true;
    this.service.loginSuperAdmin(loginForm.value).subscribe(
      (response: any) => {
        localStorage.setItem('token', response.token);
        localStorage.setItem('version', environment.appVersion.toString());
        this.router.navigateByUrl('/admin');
      },
      () => {
        this.setErrorProperties();
      }
    );
  }

  setErrorProperties(): void{
    this.wrongLogin = true;
    this.loginSubmited = false;
  }

}
