
import { Component, Input, OnInit } from '@angular/core';
import { EntityType } from 'src/app/shared/enums/entity-type';

@Component({
  selector: 'app-specific-knowledges',
  templateUrl: './specific-knowledges.component.html',
  styleUrls: ['./specific-knowledges.component.css']
})
export class SpecificKnowledgesComponent implements OnInit {

  @Input() scientistId: string;

  constructor() { }

  ngOnInit() {
  }

}
