import { NotificationMessages } from 'src/app/shared/constants/notification-messages';
import { Component, OnInit } from '@angular/core';
import { trigger, transition, useAnimation } from '@angular/animations';
import { FadeAnimation } from 'src/app/shared/animations/fade-animation';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.css'],
  animations: [
    trigger('fadeAnimation', [
      transition('void => *', [useAnimation(FadeAnimation)]),
    ]),
  ],
})
export class RegistrationComponent implements OnInit {

  loadingData: boolean;
  companyCreation: boolean;
  scientistCreation: boolean;
  publicOrganizationCreation: boolean;
  registrationType: number;
  successMessage = NotificationMessages.REGISTER_SUCCESS;
  errorMessage = NotificationMessages.REGISTER_ERROR;

  formTitles = {
    company: 'Registracija nove tvrtke',
    scientist: 'Registracija novog istraživača',
    publicOrganization: 'Registracija nove javne organizacije'
  };

  submitButtonTitles = {
    company: 'Registriraj novu tvrtku',
    scientist: 'Registriraj novog istraživača',
    publicOrganization : 'Registriraj novu javnu organizaciju'
  };

  constructor(private titleService: Title) {
    this.titleService.setTitle('Registracija | RIMAP');
   }

  ngOnInit(): void {
    this.resetFormControls();
  }

  getRegistrationForm(){
    this.loadingData = true;
    this.setRegistrationForm();
  }
 
  setRegistrationForm(){
    this.resetFormControls();

    switch (this.registrationType){
      case 1:
        this.companyCreation = true;
        break;
      case 2:
        this.scientistCreation = true;
        break;
      case 3:
        this.publicOrganizationCreation = true;
        break;
    }

    this.loadingData = false;
  }

  resetFormControls(){
    this.companyCreation = false;
    this.scientistCreation = false;
    this.publicOrganizationCreation = false;
  }


}
