import { CategoryService } from './../../services/category.service';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { trigger, transition, useAnimation } from '@angular/animations';
import {
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
  OnDestroy,
} from '@angular/core';
import { MatTableDataSource, MatPaginator } from '@angular/material';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { PublicService } from 'src/app/services/public.service';
import { FadeAnimation } from 'src/app/shared/animations/fade-animation';
import { PublicOrganization } from 'src/app/shared/models/public-organization/public-organization';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-active-public-organizations',
  templateUrl: './active-public-organizations.component.html',
  styleUrls: ['./active-public-organizations.component.css'],
  animations: [
    trigger('fadeAnimation', [
      transition('void => *', [useAnimation(FadeAnimation)]),
    ]),
  ],
})
export class ActivePublicOrganizationsComponent implements OnInit, OnDestroy {
  subscription: Subscription;
  searchForm: FormGroup;
  publicOrganizations: Observable<PublicOrganization[]>;
  publicOrganizationsDataSource: MatTableDataSource<PublicOrganization>;

  @ViewChild('publicOrganizationsPaginator')
  publicOrganizationsPaginator: MatPaginator;

  loadingData: boolean;

  constructor(
    private publicService: PublicService,
    private categoryService: CategoryService,
    private router: Router,
    private fb: FormBuilder,
    private changeDetectorRef: ChangeDetectorRef,
    private titleService: Title
  ) {
    this.titleService.setTitle('Javni sektor | RIMAP');
    this.setSearchForm();
  }

  ngOnInit(): void {
    this.loadingData = true;

    this.subscription = this.categoryService
      .listenSelectedCategories()
      .subscribe(() => {
        this.loadingData = true;
        this.getPublicOrganizations();
      });

    this.getPublicOrganizations();
  }

  initPaginator() {
    this.publicOrganizationsPaginator._intl.itemsPerPageLabel =
      'Rezultata po stranici';
    this.publicOrganizationsPaginator._intl.nextPageLabel =
      'Slijedeća stranica';
    this.publicOrganizationsPaginator._intl.previousPageLabel =
      'Prethodna stranica';
    this.publicOrganizationsPaginator._intl.getRangeLabel = (
      page: number,
      pageSize: number,
      length: number
    ) => {
      const start = page * pageSize + 1;
      const end = (page + 1) * pageSize;
      return `${start} - ${end} od ${this.publicOrganizationsPaginator.length}`;
    };
  }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.publicOrganizationsDataSource.filter = filterValue
      .trim()
      .toLowerCase();
  }

  setSearchForm() {
    this.searchForm = this.fb.group({
      categories: new FormControl([]),
    });
  }

  openPublicOrganization(publicOrganizationId: string): void {
    this.router.navigateByUrl(
      '/public-organizations/details/' + publicOrganizationId
    );
  }

  getPublicOrganizations(): void {
    this.publicService
      .getPublicOrganizations(this.searchForm.value)
      .subscribe((response: PublicOrganization[]) => {
        this.publicOrganizationsDataSource =
          new MatTableDataSource<PublicOrganization>(response);
        this.publicOrganizations = this.publicOrganizationsDataSource.connect();
        this.loadingData = false;
        this.changeDetectorRef.detectChanges();
        this.publicOrganizationsDataSource.paginator =
          this.publicOrganizationsPaginator;
        this.initPaginator();
      });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
