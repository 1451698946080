import { trigger, transition, useAnimation } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { FadeAnimation } from 'src/app/shared/animations/fade-animation';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css'],
  animations: [
    trigger('fadeAnimation', [
      transition('void => *', [useAnimation(FadeAnimation)]),
    ]),
  ],
})
export class AboutComponent implements OnInit {
  constructor(private titleService: Title) {
    this.titleService.setTitle('O nama | RIMAP');
  }

  ngOnInit(): void {}
}
