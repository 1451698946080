import { CategoryService } from './../../../../services/category.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';;
import { Category } from 'src/app/shared/models/category/category';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-edit-category',
  templateUrl: './edit-category.component.html',
  styleUrls: ['./edit-category.component.css']
})
export class EditCategoryComponent implements OnInit {

  categoryId: string;
  category: Category;
  editingError: boolean;
  loadingData: boolean;
  selectedDifficultyLevel: string;

  editCategoryForm: FormGroup;

  constructor(
    private service: CategoryService,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.loadingData = true;
    this.editingError = false;
    this.getCategory();
  }

  setCategoryEditForm(){
    this.editCategoryForm = this.formBuilder.group({
      name: new FormControl(this.category.name, Validators.required)
    });

    this.loadingData = false;
  }

  getCategory(): any{
    this.categoryId = this.route.snapshot.paramMap.get('id');
    this.service.getCategory(this.categoryId).subscribe(
      (category: any) => {
        this.category = category as Category;
        this.setCategoryEditForm();
      },
      () => {
        this.editingError = true;
      }
    )
  }

  onSubmit(){
    this.service.editCategory(this.categoryId, this.editCategoryForm.value).subscribe(
      () => {
        this.router.navigateByUrl('/admin/categories');
      },
      () => {
        this.editingError = true;
      }
    )
  }

}
