import { CategoryService } from './../../services/category.service';
import { FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { JobOffer } from 'src/app/shared/models/job-offer/job-offer';
import {
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
  OnDestroy,
} from '@angular/core';
import { MatTableDataSource, MatPaginator } from '@angular/material';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { PublicService } from 'src/app/services/public.service';
import { trigger, transition, useAnimation } from '@angular/animations';
import { FadeAnimation } from 'src/app/shared/animations/fade-animation';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-active-job-offers',
  templateUrl: './active-job-offers.component.html',
  styleUrls: ['./active-job-offers.component.css'],
  animations: [
    trigger('fadeAnimation', [
      transition('void => *', [useAnimation(FadeAnimation)]),
    ]),
  ],
})
export class ActiveJobOffersComponent implements OnInit, OnDestroy {
  subscription: Subscription;
  searchForm: FormGroup;
  jobOffers: Observable<JobOffer[]>;
  jobOffersDataSource: MatTableDataSource<JobOffer>;

  @ViewChild('jobOffersPaginator') jobOffersPaginator: MatPaginator;
  
  loadingData: boolean;

  constructor(
    private publicService: PublicService,
    private categoryService: CategoryService,
    private router: Router,
    private fb: FormBuilder,
    private changeDetectorRef: ChangeDetectorRef,
    private titleService: Title
  ) {
    this.titleService.setTitle('Potrebe za ljudski resursima | RIMAP');
    this.setSearchForm();
  }

  ngOnInit(): void {
    this.loadingData = true;

    this.subscription = this.categoryService
      .listenSelectedCategories()
      .subscribe(() => {
        this.loadingData = true;
        this.getJobOffers();
      });

    this.getJobOffers();
  }

  initPaginator() {
    this.jobOffersPaginator._intl.itemsPerPageLabel = 'Rezultata po stranici';
    this.jobOffersPaginator._intl.nextPageLabel = 'Slijedeća stranica';
    this.jobOffersPaginator._intl.previousPageLabel = 'Prethodna stranica';
    this.jobOffersPaginator._intl.getRangeLabel = (
      page: number,
      pageSize: number,
      length: number
    ) => {
      const start = page * pageSize + 1;
      const end = (page + 1) * pageSize;
      return `${start} - ${end} od ${this.jobOffersPaginator.length}`;
    };
  }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.jobOffersDataSource.filter = filterValue.trim().toLowerCase();
  }

  setSearchForm() {
    this.searchForm = this.fb.group({
      categories: new FormControl([]),
    });
  }

  openCompany(companyId: string): void {
    this.router.navigateByUrl('/companies/details/' + companyId);
  }

  getJobOffers() {
    this.publicService
      .getCompaniesJobOffers(this.searchForm.value)
      .subscribe((response: JobOffer[]) => {
        this.jobOffersDataSource = new MatTableDataSource<JobOffer>(response);
        this.jobOffers = this.jobOffersDataSource.connect();
        this.loadingData = false;
        this.changeDetectorRef.detectChanges();
        this.jobOffersDataSource.paginator = this.jobOffersPaginator;
        this.initPaginator();
      });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
