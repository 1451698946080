
<app-loader *ngIf="loadingData"></app-loader>

<mat-card *ngIf="!loadingData">
    <h3>Uređivanje kategorije</h3>
    <form [formGroup]="editCategoryForm" autocomplete="off" (submit)="onSubmit()">
        <p>
            <mat-form-field class="full-width-input" appearance="outline">
                <mat-label>Ime kategorije</mat-label>
                <input matInput formControlName="name">
                <mat-error>
                    Naziv kategorije je obavezan podatak.
                </mat-error>
            </mat-form-field>
        </p>
        <mat-error *ngIf="editingError">Greška prilikom uređivanja kategorije.</mat-error>
        <p align="end">
            <button type="submit" mat-raised-button 
            color="primary" [disabled]="editCategoryForm.invalid">
            Spremi promjene
        </button>
        </p>
    </form>
</mat-card>