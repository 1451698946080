import { CreateSpecificKnowledgeDialogComponent } from './../../../../components/create-dialogs/create-specific-knowledge-dialog/create-specific-knowledge-dialog.component';
import { AuthService } from 'src/app/services/auth.service';
import { VisibilityDialogComponent } from 'src/app/components/visibility-dialog/visibility-dialog.component';
import { MatDialog } from '@angular/material';
import { NotificationMessages } from 'src/app/shared/constants/notification-messages';
import { NotificationService } from './../../../../services/notification.service';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { DeleteDialogComponent } from 'src/app/components/delete-dialog/delete-dialog.component';
import { SpecificKnowledgeService } from 'src/app/services/specific-knowledge.service';
import { SpecificKnowledge } from 'src/app/shared/models/specific-knowledge/specific-knowledge';
import { EditSpecificKnowledgeComponent } from '../edit-specific-knowledge/edit-specific-knowledge.component';

const COLUMNS: string[] = ['edit-buttons', 'description', 'delete-buttons'];

@Component({
  selector: 'app-specific-knowledges-list',
  templateUrl: './specific-knowledges-list.component.html',
  styleUrls: ['./specific-knowledges-list.component.css'],
})
export class SpecificKnowledgesListComponent implements OnInit {
  loadingData: boolean;
  displayedColumns: string[] = COLUMNS;
  specificKnowledges: SpecificKnowledge[];
  dataSource: MatTableDataSource<SpecificKnowledge[]>;

  @Input() scientistId: string;

  admin: boolean;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private specificKnowledgeService: SpecificKnowledgeService,
    private authService: AuthService,
    private dialog: MatDialog,
    private notificationService: NotificationService
  ) {
    this.admin = this.authService.isSuperAdminLogged();
  }

  ngOnInit() {
    this.loadingData = true;
    this.getSpecificKnowledges();
    this.listenForChanges();
  }

  initPaginator() {
    this.paginator._intl.itemsPerPageLabel = 'Rezultata po stranici';
    this.paginator._intl.nextPageLabel = 'Slijedeća stranica';
    this.paginator._intl.previousPageLabel = 'Prethodna stranica';
    this.paginator._intl.getRangeLabel = (
      page: number,
      pageSize: number,
      length: number
    ) => {
      const start = page * pageSize + 1;
      const end = (page + 1) * pageSize;
      return `${start} - ${end} od ${this.paginator.length}`;
    };
  }

  listenForChanges(): void {
    this.specificKnowledgeService.listenSpecificKnowledges().subscribe(
      (response: any) => {
        this.getSpecificKnowledges();
      },
      (error) => {
        this.notificationService.warningMessage(
          NotificationMessages.SYNC_ERROR
        );
      }
    );
  }

  getSpecificKnowledges(): void {
    this.specificKnowledgeService
      .getSpecificKnowledges(this.scientistId)
      .subscribe(
        (specificKnowledges: any) => {
          this.dataSource = new MatTableDataSource<SpecificKnowledge[]>(
            specificKnowledges
          );
          this.dataSource.paginator = this.paginator;
          this.initPaginator();
          this.dataSource.sort = this.sort;
        },
        (error) => {
          this.notificationService.errorMessage(NotificationMessages.GET_ERROR);
        }
      );

    this.loadingData = false;
  }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  openVisibilityDialog(specificKnowledgeId: string): void {
    const dialogRef = this.dialog.open(VisibilityDialogComponent);

    dialogRef.afterClosed().subscribe((result) => {
      if (result !== undefined) {
        this.flipSpecificKnowledge(specificKnowledgeId);
      }
    });
  }

  flipSpecificKnowledge(specificKnowledgeId: string): void {
    this.specificKnowledgeService
      .flipSpecificKnowledgeActive(this.scientistId, specificKnowledgeId)
      .subscribe(
        (response: any) => {
          this.getSpecificKnowledges();
          this.notificationService.successMessage(
            NotificationMessages.UPDATE_SUCCESS
          );
        },
        (error) => {
          this.notificationService.errorMessage(
            NotificationMessages.UPDATE_ERROR
          );
        }
      );
  }

  openDeleteDialog(specificKnowledgeId: string): void {
    const dialogRef = this.dialog.open(DeleteDialogComponent);

    dialogRef.afterClosed().subscribe((result) => {
      if (result !== undefined) {
        this.deleteSpecificKnowledge(specificKnowledgeId);
      }
    });
  }

  deleteSpecificKnowledge(specificKnowledgeId: string): void {
    this.specificKnowledgeService
      .deleteSpecificKnowledge(this.scientistId, specificKnowledgeId)
      .subscribe(
        (response: any) => {
          this.notificationService.successMessage(
            NotificationMessages.DELETE_SUCCESS
          );
          this.getSpecificKnowledges();
        },
        (error) => {
          this.notificationService.errorMessage(
            NotificationMessages.DELETE_ERROR
          );
        }
      );
  }

  openCreateSpecificKnowledgeDialog(): void {
    const dialogRef = this.dialog.open(CreateSpecificKnowledgeDialogComponent);
    dialogRef.componentInstance.scientistId = this.scientistId;
    dialogRef.afterClosed().subscribe((result) => {});
  }

  openEditDialog(specificKnowledgeId: string): void {
    const dialogRef = this.dialog.open(EditSpecificKnowledgeComponent);
    dialogRef.componentInstance.scientistId = this.scientistId;
    dialogRef.componentInstance.specificKnowledgeId = specificKnowledgeId;

    dialogRef.afterClosed().subscribe((result) => {});
  }
}
