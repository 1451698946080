import { CreateJobOfferDialogComponent } from './../../../../components/create-dialogs/create-job-offer-dialog/create-job-offer-dialog.component';
import { AuthService } from 'src/app/services/auth.service';
import { EditJobOfferComponent } from './../edit-job-offer/edit-job-offer.component';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { NotificationService } from 'src/app/services/notification.service';
import { JobOfferService } from 'src/app/services/job-offer.service';
import { DeleteDialogComponent } from 'src/app/components/delete-dialog/delete-dialog.component';
import { NotificationMessages } from 'src/app/shared/constants/notification-messages';
import { JobOffer } from 'src/app/shared/models/job-offer/job-offer';

const COLUMNS: string[] = [
  'edit-buttons',
  'name',
  'description',
  'location',
  'deadline',
  'delete-buttons',
];

@Component({
  selector: 'app-job-offers-list',
  templateUrl: './job-offers-list.component.html',
  styleUrls: ['./job-offers-list.component.css']
})
export class JobOffersListComponent implements OnInit {

  @Input() companyId: string;

  admin: boolean;

  loadingData: boolean;
  displayedColumns: string[] = COLUMNS;
  researchProblems: JobOffer[];
  dataSource: MatTableDataSource<JobOffer[]>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private jobOffersService: JobOfferService,
    private authService: AuthService,
    private notificationService: NotificationService,
    private dialog: MatDialog
  ) {
    this.admin = this.authService.isSuperAdminLogged();
  }

  ngOnInit(): void {
    this.loadingData = true;
    this.getJobOffers();
    this.listenJobOffers();
  }

  initPaginator() {
    this.paginator._intl.itemsPerPageLabel = 'Rezultata po stranici';
    this.paginator._intl.nextPageLabel = 'Slijedeća stranica';
        this.paginator._intl.previousPageLabel = 'Prethodna stranica';
    this.paginator._intl.getRangeLabel = (
      page: number,
      pageSize: number,
      length: number
    ) => {
      const start = page * pageSize + 1;
      const end = (page + 1) * pageSize;
      return `${start} - ${end} od ${this.paginator.length}`;
    };
  }


  getJobOffers(): void {
    this.loadingData = true;

    this.jobOffersService
      .getJobOffers(this.companyId)
      .subscribe(
        (jobOffers: any) => {
          this.researchProblems = jobOffers as JobOffer[];
          this.dataSource = new MatTableDataSource<JobOffer[]>(
            jobOffers
          );
          this.loadingData = false;
          this.dataSource.paginator = this.paginator;
          this.initPaginator();
          this.dataSource.sort = this.sort;
        },
        (error) => {
          this.notificationService.errorMessage(NotificationMessages.GET_ERROR);
        }
      );

    this.loadingData = false;
  }

  listenJobOffers(): void {
    this.jobOffersService.listenJobOffers().subscribe(
      (response: any) => {
        this.getJobOffers();
      },
      (error) => {
        this.notificationService.warningMessage(
          NotificationMessages.SYNC_ERROR
        );
      }
    );
  }

  openVisibilityDialog(jobOfferId: string): void {
    const dialogRef = this.dialog.open(DeleteDialogComponent);

    dialogRef.afterClosed().subscribe((result) => {
      if (result !== undefined) {
        this.flipJobOfferActive(jobOfferId);
      }
    });
  }

  openDeleteDialog(jobOfferId: string): void {
    const dialogRef = this.dialog.open(DeleteDialogComponent);

    dialogRef.afterClosed().subscribe((result) => {
      if (result !== undefined) {
        this.deleteJobOffer(jobOfferId);
      }
    });
  }

  flipJobOfferActive(jobOfferId: string): void {
    this.jobOffersService
      .flipJobOfferActive(
        jobOfferId,
        this.companyId
      )
      .subscribe(
        (response: any) => {
          this.notificationService.successMessage(
            NotificationMessages.UPDATE_SUCCESS
          );
          this.getJobOffers();
        },
        (error) => {
          this.notificationService.errorMessage(
            NotificationMessages.UPDATE_ERROR
          );
        }
      );
  }

  deleteJobOffer(jobOfferId: string): void {
    this.jobOffersService
      .deleteJobOffer(
        jobOfferId,
        this.companyId
      )
      .subscribe(
        (response: any) => {

          this.notificationService.successMessage(
            NotificationMessages.DELETE_SUCCESS
          );
          this.getJobOffers();
        },
        (error) => {
          this.notificationService.errorMessage(
            NotificationMessages.DELETE_ERROR
          );
        }
      );
  }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  openCreateJobOfferDialog(): void {
    const dialogRef = this.dialog.open(CreateJobOfferDialogComponent);
    dialogRef.componentInstance.companyId = this.companyId;
    dialogRef.afterClosed().subscribe((result) => {});
  }

  openEditDialog(jobOfferId: string): void {
    const dialogRef = this.dialog.open(EditJobOfferComponent);
    dialogRef.componentInstance.jobOfferId = jobOfferId;
    dialogRef.componentInstance.companyId = this.companyId;

    dialogRef.afterClosed().subscribe((result) => {});
  }

}
