import { NotificationService } from './../../services/notification.service';
import { NotificationMessages } from './../../shared/constants/notification-messages';
import { FormGroup } from '@angular/forms';
import { CategoryService } from './../../services/category.service';
import { Component, Input, OnInit } from '@angular/core';
import { Category } from 'src/app/shared/models/category/category';

@Component({
  selector: 'app-category-picker',
  templateUrl: './category-picker.component.html',
  styleUrls: ['./category-picker.component.css']
})
export class CategoryPickerComponent implements OnInit {

  @Input() parentForm: FormGroup;
  @Input() newsLettersEnabled: boolean;
  @Input() categoriesEnabled: boolean;
  @Input() title: string;

  loadingData: boolean;
  categoriesList: Category[];

  categoryTitle: string;

  constructor(private categoryService: CategoryService,
              private notificationService: NotificationService) { }

  ngOnInit() {
    this.loadingData = true;
    this.setCategories();
    this.categoryTitle = this.title === undefined ? 'Odaberite pripadne kategorije' : this.title;
  }

  get newsletterCategories() {
    return this.parentForm.get('newsletterCategories').value;
  }

  get categories() {
    return this.parentForm.get('categories').value;
  }

  setCategories(): void {
    this.categoryService.getCategories().subscribe((response) => {
      this.categoriesList = response;
      this.loadingData = false;
    },
      (error) => {
        this.notificationService.errorMessage(NotificationMessages.GET_ERROR);
      }
    );
  }

  compareFunction(o1: any, o2: any) {
    return (o1.name === o2.name && o1.id === o2.id);
   }

}
