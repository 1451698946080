import { VisibilityDialogComponent } from './../../../../components/visibility-dialog/visibility-dialog.component';
import { PublicOrganizationService } from './../../../../services/public-organization.service';
import { PublicOrganization } from './../../../../shared/models/public-organization/public-organization';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatSort, MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { DeleteDialogComponent } from 'src/app/components/delete-dialog/delete-dialog.component';

const COLUMNS: string[] = [
  'edit-buttons',
  'name',
  'address',
  'email',
  'numberOfViews',
  'delete-buttons'
];

@Component({
  selector: 'app-public-organizations-list',
  templateUrl: './public-organizations-list.component.html',
  styleUrls: ['./public-organizations-list.component.css']
})
export class PublicOrganizationsListComponent implements OnInit {

  loadingData: boolean;
  displayedColumns: string[] = COLUMNS;
  publicOrganizations: PublicOrganization[];
  dataSource: MatTableDataSource<PublicOrganization[]>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private router: Router,
    private service: PublicOrganizationService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.loadingData = true;
    this.getPublicOrganizations();
  }

  initPaginator() {
    this.paginator._intl.itemsPerPageLabel = 'Rezultata po stranici';
    this.paginator._intl.nextPageLabel = 'Slijedeća stranica';
        this.paginator._intl.previousPageLabel = 'Prethodna stranica';
    this.paginator._intl.getRangeLabel = (
      page: number,
      pageSize: number,
      length: number
    ) => {
      const start = page * pageSize + 1;
      const end = (page + 1) * pageSize;
      return `${start} - ${end} od ${this.paginator.length}`;
    };
  }


  getPublicOrganizations(): void {
    this.service.getPublicOrganizations().subscribe((categories: any) => {
      this.dataSource = new MatTableDataSource<PublicOrganization[]>(categories);
      this.loadingData = false;
      this.dataSource.paginator = this.paginator;
      this.initPaginator();
      this.dataSource.sort = this.sort;
    });
  }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  
  goToCreatePage(): void {
    this.router.navigateByUrl('/admin/public-organizations/create');
  }

  goToEditPage(publicOrganizationId: string): void {
    this.router.navigateByUrl('/admin/public-organizations/edit/' + publicOrganizationId);
  }

  openVisibilityDialog(publicOrganizationId: string): void {
    const dialogRef = this.dialog.open(VisibilityDialogComponent);

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.loadingData = true;
        this.flipActivePublicOrganization(publicOrganizationId);
      }
    });
  }

  flipActivePublicOrganization(publicOrganizationId: string): void{
    this.service.flipPublicOrganizationActive(publicOrganizationId).subscribe(
      (() => {
        this.getPublicOrganizations();
      })
    );
  }
}
