export enum EntityType {
    Company,
    PublicOrganization,
    Scientist,
    News,
    Equipment,
    ResearchProblem,
    JobOffer,
    SpecificKnowledge
}
