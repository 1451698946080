import { CategoryService } from './../../services/category.service';
import { EntityType } from 'src/app/shared/enums/entity-type';
import { ResearchProblem } from 'src/app/shared/models/research-problem/research-problem';
import {
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
  OnDestroy,
} from '@angular/core';
import { MatTableDataSource, MatPaginator } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { PublicService } from 'src/app/services/public.service';
import { trigger, transition, useAnimation } from '@angular/animations';
import { FadeAnimation } from 'src/app/shared/animations/fade-animation';
import { FormGroup, FormBuilder } from '@angular/forms';
import { FormControl } from '@angular/forms';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-active-research-problems',
  templateUrl: './active-research-problems.component.html',
  styleUrls: ['./active-research-problems.component.css'],
  animations: [
    trigger('fadeAnimation', [
      transition('void => *', [useAnimation(FadeAnimation)]),
    ]),
  ],
})
export class ActiveResearchProblemsComponent implements OnInit, OnDestroy {
  searchForm: FormGroup;

  subscription: Subscription;
  companiesResearchProblems: boolean;

  researchProblems: Observable<ResearchProblem[]>;
  researchProblemsDataSource: MatTableDataSource<ResearchProblem>;

  @ViewChild('researchProblemsPaginator')
  researchProblemsPaginator: MatPaginator;

  loadingData: boolean;

  constructor(
    private publicService: PublicService,
    private categoryService: CategoryService,
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private changeDetectorRef: ChangeDetectorRef,
    private titleService: Title
  ) {
    this.titleService.setTitle('Razvojni prijedlozi tvrtki | RIMAP');
    this.setSearchForm();
  }

  ngOnInit(): void {
    this.loadingData = true;

    const activeRoute = this.route.snapshot.url.toString();

    this.companiesResearchProblems = activeRoute.includes('companies');

    this.subscription = this.categoryService
      .listenSelectedCategories()
      .subscribe(() => {
        this.loadingData = true;
        this.companiesResearchProblems
          ? this.getCompaniesResearchProblems()
          : this.getPublicOrganizationsResearchProblems();
      });

    this.companiesResearchProblems
      ? this.getCompaniesResearchProblems()
      : this.getPublicOrganizationsResearchProblems();
  }

  initPaginator() {
    this.researchProblemsPaginator._intl.itemsPerPageLabel =
      'Rezultata po stranici';
    this.researchProblemsPaginator._intl.nextPageLabel = 'Slijedeća stranica';
    this.researchProblemsPaginator._intl.previousPageLabel =
      'Prethodna stranica';
      this.researchProblemsPaginator._intl.getRangeLabel = (
        page: number,
        pageSize: number,
        length: number
      ) => {
        const start = page * pageSize + 1;
        const end = (page + 1) * pageSize;
        return `${start} - ${end} od ${this.researchProblemsPaginator.length}`;
      };
  }

  setSearchForm() {
    this.searchForm = this.fb.group({
      categories: new FormControl([]),
    });
  }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.researchProblemsDataSource.filter = filterValue.trim().toLowerCase();
  }

  openParentEntity(researchProblem: ResearchProblem): void {
    if (researchProblem.publicOrganizationId !== null) {
      this.router.navigateByUrl(
        '/public-organizations/details/' + researchProblem.publicOrganizationId
      );
    } else {
      this.router.navigateByUrl(
        '/companies/details/' + researchProblem.companyId
      );
    }
  }

  getPublicOrganizationsResearchProblems() {
    this.publicService
      .getPublicOrganizationsResearchProblems(this.searchForm.value)
      .subscribe((response: ResearchProblem[]) => {
        this.researchProblemsDataSource =
          new MatTableDataSource<ResearchProblem>(response);
        this.researchProblems = this.researchProblemsDataSource.connect();
        this.loadingData = false;
        this.changeDetectorRef.detectChanges();
        this.researchProblemsDataSource.paginator =
          this.researchProblemsPaginator;
        this.initPaginator();
      });
  }

  getCompaniesResearchProblems() {
    this.publicService
      .getCompaniesResearchProblems(this.searchForm.value)
      .subscribe((response: ResearchProblem[]) => {
        this.researchProblemsDataSource =
          new MatTableDataSource<ResearchProblem>(response);
        this.researchProblems = this.researchProblemsDataSource.connect();
        this.loadingData = false;
        this.changeDetectorRef.detectChanges();
        this.researchProblemsDataSource.paginator =
          this.researchProblemsPaginator;
        this.initPaginator();
      });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
