import { Component, ViewEncapsulation, OnInit, OnDestroy } from '@angular/core';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  title = 'b2b';


  constructor(public mediaObserver: MediaObserver){}

  ngOnInit(): void{
  }


}
