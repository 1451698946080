import { NotificationMessageComponent } from './../components/notification-message/notification-message.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})

export class NotificationService {
  constructor(private snackBar: MatSnackBar) {}

  successMessage(successMessage: string): void {
    this.snackBar.openFromComponent(NotificationMessageComponent, {
      data: {
        text: successMessage,
        iconType: 'check'
      },
      duration: 1000000,
      horizontalPosition: 'end',
      verticalPosition: 'top',
      panelClass: ['blue-snackbar'],
    });
  }

  errorMessage(errorMessage: string): void {
    this.snackBar.openFromComponent(NotificationMessageComponent, {
      data: {
        text: errorMessage,
        iconType: 'error'
      },
      duration: 10000,
      horizontalPosition: 'end',
      verticalPosition: 'top',
      panelClass: ['blue-snackbar'],
    });
  }

  infoMessage(infoMessage: string): void {
    this.snackBar.openFromComponent(NotificationMessageComponent, {
      data: {
        text: infoMessage,
        iconType: 'info'
      },
      duration: 1000000,
      horizontalPosition: 'end',
      verticalPosition: 'top',
      panelClass: ['blue-snackbar']
    });
  }

  warningMessage(warningMessage: string): void {
    this.snackBar.openFromComponent(NotificationMessageComponent, {
      data: {
        text: warningMessage,
        iconType: 'warning'
      },
      duration: 10000,
      horizontalPosition: 'end',
      verticalPosition: 'top',
      panelClass: ['blue-snackbar'],
    });
  }
}
