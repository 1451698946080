<app-loader *ngIf="loadingData"></app-loader>

<h1 mat-dialog-title *ngIf="!loadingData">Uređivanje razvojnog prijedloga</h1>
<mat-dialog-content *ngIf="!loadingData">
  <form [formGroup]="editForm" autocomplete="off" (submit)="onSubmit()">

    <div class="row centered-container">
      <img class="img img-fluid entity-image"
        src="{{ researchProblem.image || 'assets/logo/image-default.png' }}"
        alt="Slika razvojnog prijedloga" />
    </div>

    <app-file-uploader [entityType]="entityTypeEnum.ResearchProblem" [entityId]="researchProblem.id"
      [parentEntityId]="entityId" [parentEntityType]="entityType">
    </app-file-uploader>

    <mat-form-field class="full-width" appearance="outline">
      <mat-label>Naslov</mat-label>
      <input matInput formControlName="title" />
      <mat-error>Naslov razvojnog prijedloga je obavezan.</mat-error>
    </mat-form-field>

    <mat-label>Opis</mat-label>
    <ckeditor [editor]="Editor" [config]="editorConfig.getConfig()" formControlName="description"
      (ready)="onReady($event)"></ckeditor>

    <mat-form-field class="full-width mt-4" appearance="outline">
      <mat-label>Mogućnosti doprinosa akademske zajednice</mat-label>
      <textarea rows="10" cols="40" matInput formControlName="academicCommunityContributionPossibility"
        maxLength="2000"></textarea>
      <mat-error>Mogućnosti doprinosa akademske zajednice su obavezan podatak.</mat-error>
    </mat-form-field>

    <app-category-picker [categoriesEnabled]="true" [parentForm]="editForm" [newsLettersEnabled]="false">
    </app-category-picker>

    <div align="end" mat-dialog-actions align="end">
      <button type="button" mat-raised-button color="warn" (click)="onNoClick()">Odustani
      </button>&nbsp;
      <button type="submit" mat-raised-button color="primary" [mat-dialog-close]="true" cdkFocusInitial
        [disabled]="editForm.invalid">
        Spremi promjene
      </button>
    </div>
  </form>
</mat-dialog-content>