<header class="masthead header-public-organizations">
    <div class="container-fluid h-100">
        <div class="row h-100 align-items-center">
            <div class="container text-center">
                <h1 *ngIf="!loadingData" class="page-header-title">{{publicOrganization.name}}</h1>
            </div>
        </div>
    </div>
</header>

<div class="container">
    <div [@fadeAnimation] class="container">
        <div class="mainflip flip-0">
            <div class="frontside">
                <div class="card">
                    <app-loader *ngIf="loadingData"></app-loader>
                    <div *ngIf="!loadingData" class="card-body">
                        <p class="text-center"><img class=" img-fluid entity-profile-picture "
                                [src]="publicOrganization.logo || 'assets/logo/image-default.png'" alt="card image"></p>
                        <div class="row buttons-container mt-3 mb-3 text-center">
                            <button type="button" mat-raised-button color="primary"
                                (click)="openSendMessage()">Kontaktiraj organizaciju
                            </button>
                        </div>
                        <div class="card-text">
                            <p class="adresa" *ngIf="publicOrganization.address !== ''">
                                <strong>Adresa:</strong>
                                {{publicOrganization.address}}
                            </p>
                            <p class="web" *ngIf="publicOrganization.website !== ''">
                                <strong>Web stranica: </strong>
                                <a [href]="publicOrganization.website"
                                    target="_blank">{{publicOrganization.website}}</a>
                            </p>
                            <p *ngIf="publicOrganization.categories.length > 0">
                                <mat-chip-list>
                                    <strong>Područja djelatnosti:</strong>
                                    <mat-chip *ngFor="let category of publicOrganization.categories">
                                        {{category.name}}
                                    </mat-chip>
                                </mat-chip-list>
                            </p>
                            <p *ngIf="publicOrganization.categoryTags.length > 0">
                                <mat-chip-list>
                                    <strong>Potpodručja djelatnosti:</strong>
                                    <mat-chip
                                        *ngFor="let tag of publicOrganization.categoryTags?.split(';').slice(0, -1)">
                                        {{tag}}
                                    </mat-chip>
                                </mat-chip-list>
                            </p>

                            <p *ngIf="publicOrganization.description !== null">
                                <strong>Kratki opis organizacije i djelatnosti: </strong>
                                {{publicOrganization.description}}
                            </p>

                            <div class="mt-3">
                                <h2 *ngIf="publicOrganization.researchProblems.length !== 0">
                                    <strong>Razvojni prijedlozi</strong>
                                </h2>
                                <div class="sub-entity-details-container mt-3"
                                    *ngFor="let researchProblem of publicOrganization.researchProblems">
                                    <h3 class="mt-3 mb-3 sub-entity-main-title">
                                        <strong>{{researchProblem.title}}</strong>
                                    </h3>
                                    <img [src]="researchProblem.image || 'assets/logo/image-default.png'"
                                        alt={{researchProblem.title}} />

                                    <div class="card-text">
                                        <div [innerHTML]="researchProblem.description | safeHtml"></div>

                                        <p class="mt-4 mb-3"
                                            *ngIf="researchProblem.academicCommunityContributionPossibility !== null">
                                            <strong>Mogućnosti inovacijskog doprinosa:</strong>
                                            {{researchProblem.academicCommunityContributionPossibility}}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr>
                        <div class="row buttons-container mt-3 text-center">
                            <button type="button" mat-raised-button color="primary"
                                (click)="openSendMessage()">Kontaktiraj organizaciju
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>