import { MatDialogRef } from '@angular/material';
import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { EquipmentService } from 'src/app/services/equipment.service';
import { NotificationService } from 'src/app/services/notification.service';
import { NotificationMessages } from 'src/app/shared/constants/notification-messages';

@Component({
  selector: 'app-create-equipment-dialog',
  templateUrl: './create-equipment-dialog.component.html',
  styleUrls: ['./create-equipment-dialog.component.css']
})
export class CreateEquipmentDialogComponent implements OnInit {
  @Input() scientistId: string;

  createForm: FormGroup;
  loadingData: boolean;

  constructor(
    private equipmentService: EquipmentService,
    private notificationService: NotificationService,
    private dialogRef: MatDialogRef<CreateEquipmentDialogComponent>,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.loadingData = true;
    this.setCreateForm();
  }

  setCreateForm(): void {
    this.createForm = this.fb.group({
      description: new FormControl('', Validators.required),
      title: new FormControl('', Validators.required),
      categories: new FormControl([], Validators.required),
    });

    this.loadingData = false;
  }

  onSubmit(): void {
    this.loadingData = true;

    this.prepareCategoriesBeforeSubmit();

    this.equipmentService
      .createEquipment(this.scientistId, this.createForm.value)
      .subscribe(
        (response: any) => {
          this.notificationService.successMessage(
            NotificationMessages.CREATE_SUCCESS
          );
          this.equipmentService.pingNewEquipment();
          this.loadingData = false;
          this.dialogRef.close();
        },
        (error) => {
          this.notificationService.errorMessage(
            NotificationMessages.CREATE_ERROR
          );
          this.loadingData = false;
        }
      );
  }

  prepareCategoriesBeforeSubmit(): void {
    const realCategories = this.createForm
      .get('categories')
      .value.map((x) => x.id);
    this.createForm.get('categories').setValue(realCategories);
  }

}
