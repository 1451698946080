export class NotificationMessages {
  public static readonly GET_ERROR = 'Neuspješan dohvat podataka.';

  public static readonly SYNC_ERROR = 'Neuspješna sinkronizacija podataka';

  public static readonly MESSAGE_SENT = 'Poruka je poslana.';

  public static readonly ERROR_SENDING_MESSAGE = 'Poruka nije poslana.';

  public static readonly SUCCESSFULLY_REGISTERED_COMPANY =
    'Uspješno ste registrirali novu tvrtku. Provjerite email i potvrdite registraciju kako biste se mogli prijaviti u platformu.';

  public static readonly SUCCESSFULLY_REGISTERED_PUBLIC_ORGANIZATION =
    'Uspješno ste registrirali novu javnu organizaciju. Provjerite email i potvrdite registraciju kako biste se mogli prijaviti u platformu.';

  public static readonly SUCCESSFULLY_REGISTERED_SCIENTIST =
    'Uspješno ste registrirali novog istraživača. Provjerite email i potvrdite registraciju kako biste se mogli prijaviti u platformu.';

  public static readonly PASSWORD_CHANGE_SUCCESS =
    'Uspješno promjenjena lozinka.';
  public static readonly PASSWORD_CHANGE_ERROR =
    'Uspješno promjenjena lozinka.';

  public static readonly EMAIL_SENT = 'Email je poslan.';
  public static readonly EMAIL_NOT_SENT = 'Neuspješno slanje emaila.';

  public static readonly UPLOAD_SUCCESS = 'Uspješno učitana datoteka.';
  public static readonly UPLOAD_ERROR = 'Neuspješno učitavanje datoteke.';

  public static readonly SUBSCRIBE_SUCCESS = 'Uspješno ste pretplaćeni.';
  public static readonly SUBSCRIBE_ERROR =
    'Neuspješno aktiviranje pretplate.';

  public static readonly CREATE_SUCCESS = 'Uspješno kreirano.';
  public static readonly CREATE_ERROR = 'Neuspješno kreiranje.';

  public static readonly DELETE_SUCCESS = 'Uspješno izbrisano.';
  public static readonly DELETE_ERROR = 'Neuspješno brisanje.';

  public static readonly LOGIN_SUCCESS = 'Uspješna prijava.';
  public static readonly LOGIN_ERROR = 'Neuspješna prijava.';

  public static readonly REGISTER_SUCCESS = 'Registracija je uspješna';
  public static readonly REGISTER_ERROR = 'Neuspješna registracija.';

  public static readonly UPDATE_SUCCESS = 'Uspješno ažurirani podatci.';
  public static readonly UPDATE_ERROR = 'Neuspješno ažuriranje podataka.';

  public static readonly USERNAME_AVAILABLE = 'Korisničko ime je dostupno.';
  public static readonly USERNAME_NOT_AVAILABLE = 'Korisničko ime već postoji.';
}
