import { EditorConfig } from './../../../../shared/constants/editor-config';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { EntityType } from 'src/app/shared/enums/entity-type';
import { NotificationMessages } from 'src/app/shared/constants/notification-messages';
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
} from '@angular/forms';
import { NotificationService } from './../../../../services/notification.service';
import { NewsService } from './../../../../services/news.service';
import { Component, OnInit } from '@angular/core';
import * as Editor from 'src/app/shared/ckeditor/build/ckeditor';
import { UploadAdapter } from 'src/app/shared/helpers/upload-adapter';

@Component({
  selector: 'app-create-news',
  templateUrl: './create-news.component.html',
  styleUrls: ['./create-news.component.css'],
})
export class CreateNewsComponent implements OnInit {
  loadingData: boolean;
  createForm: FormGroup;

  public Editor = Editor;

  public get editorConfig(): typeof EditorConfig{
    return EditorConfig;
  }

  public get entityType(): typeof EntityType {
    return EntityType;
  }

  constructor(
    private newsService: NewsService,
    private notificationService: NotificationService,
    private fb: FormBuilder,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.loadingData = true;
    this.setCreateForm();
  }

  setCreateForm(): void {
    this.createForm = this.fb.group({
      title: new FormControl('', Validators.required),
      content: new FormControl('', Validators.required),
    });

    this.loadingData = false;
  }

  onReady(eventData) {
    eventData.plugins.get(
      'FileRepository'
    ).createUploadAdapter = function (loader: { file: string }) {
      return new UploadAdapter(loader);
    };
  }

  onSubmit(): void {
    this.loadingData = true;

    this.newsService.createNews(this.createForm.value).subscribe(
      (response: any) => {
        this.notificationService.successMessage(
          NotificationMessages.CREATE_SUCCESS
        );
        this.router.navigateByUrl('/admin/news');
      },
      (error) => {
        this.notificationService.errorMessage(
          NotificationMessages.CREATE_ERROR
        );
      }
    );

    this.loadingData = false;
  }
}
