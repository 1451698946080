import { Scientist } from 'src/app/shared/models/scientist/scientist';
import { useAnimation } from '@angular/animations';
import { trigger } from '@angular/animations';
import { transition } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationService } from 'src/app/services/notification.service';
import { PublicService } from 'src/app/services/public.service';
import { FadeAnimation } from 'src/app/shared/animations/fade-animation';
import { NotificationMessages } from 'src/app/shared/constants/notification-messages';
import { Equipment } from 'src/app/shared/models/equipment/equipment';
import { JobOffer } from 'src/app/shared/models/job-offer/job-offer';
import { News } from 'src/app/shared/models/news/news';
import { ResearchProblem } from 'src/app/shared/models/research-problem/research-problem';
import { Company } from 'src/app/shared/models/company/company';
import { PublicOrganization } from 'src/app/shared/models/public-organization/public-organization';
import { MediaObserver } from '@angular/flex-layout';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-latest',
  templateUrl: './latest.component.html',
  styleUrls: ['./latest.component.css'],
  animations: [
    trigger('fadeAnimation', [
      transition('void => *', [useAnimation(FadeAnimation)]),
    ]),
  ],
})
export class LatestComponent implements OnInit {
  loadingData: boolean;

  latestNews: News[];
  latestCompaniesResearchProblems: ResearchProblem[];
  latestPublicOrganizationsResearchProblems: ResearchProblem[];
  latestJobOffers: JobOffer[];
  latestEquipment: Equipment[];
  latestScientists: Scientist[];
  latestCompanies: Company[];
  latestPublicOrganizations: PublicOrganization[];

  mediaSub: Subscription;
  deviceXs: boolean;

  constructor(
    private publicService: PublicService,
    private notificationService: NotificationService,
    private router: Router,
    public mediaObserver: MediaObserver
  ) {}

  ngOnInit(): void {
    this.loadingData = true;
    this.getLatestEntities();
  }

  getLatestEntities(): void {
    this.getLatestCompaniesResearchProblems();
    this.getLatestPublicOrganizationResearchProblems();
    this.getLatestEquipment();
    this.getLatestJobOffers();
    this.getLatestNews();
    this.getLatestScientists();
    this.getLatestCompanies();
    this.getLatestPublicOrganizations();
  }

  getLatestCompanies() {
    this.publicService.getLatestCompanies(3).subscribe(
      (response: Company[]) => {
        this.latestCompanies = response as Company[];
      },
      () => {
        this.notificationService.errorMessage(NotificationMessages.GET_ERROR);
      }
    );
  }

  getLatestPublicOrganizations() {
    this.publicService.getLatestPublicOrganizations(3).subscribe(
      (response: PublicOrganization[]) => {
        this.latestPublicOrganizations = response as PublicOrganization[];
      },
      () => {
        this.notificationService.errorMessage(NotificationMessages.GET_ERROR);
      }
    );
  }

  getLatestScientists() {
    this.publicService.getLatestScientists(3).subscribe(
      (response: Scientist[]) => {
        this.latestScientists = response as Scientist[];
      },
      () => {
        this.notificationService.errorMessage(NotificationMessages.GET_ERROR);
      }
    );
  }

  getLatestNews(): void {
    this.publicService.getLatestNews(3).subscribe(
      (response: News[]) => {
        this.latestNews = response as News[];
      },
      () => {
        this.notificationService.errorMessage(NotificationMessages.GET_ERROR);
      }
    );
  }

  getLatestPublicOrganizationResearchProblems(): void {
    this.publicService.getLatestPublicOrganizationResearchProblems(3).subscribe(
      (response: ResearchProblem[]) => {
        this.latestPublicOrganizationsResearchProblems = response as ResearchProblem[];
      },
      () => {
        this.notificationService.errorMessage(NotificationMessages.GET_ERROR);
      }
    );
  }

  getLatestCompaniesResearchProblems(): void {
    this.publicService.getLatestCompaniesResearchProblems(3).subscribe(
      (response: ResearchProblem[]) => {
        this.latestCompaniesResearchProblems = response as ResearchProblem[];
      },
      () => {
        this.notificationService.errorMessage(NotificationMessages.GET_ERROR);
      }
    );
  }

  getLatestEquipment(): void {
    this.publicService.getLatestEquipment(3).subscribe(
      (response: Equipment[]) => {
        this.latestEquipment = response as Equipment[];
      },
      () => {
        this.notificationService.errorMessage(NotificationMessages.GET_ERROR);
      }
    );
  }

  getLatestJobOffers(): void {
    this.publicService.getLatestJobOffers(3).subscribe(
      (response: JobOffer[]) => {
        this.latestJobOffers = response as JobOffer[];
        this.loadingData = false;
      },
      () => {
        this.notificationService.errorMessage(NotificationMessages.GET_ERROR);
      }
    );
  }

  companyDetails(companyId: string): void {
    this.router.navigateByUrl('/company/details/' + companyId);
  }

  scientistDetails(scientistId: string): void {
    this.router.navigateByUrl('/scientists/details/' + scientistId);
  }

  publicOrganizationDetails(publicOrganizationId: string): void {
    this.router.navigateByUrl(
      '/publicOrganizations/details/' + publicOrganizationId
    );
  }

  goToNews(): void {
    this.router.navigateByUrl('/news');
  }

  openParentEntity(researchProblem: ResearchProblem): void {
    if (researchProblem.publicOrganizationId !== null) {
      this.router.navigateByUrl(
        '/public-organizations/details/' + researchProblem.publicOrganizationId
      );
    } else {
      this.router.navigateByUrl(
        '/companies/details/' + researchProblem.companyId
      );
    }
  }

  openJobOffers(): void {
    this.router.navigateByUrl('companies/job-offers');
  }

  openCompanyResearchProblems(): void {
    this.router.navigateByUrl('companies/research-problems');
  }

  openPublicOrganizationResearchProblems(): void {
    this.router.navigateByUrl('public-organizations/research-problems');
  }

  openEquipment(): void {
    this.router.navigateByUrl('scientists/equipment');
  }

  openScientists(): void {
    this.router.navigateByUrl('/scientists');
  }

  openNews(newsId: string): void {
    this.router.navigateByUrl('/news/details/' + newsId);
  }

  openPublicOrganization(publicOrganizationId: string) {
    this.router.navigateByUrl('/public-organizations/details/' + publicOrganizationId);
  }

  openScientist(scientistId: string) {
    this.router.navigateByUrl('/scientists/details/' + scientistId);
  }

  openCompany(companyId: string) {
    this.router.navigateByUrl('/companies/details/' + companyId);
  }

  openCompanies(): void{
    this.router.navigateByUrl('/companies');
  }

  openPublicOrganizations(): void{
    this.router.navigateByUrl('/public-organizations');
  }
}
