import { CategoryService } from './../../services/category.service';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { Equipment } from 'src/app/shared/models/equipment/equipment';
import {
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
  OnDestroy,
} from '@angular/core';
import { MatTableDataSource, MatPaginator } from '@angular/material';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { PublicService } from 'src/app/services/public.service';
import { trigger, transition, useAnimation } from '@angular/animations';
import { FadeAnimation } from 'src/app/shared/animations/fade-animation';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-active-equipment',
  templateUrl: './active-equipment.component.html',
  styleUrls: ['./active-equipment.component.css'],
  animations: [
    trigger('fadeAnimation', [
      transition('void => *', [useAnimation(FadeAnimation)]),
    ]),
  ],
})
export class ActiveEquipmentComponent implements OnInit, OnDestroy {
  subscription: Subscription;
  searchForm: FormGroup;
  equipment: Observable<Equipment[]>;
  equipmentDataSource: MatTableDataSource<Equipment>;

  @ViewChild('equipmentPaginator') equipmentPaginator: MatPaginator;

  paginatorText = 'Rezultata po stranici';

  loadingData: boolean;

  constructor(
    private publicService: PublicService,
    private categoryService: CategoryService,
    private router: Router,
    private fb: FormBuilder,
    private changeDetectorRef: ChangeDetectorRef,
    private titleService: Title
  ) {
    this.titleService.setTitle('Istraživačka oprema | RIMAP');
    this.setSearchForm();
  }

  ngOnInit(): void {
    this.loadingData = true;

    this.subscription = this.categoryService
      .listenSelectedCategories()
      .subscribe(() => {
        this.loadingData = true;
        this.getEquipment();
      });

    this.getEquipment();
  }

  initPaginator() {
    this.equipmentPaginator._intl.itemsPerPageLabel = 'Rezultata po stranici';
    this.equipmentPaginator._intl.nextPageLabel = 'Slijedeća stranica';
    this.equipmentPaginator._intl.previousPageLabel = 'Prethodna stranica';
    this.equipmentPaginator._intl.getRangeLabel = (
      page: number,
      pageSize: number,
      length: number
    ) => {
      const start = page * pageSize + 1;
      const end = (page + 1) * pageSize;
      return `${start} - ${end} od ${this.equipmentPaginator.length}`;
    };
  }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.equipmentDataSource.filter = filterValue.trim().toLowerCase();
  }

  setSearchForm() {
    this.searchForm = this.fb.group({
      categories: new FormControl([]),
    });
  }

  openScientist(scientistId: string): void {
    this.router.navigateByUrl('/scientists/details/' + scientistId);
  }

  getEquipment() {
    this.publicService
      .getScientistsEquipment(this.searchForm.value)
      .subscribe((response: Equipment[]) => {
        this.equipmentDataSource = new MatTableDataSource<Equipment>(response);
        this.equipment = this.equipmentDataSource.connect();
        this.loadingData = false;
        this.changeDetectorRef.detectChanges();
        this.equipmentDataSource.paginator = this.equipmentPaginator;
        this.initPaginator();
      });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
