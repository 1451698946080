<mat-tab-group>
  <mat-tab label="Javna organizacija">
    <app-loader *ngIf="loadingData"></app-loader>

    <mat-card *ngIf="!loadingData" class="container-fluid">
      <mat-card-title>{{ editFormTitle }}</mat-card-title>
      <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutAlign="flex-start" *ngIf="!loadingData">
        <mat-card-content>
          <div class="container-fluid">
            <div class="row">
              <img class="img img-fluid entity-image"
                src="{{ publicOrganization.logo || 'assets/logo/image-default.png' }}"
                alt="Slika javne organizacije" />
              <app-file-uploader [entityType]="entityType.PublicOrganization" [entityId]="publicOrganization.id">
              </app-file-uploader>
            </div>
          </div>
          <form [formGroup]="editForm" autocomplete="off" (submit)="onSubmit()">
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Naziv javne organizacije</mat-label>
              <input matInput formControlName="name" />
              <mat-error>Naziv javne organizacije je obavezan podatak.</mat-error>
            </mat-form-field>
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Kratko o javnog organizaciji i opis djelatnosti (5-6 rečenica)</mat-label>
              <textarea rows="10" cols="40" matInput formControlName="description" maxLength="2000"></textarea>
          </mat-form-field>
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Adresa</mat-label>
              <input matInput formControlName="address" />
              <mat-error>Adresa je obavezan podatak.</mat-error>
            </mat-form-field>
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Web stranica</mat-label>
              <input matInput formControlName="website" />
              <mat-error>Web stranica mora počinjati s http:// ili https://</mat-error>            
            </mat-form-field>

            <app-category-picker [title]="categoryPickerTitle" [categoriesEnabled]="true" [parentForm]="editForm"
              [newsLettersEnabled]="true"></app-category-picker>

            <app-tag-picker [title]="tagPickerTitle" [parentForm]="editForm"></app-tag-picker>

            <p align="end">
              <button type="submit" mat-raised-button color="primary" [disabled]="editForm.invalid">
                Spremi promjene
              </button>
            </p>
          </form>
        </mat-card-content>
      </div>
    </mat-card>
  </mat-tab>
  <mat-tab label="Promjena lozinke">
    <app-change-password [entityId]="publicOrganizationId" [entityType]="entityType.PublicOrganization">
    </app-change-password>
  </mat-tab>
  <mat-tab label="Razvojni prijedlozi">
    <app-research-problems [entityType]="entityType.PublicOrganization" [entityId]="publicOrganizationId">
    </app-research-problems>
  </mat-tab>
</mat-tab-group>