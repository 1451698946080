<h1 mat-dialog-title>Slanje upita prema korisniku</h1>

<div mat-dialog-content>
    <form [formGroup]="sendMessageForm" (submit)="onSubmit()">
        <mat-form-field appearance="outline">
            <mat-label>Tekst upita</mat-label>
            <textarea cols="10" rows="5" type="text" matInput formControlName="content"></textarea>
         </mat-form-field>
         <div align="end">
             <button color="primary" type="submit" 
             mat-raised-button [disabled]="sendMessageForm.invalid">Pošalji upit</button>
         </div>
    </form>
</div>