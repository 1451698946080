import { MatDialogRef } from '@angular/material';
import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { NotificationService } from 'src/app/services/notification.service';
import { SpecificKnowledgeService } from 'src/app/services/specific-knowledge.service';
import { NotificationMessages } from 'src/app/shared/constants/notification-messages';

@Component({
  selector: 'app-create-specific-knowledge-dialog',
  templateUrl: './create-specific-knowledge-dialog.component.html',
  styleUrls: ['./create-specific-knowledge-dialog.component.css']
})
export class CreateSpecificKnowledgeDialogComponent implements OnInit {

  @Input() scientistId: string;
 
  createForm: FormGroup;
  loadingData: boolean;

  constructor(
    private specificKnowledgeService: SpecificKnowledgeService,
    private notificationService: NotificationService,
    private dialogRef: MatDialogRef<CreateSpecificKnowledgeDialogComponent>,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.loadingData = true;
    this.setCreateForm();
  }

  setCreateForm(): void {
    this.createForm = this.fb.group({
      description: new FormControl('', Validators.required),
      categories: new FormControl([], Validators.required),
      title: new FormControl('', Validators.required)
    });

    this.loadingData = false;
  }

  onSubmit(): void {
    this.loadingData = true;

    this.prepareCategoriesBeforeSubmit();

    this.specificKnowledgeService
      .createSpecificKnowledge(this.scientistId, this.createForm.value)
      .subscribe(
        (response: any) => {
          this.specificKnowledgeService.pingSpecificKnowledges();
          this.notificationService.successMessage(
            NotificationMessages.CREATE_SUCCESS
          );
          this.createForm.reset();
        },
        (error) => {
          this.notificationService.errorMessage(
            NotificationMessages.CREATE_ERROR
          );
        }
      );

    this.loadingData = false;
  }

  prepareCategoriesBeforeSubmit(): void {
    const realCategories = this.createForm
      .get('categories')
      .value.map((x) => x.id);
    this.createForm.get('categories').setValue(realCategories);
  }

}
