import { EntityType } from './../../shared/enums/entity-type';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import jwtDecode from 'jwt-decode';
import { AuthService } from 'src/app/services/auth.service';
import { AuthToken } from 'src/app/shared/models/auth/auth-token';
import { Title } from '@angular/platform-browser';
 
@Component({     
  selector: 'app-home',  
  templateUrl: './home.component.html',   
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit {  
  userLogged: boolean;

  constructor(private service: AuthService, private router: Router, private titleService: Title) {
    this.titleService.setTitle('Početna | RIMAP');
  }

  public get entityType(): typeof EntityType {
    return EntityType;
  }

  ngOnInit() {
    this.checkIfUserLogged();
    this.listenToLoginStatus();
  }

  listenToLoginStatus(): void {
    this.service.getUserLoginStatus().subscribe((response: any) => {
      this.checkIfUserLogged();
    });
  }

  checkIfUserLogged(): void {
    if (localStorage.getItem('token') != null) {
      const tokenStorageValue = localStorage.getItem('token');
      const decodedToken = jwtDecode(tokenStorageValue) as AuthToken;
      const now = Date.now().valueOf() / 1000;

      if (decodedToken.exp < now) {
        this.userLogged = false;
        localStorage.clear();
        return;
      }

      if (decodedToken.role === 'SuperAdmin') {
        this.userLogged = false;
      } else {
        this.userLogged = true;
      }
    } else {
      this.userLogged = false;
    }
  }

  logOut(): void {
    this.service.logOut();
    this.checkIfUserLogged();
    this.router.navigateByUrl('');
  }
}
