import { PlaceService } from './../../../../services/place.service';
import { Place } from './../../../../shared/models/place/place';
import { Component, Input, OnInit } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
} from '@angular/forms';
import { NotificationService } from 'src/app/services/notification.service';
import { JobOfferService } from 'src/app/services/job-offer.service';
import { NotificationMessages } from 'src/app/shared/constants/notification-messages';
import { ResearchProblem as JobOffer } from 'src/app/shared/models/research-problem/research-problem';
import { DateAdapter } from '@angular/material';

@Component({
  selector: 'app-create-job-offer',
  templateUrl: './create-job-offer.component.html',
  styleUrls: ['./create-job-offer.component.css'],
})
export class CreateJobOfferComponent implements OnInit {
  @Input() companyId: string;

  createForm: FormGroup;
  loadingData: boolean;

  places: Place[];

  constructor(
    private fb: FormBuilder,
    private jobOfferService: JobOfferService,
    private notificationService: NotificationService,
    private placeService: PlaceService,
    private dateAdapter: DateAdapter<any>
  ) {}

  ngOnInit() {
    this.loadingData = true;
    this.setCreateForm();
  }

  setCreateForm(): void {
    this.createForm = this.fb.group({
      name: new FormControl('', Validators.required),
      description: new FormControl('', Validators.required),
      location: new FormControl('', Validators.required),
      deadline: new FormControl(''),
      experience: new FormControl(''),
      education: new FormControl(''),
      specialConditions: new FormControl(''),
      duration: new FormControl(''),
      applyMethod: new FormControl(''),
      additional: new FormControl(''),
      categories: new FormControl([], Validators.required),
    });

    this.dateAdapter.setLocale('hr');

    this.getPlaces();

    this.loadingData = false;
  }

  getPlaces() {
    this.placeService.getPlaces().subscribe(
      (response: Place[]) => {
        this.places = response as Place[];
      });
  }

  onSubmit(): void {
    this.loadingData = true;

    this.prepareCategoriesBeforeSubmit();

    this.jobOfferService
      .createJobOffer(this.companyId, this.createForm.value)
      .subscribe(
        (response: JobOffer) => {
          this.notificationService.successMessage(
            NotificationMessages.CREATE_SUCCESS
          );
          this.jobOfferService.pingJobOffers();
        },
        (error) => {
          this.notificationService.errorMessage(
            NotificationMessages.CREATE_ERROR
          );
        }
      );

    this.loadingData = false;
  }

  prepareCategoriesBeforeSubmit(): void {
    const realCategories = this.createForm
      .get('categories')
      .value.map((x) => x.id);
    this.createForm.get('categories').setValue(realCategories);
  }
}
