import { FormGroup } from '@angular/forms';
import { Component, Input, OnInit } from '@angular/core';
import { ENTER, SEMICOLON } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material';

@Component({
  selector: 'app-tag-picker',
  templateUrl: './tag-picker.component.html',
  styleUrls: ['./tag-picker.component.css']
})
export class TagPickerComponent implements OnInit {

  @Input() parentForm: FormGroup;
  @Input() title: string;

  tagTitle: string;

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER];
  categoryTagsError: boolean;

  loadingData: boolean;

  constructor() {}

  ngOnInit(): void {
    this.loadingData = true;
    this.categoryTagsError = false;
    this.tagTitle = this.title === undefined ? 'Odaberite ključne riječi' : this.title;
    this.loadingData = false;
  }

  get categoryTags() {

    if (this.parentForm.get('categoryTags').value === null){
      this.parentForm.get('categoryTags').setValue('');
      this.parentForm.get('categoryTags').updateValueAndValidity();
    }

    return this.parentForm.get('categoryTags');
  }
  // category tags picker
  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {

      if (this.categoryTags.value.length === 6){
        this.categoryTagsError = true;
      }else{
        this.categoryTags.setValue([...this.categoryTags.value, value.trim()]);
        this.categoryTags.updateValueAndValidity();
      }
    }
    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  remove(categoryTag: string): void {
    const index = this.categoryTags.value.indexOf(categoryTag);

    if (index >= 0) {
      this.categoryTagsError = false;
      this.categoryTags.value.splice(index, 1);
      this.categoryTags.updateValueAndValidity();
    }
  }
}
