
  <h3 class="mt-4" *ngIf="!loadingData">Popis usluga i kompetencija</h3>
  
  <div align="end">
    <mat-form-field appearance="outline">
        <mat-label>Pretraga usluga i kompetencija</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="npr. rad u laboratoriju" #input />
    </mat-form-field>

    
    <button class="mb-3" color="primary" (click)="openCreateSpecificKnowledgeDialog()" mat-raised-button>
        Dodaj novu uslugu i kompetenciju
    </button>
  </div>
  
  <app-loader *ngIf="loadingData"></app-loader>
  
  <div [hidden]="loadingData">
    <table mat-table class="full-width" matSort [dataSource]="dataSource">
  
        <ng-container matColumnDef="edit-buttons">
            <th mat-header-cell *matHeaderCellDef class="white-background"></th>
            <td mat-cell *matCellDef="let element">
                <button mat-icon-button color="primary" (click)="openEditDialog(element.id)">
                    <mat-icon color="primary">create</mat-icon>
                </button>
            </td>
        </ng-container>
  
        <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef class="white-background"></th>
            <td mat-cell *matCellDef="let element">{{ element.description }}</td>
        </ng-container>
  
        <ng-container matColumnDef="delete-buttons">
            <th mat-header-cell *matHeaderCellDef class="white-background"></th>
            <td mat-cell *matCellDef="let element">
                <button *ngIf="admin" mat-icon-button color="primary" (click)="openVisibilityDialog(element.id)">
                    <mat-icon *ngIf="element.isActive" color="primary">visibility</mat-icon>
                    <mat-icon *ngIf="!element.isActive" color="primary">visibility_off</mat-icon>
                </button>
                <button mat-icon-button color="primary" (click)="openDeleteDialog(element.id)">
                    <mat-icon color="primary">delete</mat-icon>
                </button>
            </td>
        </ng-container>
  
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  
        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="4">
                Nema podataka koji odgovaraju "{{ input.value }}"
            </td>
        </tr>
    </table>
  
    <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
  </div>