<div [@fadeAnimation] class="container-fluid text-left snackbar-container">
    <div class="row">
        <div class="snackbar-content">
            <span class="snackbar-text">{{snackBarText}}</span>
        </div>
    </div>
    <button class="snackbar-dismiss-icon" (click)="onDismiss()" mat-icon-button>
        <mat-icon>close</mat-icon>
    </button>
</div>
