import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { tap } from 'rxjs/operators';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService, private router: Router) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (this.authService.userLogged() || this.authService.isSuperAdminLogged()) {
      const clonedRequest = request.clone({
        headers: request.headers.set(
          'Authorization',
          'Bearer ' + localStorage.getItem('token')
        ),
      });

      return next.handle(clonedRequest).pipe(
        tap(
          (success) => {},
          (error) => {
            if (error.status === 401) {
              const logoutUrl = this.authService.getLogoutUrl();
              localStorage.removeItem('token');
              this.router.navigateByUrl(logoutUrl);
            }
          }
        )
      );
    } else {
      return next.handle(request.clone());
    }
  }
}
