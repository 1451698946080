<app-loader *ngIf="loadingData"></app-loader>

<div [formGroup]="parentForm" *ngIf="!loadingData">
  <mat-form-field class="full-width" appearance="outline">
    <mat-label>{{tagTitle}}</mat-label> 
    <mat-chip-list 
      #chipList
      aria-label="Odaberite ključne riječi"
      formControlName="categoryTags"
    >
      <mat-chip
        color="primary"
        *ngFor="let categoryTag of categoryTags.value"
        [selectable]="selectable"
        [removable]="removable"
        (removed)="remove(categoryTag)"
      >
        {{ categoryTag }}
        <mat-icon color="primary" matChipRemove *ngIf="removable">cancel</mat-icon>
      </mat-chip>
      <input
        placeholder="Upišite novu ključnu riječ..."
        [matChipInputFor]="chipList"
        [matChipInputAddOnBlur]="addOnBlur"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        (matChipInputTokenEnd)="add($event)"
      />
      <mat-error *ngIf="categoryTagsError"
        >Možete dodati maksimalno 6 ključnih riječi.</mat-error
      >
    </mat-chip-list>
  </mat-form-field>
</div>
