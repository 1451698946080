import { EntityType } from 'src/app/shared/enums/entity-type';
import { PlaceService } from './../../../../services/place.service';
import { MatDialogRef } from '@angular/material/dialog';
import { Component, Input, OnInit } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
} from '@angular/forms';
import { JobOfferService } from 'src/app/services/job-offer.service';
import { NotificationService } from 'src/app/services/notification.service';
import { NotificationMessages } from 'src/app/shared/constants/notification-messages';
import { JobOffer } from 'src/app/shared/models/job-offer/job-offer';
import { Place } from 'src/app/shared/models/place/place';

@Component({
  selector: 'app-edit-job-offer',
  templateUrl: './edit-job-offer.component.html',
  styleUrls: ['./edit-job-offer.component.css'],
})
export class EditJobOfferComponent implements OnInit {
  @Input() companyId: string;
  @Input() jobOfferId: string;

  editForm: FormGroup;
  loadingData: boolean;
  jobOffer: JobOffer;
  places: Place[];

  public entityType = EntityType;

  constructor(
    private fb: FormBuilder,
    private jobOfferService: JobOfferService,
    private placeService: PlaceService,
    private notificationService: NotificationService,
    public dialogRef: MatDialogRef<EditJobOfferComponent>
  ) {}

  ngOnInit() {
    this.loadingData = true;
    this.getJobOffer();
  }

  setEditForm(): void {
    this.editForm = this.fb.group({
      name: new FormControl(this.jobOffer.name, Validators.required),
      description: new FormControl(
        this.jobOffer.description,
        Validators.required
      ),
      location: new FormControl(this.jobOffer.location, Validators.required),
      deadline: new FormControl(this.jobOffer.deadline),
      experience: new FormControl(this.jobOffer.experience),
      education: new FormControl(this.jobOffer.education),
      specialConditions: new FormControl(this.jobOffer.specialConditions),
      duration: new FormControl(this.jobOffer.duration),
      applyMethod: new FormControl(this.jobOffer.applyMethod),
      additional: new FormControl(this.jobOffer.additional),
      categories: new FormControl(this.jobOffer.categories),
    });

    this.loadingData = false;
  }

  getJobOffer(): void {
    this.jobOfferService.getJobOffer(this.jobOfferId, this.companyId).subscribe(
      (response: JobOffer) => {
        this.jobOffer = response as JobOffer;
        this.getPlaces();
        this.setEditForm();
      },
      (error) => {
        this.notificationService.errorMessage(NotificationMessages.GET_ERROR);
      }
    );
  }

  getPlaces() {
    this.placeService.getPlaces().subscribe(
      (response: Place[]) => {
        this.places = response as Place[];
      });
  }

  onNoClick() {
    this.dialogRef.close();
  }


  onSubmit(): void {
    this.loadingData = true;

    this.prepareCategoriesBeforeSubmit();

    this.jobOfferService
      .editJobOffer(this.jobOfferId, this.companyId, this.editForm.value)
      .subscribe(
        (response: JobOffer) => {
          this.notificationService.successMessage(
            NotificationMessages.UPDATE_SUCCESS
          );
          this.jobOfferService.pingJobOffers();
        },
        (error) => {
          this.notificationService.errorMessage(
            NotificationMessages.UPDATE_ERROR
          );
        }
      );

    this.loadingData = false;
  }

  prepareCategoriesBeforeSubmit(): void {
    const realCategories = this.editForm
      .get('categories')
      .value.map((x) => x.id);
    this.editForm.get('categories').setValue(realCategories);
  }
}
