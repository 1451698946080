import { AuthService } from './../../../services/auth.service';
import { MatDialog } from '@angular/material';
import { PublicOrganizationService } from 'src/app/services/public-organization.service';
import { Component, OnInit } from '@angular/core'; 
import { ActivatedRoute } from '@angular/router';
import { NotificationService } from 'src/app/services/notification.service';
import { NotificationMessages } from 'src/app/shared/constants/notification-messages';
import { PublicOrganization } from 'src/app/shared/models/public-organization/public-organization';
import { PublicService } from 'src/app/services/public.service';
import { trigger, transition, useAnimation } from '@angular/animations';
import { FadeAnimation } from 'src/app/shared/animations/fade-animation';
import { SendMessageComponent } from 'src/app/components/send-message/send-message.component';
import { EntityType } from 'src/app/shared/enums/entity-type';
import { WarningWindowComponent } from 'src/app/components/warning-window/warning-window.component';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-public-organization-details',
  templateUrl: './public-organization-details.component.html',
  styleUrls: ['./public-organization-details.component.css'],
  animations: [
    trigger('fadeAnimation', [
      transition('void => *', [useAnimation(FadeAnimation)]),
    ]),
  ],
})
export class PublicOrganizationDetailsComponent implements OnInit {

  publicOrganizationId: string; 
  publicOrganization: PublicOrganization;

  loadingData: boolean;

  userLogged: boolean;

  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private notificationService: NotificationService,
    private publicService: PublicService,
    private dialog: MatDialog,
    private titleService: Title) {}

  ngOnInit(): void {
    this.loadingData = true;
    this.getPublicOrganization();
  }

  openSendMessage(): void {
    return this.userLogged ? this.openContactDialog() : this.openInfoDialog();
  }

  openInfoDialog(): void {
    this.dialog.open(WarningWindowComponent);
  }

  openContactDialog(): void {
    const dialogRef = this.dialog.open(SendMessageComponent, {
      panelClass: 'send-message-dialog'
    });
    dialogRef.componentInstance.receiverId = this.publicOrganization.id;
    dialogRef.componentInstance.receiverType = EntityType[1].toString();

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.loadingData = true;
        this.notificationService.successMessage(NotificationMessages.MESSAGE_SENT);
        this.getPublicOrganization();
      }
    });
  }

  getPublicOrganization() {
    this.publicOrganizationId = this.route.snapshot.paramMap.get('id');

    this.publicService.getPublicOrganization(this.publicOrganizationId).subscribe(
      (publicOrganization: PublicOrganization) => {
        this.publicOrganization = publicOrganization as PublicOrganization;
        this.userLogged = this.authService.userLogged();
        this.titleService.setTitle(this.publicOrganization.name + ' | RIMAP');
        this.loadingData = false;
      },
      (error) => {
        this.notificationService.errorMessage(NotificationMessages.GET_ERROR);
      }
    );
  }
}
