import { ActivitiesComponent } from './activities/activities.component';
import { AdminGuard } from './../../guards/admin.guard';
import { CreateNewsComponent } from './news/create-news/create-news.component';
import { EditNewsComponent } from './news/edit-news/edit-news.component';
import { NewsListComponent } from './news/news-list/news-list.component';
import { NewsComponent } from './news/news.component';
import { CompaniesComponent } from './companies/companies.component';
import { LoginComponent } from './../login/login.component';
import { AdminComponent } from './admin.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { CreateCategoryComponent } from './categories/create-category/create-category.component';
import { EditCategoryComponent } from './categories/edit-category/edit-category.component';
import { CreateCompanyComponent } from './companies/create-company/create-company.component';
import { EditCompanyComponent } from './companies/edit-company/edit-company.component';
import { CreatePublicOrganizationComponent } from './public-organizations/create-public-organization/create-public-organization.component';
import { EditPublicOrganizationComponent } from './public-organizations/edit-public-organization/edit-public-organization.component';
import { CreateScientistComponent } from './scientists/create-scientist/create-scientist.component';
import { EditScientistComponent } from './scientists/edit-scientist/edit-scientist.component';
import { CreateUserComponent } from './users/create-user/create-user.component';
import { EditUserComponent } from './users/edit-user/edit-user.component';
import { CompaniesListComponent } from './companies/companies-list/companies-list.component';
import { UsersListComponent } from './users/users-list/users-list.component';
import { PublicOrganizationsListComponent } from './public-organizations/public-organizations-list/public-organizations-list.component';
import { ScientistsListComponent } from './scientists/scientists-list/scientists-list.component';
import { CategoriesListComponent } from './categories/categories-list/categories-list.component';
import { ScientistsComponent } from './scientists/scientists.component';
import { UsersComponent } from './users/users.component';
import { PublicOrganizationsComponent } from './public-organizations/public-organizations.component';
import { CategoriesComponent } from './categories/categories.component';

const routes: Routes = [
  {
    path: 'admin',
    component: AdminComponent,
    canActivate: [AdminGuard],
    children: [
      {
        path: 'categories',
        component: CategoriesComponent,
        children: [
          {
            path: '',
            component: CategoriesListComponent,
          },
          {
            path: 'create',
            component: CreateCategoryComponent,
          },
          {
            path: 'edit/:id',
            component: EditCategoryComponent,
          },
        ],
      },
      {
        path: 'companies',
        component: CompaniesComponent,
        children: [
          {
            path: '',
            component: CompaniesListComponent,
          },
          {
            path: 'create',
            component: CreateCompanyComponent,
          },
          {
            path: 'edit/:id',
            component: EditCompanyComponent,
          },
        ],
      },
      {
        path: 'scientists',
        component: ScientistsComponent,
        children: [
          {
            path: '',
            component: ScientistsListComponent,
          },
          {
            path: 'create',
            component: CreateScientistComponent,
          },
          {
            path: 'edit/:id',
            component: EditScientistComponent,
          },
        ],
      },
      {
        path: 'users',
        component: UsersComponent,
        children: [
          {
            path: '',
            component: UsersListComponent,
          },
          {
            path: 'create',
            component: CreateUserComponent,
          },
          {
            path: 'edit/:id',
            component: EditUserComponent,
          },
        ],
      },
      {
        path: 'public-organizations',
        component: PublicOrganizationsComponent,
        children: [
          {
            path: '',
            component: PublicOrganizationsListComponent,
          },
          {
            path: 'create',
            component: CreatePublicOrganizationComponent,
          },
          {
            path: 'edit/:id',
            component: EditPublicOrganizationComponent,
          },
        ],
      },
      {
        path: 'news',
        component: NewsComponent,
        children: [
          {
            path: '',
            component: NewsListComponent,
          },
          {
            path: 'create',
            component: CreateNewsComponent,
          },
          {
            path: 'edit/:id',
            component: EditNewsComponent,
          },
        ],
      },
      {
        path: 'activities',
        component: ActivitiesComponent
      },
    ],
  },
  {
    path: 'login',
    component: LoginComponent,
  },
];

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
    CommonModule,
  ],
  exports: [RouterModule],
})
export class AdminRoutingModule {}
