
<h3 class="mt-4" *ngIf="!loadingData">Popis razvojnih prijedloga</h3>

<div align="end" *ngIf="!loadingData">
    <mat-form-field appearance="outline">
        <mat-label>Pretraga razvojnih prijedloga</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="npr. kompresija podataka" #input />
    </mat-form-field>

    <button class="mb-3" color="primary" (click)="openCreateResearchProblemDialog()" mat-raised-button>
        Dodaj novi razvojni prijedlog
    </button>

</div>

<app-loader *ngIf="loadingData"></app-loader>

<div [hidden]="loadingData">
    <table mat-table class="full-width" matSort [dataSource]="dataSource">

        <ng-container matColumnDef="edit-buttons">
            <th mat-header-cell *matHeaderCellDef class="white-background"></th>
            <td mat-cell *matCellDef="let element">
                <button mat-icon-button color="primary" (click)="openEditDialog(element.id)">
                    <mat-icon class="primary">create</mat-icon>
                </button>
            </td>
        </ng-container>

        <ng-container matColumnDef="title">
            <th mat-header-cell *matHeaderCellDef class="white-background"></th>
            <td mat-cell *matCellDef="let element">{{ element.title }}</td>
        </ng-container>

        <ng-container matColumnDef="delete-buttons">
            <th mat-header-cell *matHeaderCellDef class="white-background"></th>
            <td mat-cell *matCellDef="let element">
              <button *ngIf="admin" mat-icon-button color="primary" (click)="openVisibilityDialog(element.id)">
                <mat-icon *ngIf="element.isActive" color="primary">visibility</mat-icon>
                <mat-icon *ngIf="!element.isActive" color="primary">visibility_off</mat-icon>
              </button>
              <button mat-icon-button color="primary" (click)="openDeleteDialog(element.id)">
                <mat-icon color="primary">delete</mat-icon>
            </button>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

    </table>

    <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
</div>