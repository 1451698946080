<header class="masthead header-scientists">
    <div class="container-fluid h-100">
        <div class="row h-100 align-items-center">
            <div class="container text-center">
                <h1 class="page-header-title">Pretraga usluga i kompetencija</h1>
            </div>
        </div>
    </div>
</header>

<div class="container">
    <section class="tvrtke pb-5">
        <div class="container">

            <div class="d-flex justify-content-center">
                <mat-form-field appearance="outline">
                    <input matInput type="text" autocomplete="off" (keyup)="applyFilter($event)"
                        placeholder="Pretraga...">
                </mat-form-field>
            </div>

            <div class="d-flex justify-content-center">
                <div [formGroup]="searchForm">
                    <app-category-search-picker [parentForm]="searchForm"></app-category-search-picker>
                </div>
            </div>

            <app-loader *ngIf="loadingData"></app-loader>

            <div class="row">
                <div [hidden]="loadingData" [@fadeAnimation]
                    *ngFor="let specificKnowledge of specificKnowledges | async" class="col-xs-12">

                    <div class="image-flip">
                        <div class="mainflip flip-0">
                            <div class="frontside">
                                <div class="card">
                                    <div class="card-body text-left">
                                        <p>
                                            <a (click)="openScientist(specificKnowledge.scientistId)">
                                                <img class=" img-fluid entity-profile-picture"
                                                    [src]="specificKnowledge.image || 'assets/logo/image-default.png'"
                                                    alt="card image">
                                            </a>
                                        </p>
                                        <h3 class="card-title">
                                            <a (click)="openScientist(specificKnowledge.scientistId)">
                                                {{specificKnowledge.title}}
                                            </a>
                                        </h3>
                                    </div>
                                </div>
                            </div>
                            <div class="backside">
                                <div class="card">
                                    <div class="card-body text-left ">
                                        <div class="card-text">
                                            <p>
                                                {{specificKnowledge.description?.substring(0, 100) + '...'}}
                                            </p>
                                        </div>
                                        <button color="primary" (click)="openScientist(specificKnowledge.scientistId)"
                                            mat-raised-button>
                                            Detalji i kontakt istraživača 
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <mat-paginator #specificKnowledgesPaginator [pageSizeOptions]="[6, 9, 12, 15]"></mat-paginator>
        </div>
    </section>
</div>