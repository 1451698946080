import { AuthService } from './../../../services/auth.service';
import { MatDialog } from '@angular/material';
import { PublicService } from 'src/app/services/public.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { Scientist } from 'src/app/shared/models/scientist/scientist';
import { NotificationMessages } from 'src/app/shared/constants/notification-messages';
import { FadeAnimation } from 'src/app/shared/animations/fade-animation';
import { transition, trigger, useAnimation } from '@angular/animations';
import { SendMessageComponent } from 'src/app/components/send-message/send-message.component';
import { EntityType } from 'src/app/shared/enums/entity-type';
import { WarningWindowComponent } from 'src/app/components/warning-window/warning-window.component';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-scientist-details',
  templateUrl: './scientist-details.component.html',
  styleUrls: ['./scientist-details.component.css'],
  animations: [
    trigger('fadeAnimation', [
      transition('void => *', [useAnimation(FadeAnimation)]),
    ]),
  ],
})
export class ScientistDetailsComponent implements OnInit {
  scientistId: string;
  scientist: Scientist;
  loadingData: boolean;
  userLogged: boolean;

  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private notificationService: NotificationService,
    private publicService: PublicService,
    private dialog: MatDialog,
    private titleService: Title
  ) {}

  ngOnInit(): void {
    this.loadingData = true;
    this.getScientist();
  }

  openSendMessage(): void {
    return this.userLogged ? this.openContactDialog() : this.openInfoDialog();
  }

  openInfoDialog(): void {
    this.dialog.open(WarningWindowComponent);
  }

  openContactDialog(): void {
    const dialogRef = this.dialog.open(SendMessageComponent, {
      panelClass: 'send-message-dialog',
    });
    dialogRef.componentInstance.receiverId = this.scientist.id;
    dialogRef.componentInstance.receiverType = EntityType[2].toString();

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.loadingData = true;
        this.notificationService.successMessage(
          NotificationMessages.MESSAGE_SENT
        );
        this.getScientist();
      }
    });
  }

  getScientist() {
    this.scientistId = this.route.snapshot.paramMap.get('id');

    this.publicService.getScientist(this.scientistId).subscribe(
      (scientist: Scientist) => {
        this.scientist = scientist as Scientist;
        this.userLogged = this.authService.userLogged();
        this.titleService.setTitle(
          this.scientist.firstname + ' ' + this.scientist.lastname + ' | RIMAP'
        );
        this.loadingData = false;
      },
      () => {
        this.notificationService.errorMessage(NotificationMessages.GET_ERROR);
      }
    );
  }
}
