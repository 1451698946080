<h1 mat-dialog-title>Kontakt RIMAP korisnika</h1>

<div mat-dialog-content>
    <p class="news-text-time warning-text">
      <strong class="mr-2">Upozorenje: </strong>Kako biste kontaktirali RIMAP korisnika morate biti 
      prijavljeni na platformi.
  </p>
</div>
<div mat-dialog-actions align="end">
  <button mat-raised-button color="primary" [mat-dialog-close]="true" cdkFocusInitial>U redu</button>
</div>