import { EmbedVideoPipe } from './shared/pipes/embed-video.pipe';
import { CreateJobOfferDialogComponent } from './components/create-dialogs/create-job-offer-dialog/create-job-offer-dialog.component';
import { CreateResearchProblemDialogComponent } from './components/create-dialogs/create-research-problem-dialog/create-research-problem-dialog.component';
import { CreateSpecificKnowledgeDialogComponent } from './components/create-dialogs/create-specific-knowledge-dialog/create-specific-knowledge-dialog.component';
import { ActivitiesComponent } from './pages/admin/activities/activities.component';
import { ActiveSpecificKnowledgesComponent } from './pages/active-specific-knowledges/active-specific-knowledges.component';
import { ActiveResearchProblemsComponent } from './pages/active-research-problems/active-research-problems.component';
import { ActiveEquipmentComponent } from './pages/active-equipment/active-equipment.component';
import { NewsDetailsComponent } from './pages/details-pages/news-details/news-details.component';
import { StartComponent } from './components/start/start.component';
import { LatestComponent } from './components/latest/latest.component';
import { ScientistDetailsComponent } from './pages/details-pages/scientist-details/scientist-details.component';
import { PublicOrganizationDetailsComponent } from './pages/details-pages/public-organization-details/public-organization-details.component';
import { CompanyDetailsComponent } from './pages/details-pages/company-details/company-details.component';
import { FooterComponent } from './components/footer/footer.component';
import { AboutComponent } from './pages/about/about.component';
import { ContactComponent } from './pages/contact/contact.component';
import { ActiveNewsComponent } from './pages/active-news/active-news.component';
import { ActiveScientistsComponent } from './pages/active-scientists/active-scientists.component';
import { ActivePublicOrganizationsComponent } from './pages/active-public-organizations/active-public-organizations.component';
import { ActiveCompaniesComponent } from './pages/active-companies/active-companies.component';
import { AlreadyConfirmedComponent } from './pages/already-confirmed/already-confirmed.component';
import { ThankYouComponent } from './pages/thank-you/thank-you.component';
import { UnsubscribedComponent } from './pages/unsubscribed/unsubscribed.component';
import { EditSpecificKnowledgeComponent } from './pages/admin/specific-knowledges/edit-specific-knowledge/edit-specific-knowledge.component';
import { CreateSpecificKnowledgeComponent } from './pages/admin/specific-knowledges/create-specific-knowledge/create-specific-knowledge.component';
import { SpecificKnowledgesListComponent } from './pages/admin/specific-knowledges/specific-knowledges-list/specific-knowledges-list.component';
import { SpecificKnowledgesComponent } from './pages/admin/specific-knowledges/specific-knowledges.component';
import { EditEquipmentComponent } from './pages/admin/equipment/edit-equipment/edit-equipment.component';
import { CreateEquipmentComponent } from './pages/admin/equipment/create-equipment/create-equipment.component';
import { EquipmentListComponent } from './pages/admin/equipment/equipment-list/equipment-list.component';
import { EquipmentComponent } from './pages/admin/equipment/equipment.component';
import { EditNewsComponent } from './pages/admin/news/edit-news/edit-news.component';
import { NewsListComponent } from './pages/admin/news/news-list/news-list.component';
import { NewsComponent } from './pages/admin/news/news.component';
import { NotificationMessageComponent } from './components/notification-message/notification-message.component';
import { UserLoginComponent } from './pages/user-login/user-login.component';
import { CategoryPickerComponent } from './components/category-picker/category-picker.component';
import { TagPickerComponent } from './components/tag-picker/tag-picker.component';
import { DeleteDialogComponent } from './components/delete-dialog/delete-dialog.component';
import { FormsModule, NgForm, ReactiveFormsModule } from '@angular/forms';
import { PasswordResetComponent } from './pages/password-reset/password-reset.component';
import { HelpComponent } from './pages/admin/help/help.component';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';

import { AppComponent } from './app.component';
import { LoaderComponent } from './components/loader/loader.component';
import { LoginComponent } from './pages/login/login.component';
import { RegistrationComponent } from './pages/registration/registration.component';
import { NavigationComponent } from './components/navigation/navigation.component';
import { AdminComponent } from './pages/admin/admin.component';
import { HomeComponent } from './pages/home/home.component';
import { AdminRoutingModule } from './pages/admin/admin-routing.module';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularMaterialModule } from './angular-material.module';
import { HttpClientModule } from '@angular/common/http';
import { CategoriesListComponent } from './pages/admin/categories/categories-list/categories-list.component';
import { CreateCategoryComponent } from './pages/admin/categories/create-category/create-category.component';
import { EditCategoryComponent } from './pages/admin/categories/edit-category/edit-category.component';
import { CompaniesListComponent } from './pages/admin/companies/companies-list/companies-list.component';
import { CreateCompanyComponent } from './pages/admin/companies/create-company/create-company.component';
import { EditCompanyComponent } from './pages/admin/companies/edit-company/edit-company.component';
import { CreateScientistComponent } from './pages/admin/scientists/create-scientist/create-scientist.component';
import { EditScientistComponent } from './pages/admin/scientists/edit-scientist/edit-scientist.component';
import { ScientistsListComponent } from './pages/admin/scientists/scientists-list/scientists-list.component';
import { CreateUserComponent } from './pages/admin/users/create-user/create-user.component';
import { EditUserComponent } from './pages/admin/users/edit-user/edit-user.component';
import { UsersListComponent } from './pages/admin/users/users-list/users-list.component';
import { CategoriesComponent } from './pages/admin/categories/categories.component';
import { CompaniesComponent } from './pages/admin/companies/companies.component';
import { ScientistsComponent } from './pages/admin/scientists/scientists.component';
import { PublicOrganizationsComponent } from './pages/admin/public-organizations/public-organizations.component';
import { UsersComponent } from './pages/admin/users/users.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { MyProfileComponent } from './pages/my-profile/my-profile.component';
import { FileUploaderComponent } from './components/file-uploader/file-uploader.component';
import { CreateResearchProblemComponent } from './pages/admin/research-problems/create-research-problem/create-research-problem.component';
import { ResearchProblemsListComponent } from './pages/admin/research-problems/research-problems-list/research-problems-list.component';
import { EditResearchProblemComponent } from './pages/admin/research-problems/edit-research-problem/edit-research-problem.component';
import { JobOffersComponent } from './pages/admin/job-offers/job-offers.component';
import { JobOffersListComponent } from './pages/admin/job-offers/job-offers-list/job-offers-list.component';
import { EditJobOfferComponent } from './pages/admin/job-offers/edit-job-offer/edit-job-offer.component';
import { CreateJobOfferComponent } from './pages/admin/job-offers/create-job-offer/create-job-offer.component';
import { ResearchProblemsComponent } from './pages/admin/research-problems/research-problems.component';
import { PublicOrganizationsListComponent } from './pages/admin/public-organizations/public-organizations-list/public-organizations-list.component';
import { CreatePublicOrganizationComponent } from './pages/admin/public-organizations/create-public-organization/create-public-organization.component';
import { EditPublicOrganizationComponent } from './pages/admin/public-organizations/edit-public-organization/edit-public-organization.component';
import { CreateNewsComponent } from './pages/admin/news/create-news/create-news.component';
import { VisibilityDialogComponent } from './components/visibility-dialog/visibility-dialog.component';
import { MainSearchComponent } from './pages/main-search/main-search.component';

import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { NewSubscriberComponent } from './components/new-subscriber/new-subscriber.component';
import { ActiveJobOffersComponent } from './pages/active-job-offers/active-job-offers.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { CategorySearchPickerComponent } from './components/category-search-picker/category-search-picker.component';
import { SendMessageComponent } from './components/send-message/send-message.component';
import { WarningWindowComponent } from './components/warning-window/warning-window.component';
import { CreateEquipmentDialogComponent } from './components/create-dialogs/create-equipment-dialog/create-equipment-dialog.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { SafeHtmlPipe } from './shared/pipes/safe-html.pipe';


@NgModule({
  declarations: [
    AppComponent,
    LoaderComponent,
    LoginComponent,
    RegistrationComponent,
    NavigationComponent,
    AdminComponent,
    HomeComponent,
    HelpComponent,
    PasswordResetComponent,
    CategoriesComponent,
    CategoriesListComponent,
    CreateCategoryComponent,
    EditCategoryComponent,
    CompaniesComponent,
    CompaniesListComponent,
    CreateCompanyComponent,
    EditCompanyComponent,
    ScientistsComponent,
    ScientistsListComponent,
    CreateScientistComponent,
    EditScientistComponent,
    PublicOrganizationsComponent,
    PublicOrganizationsListComponent,
    CreatePublicOrganizationComponent,
    EditPublicOrganizationComponent,
    UsersComponent,
    UsersListComponent,
    CreateUserComponent,
    EditUserComponent,
    DeleteDialogComponent,
    TagPickerComponent,
    CategoryPickerComponent,
    UserLoginComponent,
    MyProfileComponent,
    NotificationMessageComponent,
    FileUploaderComponent,
    ResearchProblemsComponent,
    ResearchProblemsListComponent,
    CreateResearchProblemComponent,
    EditResearchProblemComponent,
    JobOffersComponent,
    JobOffersListComponent,
    CreateJobOfferComponent,
    EditJobOfferComponent,
    NewsComponent,
    NewsListComponent,
    CreateNewsComponent,
    EditNewsComponent,
    EquipmentComponent,
    EquipmentListComponent,
    CreateEquipmentComponent,
    EditEquipmentComponent,
    SpecificKnowledgesComponent,
    SpecificKnowledgesListComponent,
    CreateSpecificKnowledgeComponent,
    EditSpecificKnowledgeComponent,
    VisibilityDialogComponent,
    EditJobOfferComponent,
    EditEquipmentComponent,
    EditResearchProblemComponent,
    MainSearchComponent,
    NewSubscriberComponent,
    UnsubscribedComponent,
    ThankYouComponent,
    AlreadyConfirmedComponent,
    ActiveCompaniesComponent,
    ActivePublicOrganizationsComponent,
    ActiveScientistsComponent,
    ActiveNewsComponent,
    ContactComponent,
    AboutComponent,
    FooterComponent,
    CompanyDetailsComponent,
    PublicOrganizationDetailsComponent,
    ScientistDetailsComponent,
    LatestComponent,
    StartComponent,
    CompanyDetailsComponent,
    NewsDetailsComponent,
    ScientistDetailsComponent,
    PublicOrganizationDetailsComponent,
    ActiveEquipmentComponent,
    ActiveResearchProblemsComponent,
    ActiveSpecificKnowledgesComponent,
    ActiveJobOffersComponent,
    NotFoundComponent,
    ChangePasswordComponent,
    ActivitiesComponent,
    CategorySearchPickerComponent,
    SendMessageComponent,
    WarningWindowComponent,
    CreateEquipmentDialogComponent,
    CreateSpecificKnowledgeDialogComponent,
    CreateResearchProblemDialogComponent,
    CreateJobOfferDialogComponent,
    SafeHtmlPipe,
    EmbedVideoPipe
  ],
  imports: [
    BrowserModule,
    AdminRoutingModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AngularMaterialModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    Ng2SearchPipeModule,
    FlexLayoutModule,
    CKEditorModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    PasswordResetComponent,
    DeleteDialogComponent,
    NotificationMessageComponent,
    EditResearchProblemComponent,
    EditEquipmentComponent,
    EditJobOfferComponent,
    EditSpecificKnowledgeComponent,
    VisibilityDialogComponent,
    SendMessageComponent,
    WarningWindowComponent,
    CreateEquipmentDialogComponent,
    CreateSpecificKnowledgeDialogComponent,
    CreateResearchProblemDialogComponent,
    CreateJobOfferDialogComponent,
  ],
})
export class AppModule {}
