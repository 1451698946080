<app-loader *ngIf="loadingData"></app-loader>

<mat-card *ngIf="!loadingData">
  <mat-card-title>Nova objava</mat-card-title>
  <mat-card-content>
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-6 col-sm-12 col-xs-12">
          <img class="img img-fluid entity-image" src="{{ news.featuredImage || 'assets/logo/image-default.png' }}"
            alt="Slika Objave" />
        </div>
        <app-file-uploader [entityType]="entityType.News" [entityId]="news.id">
        </app-file-uploader>
      </div>
    </div>
    <form [formGroup]="editForm" autocomplete="off" (submit)="onSubmit()">
      <mat-form-field appearance="outline">
        <mat-label>Naslov objave</mat-label>
        <input matInput formControlName="title" />
        <mat-error>Naslov objave je obavezan podatak.</mat-error>
      </mat-form-field>

      <mat-label>Sadržaj objave</mat-label>
      <ckeditor [config]="editorConfig.getConfig()" [editor]="Editor" formControlName="content" (ready)="onReady($event)"></ckeditor>

      <p align="end">
        <button type="submit" mat-raised-button color="primary" [disabled]="editForm.invalid">
          Spremi promjene
        </button>
      </p>
    </form>
  </mat-card-content>
</mat-card>