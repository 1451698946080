import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-warning-window',
  templateUrl: './warning-window.component.html',
  styleUrls: ['./warning-window.component.css']
})
export class WarningWindowComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<WarningWindowComponent>) { }

  ngOnInit() {
  }

  onNoClick() {
    this.dialogRef.close();
  }

}
