import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import jwtDecode from 'jwt-decode';
import { AuthToken } from 'src/app/shared/models/auth/auth-token';

@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.css'],
})
export class MyProfileComponent implements OnInit {
  entityId: string;
  loadingData: boolean;
  companyProfile: boolean;
  publicOrganizationProfile: boolean;
  scientistProfile: boolean;

  myProfileTitle = 'Uređivanje profila';

  constructor(private titleService: Title) {
    this.titleService.setTitle('Moj profil | RIMAP');
  }

  ngOnInit() {
    this.loadingData = true;
    this.resetProfileForms();
    this.setProfileData();
  }
 
  setProfileData() {
    const tokenStorageValue = localStorage.getItem('token');
    const decodedToken = jwtDecode(tokenStorageValue) as AuthToken;
    this.entityId = decodedToken.nameid;
    this.setProfileType(decodedToken.role);
  }

  setProfileType(role: string) {
    switch (role) {
      case 'PublicOrganization':
        this.publicOrganizationProfile = true;
        break;
      case 'Company':
        this.companyProfile = true;
        break;
      case 'Scientist':
        this.scientistProfile = true;
        break;
      default:
        break;
    }
  }

  resetProfileForms() {
    this.publicOrganizationProfile = false;
    this.companyProfile = false;
    this.scientistProfile = false;
  }
}
