import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { News } from '../shared/models/news/news';

@Injectable({
  providedIn: 'root'
})
export class NewsService {

  newsUrl = environment.apiUrl + '/news';

  constructor(private http: HttpClient) { }

  getNews(): Observable<News[]> {
    return this.http.get<News[]>(this.newsUrl + '/get');
  }

  getLatestNews(numberOfPosts: number): Observable<News[]> {
    return this.http.get<News[]>(this.newsUrl + '/getLatest/' + numberOfPosts);
  }

  getSingleNews(newsId: string) {
    return this.http.get<News>(this.newsUrl + '/get/' + newsId);
  }

  createNews(news: News) {
    return this.http.post(this.newsUrl + '/create', news);
  }

  editNews(newsId: string, news: News) {
    return this.http.post(this.newsUrl + '/edit/' + newsId, news);
  }

  flipNewsActive(newsId: string) {
    return this.http.post(this.newsUrl + '/flipActive/' + newsId, null);
  }

  deleteNews(newsId: string) {
    return this.http.post(this.newsUrl + '/delete/' + newsId, null);
  }

}
