<div class="container" [@fadeAnimation]>
  <div>
    <div class="mainflip flip-0">
      <div class="frontside">
        <div class="card" align="center">
          <div class="card-body text-center">
            <h2>Molimo odaberite vrstu registracije</h2>

            <mat-radio-group aria-labelledby="Odabir vrste registracije" class="full-width"
              [(ngModel)]="registrationType" (change)="getRegistrationForm()">
              <mat-radio-button class="radio-button" [value]="1">Tvrtka</mat-radio-button>
              <mat-radio-button class="radio-button" [value]="2">Istraživač</mat-radio-button>
              <mat-radio-button class="radio-button" [value]="3">Javna organizacija</mat-radio-button>
            </mat-radio-group>

            <app-loader *ngIf="loadingData"></app-loader>
            <div *ngIf="!loadingData">
              <div class="row">
                <div class="container-fluid">
                  <app-create-company *ngIf="companyCreation" [formTitle]='formTitles.company'
                    [submitButtonTitle]='submitButtonTitles.company' [successMessage]='successMessage'
                    [errorMessage]='errorMessage'>
                  </app-create-company>
                  <app-create-scientist *ngIf="scientistCreation" [formTitle]='formTitles.scientist'
                    [submitButtonTitle]='submitButtonTitles.scientist' [successMessage]='successMessage'
                    [errorMessage]='errorMessage'></app-create-scientist>
                  <app-create-public-organization *ngIf="publicOrganizationCreation"
                    [formTitle]='formTitles.publicOrganization'
                    [submitButtonTitle]='submitButtonTitles.publicOrganization' [successMessage]='successMessage'
                    [errorMessage]='errorMessage'></app-create-public-organization>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>