<app-loader *ngIf="loadingData"></app-loader>

<mat-card *ngIf="!loadingData">
  <mat-card-title>Novi razvojni prijedlog</mat-card-title>
  <mat-card-content>

    <p class="news-text-time warning-text">
      <strong class="mr-2">Napomena: </strong>Novi razvojni prijedlog bit će vidljiv nakon što ga pregleda i odobri
      administrator RIMAP-a. Hvala na razumijevanju!
  </p>

    <form
      [formGroup]="createForm"
      autocomplete="off"
      (submit)="onSubmit()"
    >
      <mat-form-field class="full-width" appearance="outline">
        <mat-label>Naslov</mat-label>
        <input matInput formControlName="title" />
        <mat-error>Naslov razvojnog prijedloga je obavezan.</mat-error>
     </mat-form-field>
     <mat-form-field class="full-width" appearance="outline">
        <mat-label>Opis</mat-label>
        <textarea rows="10" cols="40" matInput formControlName="description" maxLength="2000"></textarea>
        <mat-error>Opis razvojnog prijedloga je obavezan.</mat-error>
     </mat-form-field>
     <mat-form-field class="full-width mt-4" appearance="outline">
      <mat-label>Mogućnosti doprinosa akademske zajednice</mat-label>
      <textarea rows="10" cols="40" matInput formControlName="academicCommunityContributionPossibility" maxLength="2000"></textarea>
      <mat-error>Mogućnosti doprinosa akademske zajednice su obavezan podatak.</mat-error>
   </mat-form-field>

      <app-category-picker [categoriesEnabled]="true" [parentForm]="createForm" [newsLettersEnabled]="false"></app-category-picker>

      <p align="end">
        <button
          type="submit"
          mat-raised-button
          color="primary"
          [disabled]="createForm.invalid"
        >
          Spremi novi razvojni prijedlog
        </button>
      </p>
    </form>
  </mat-card-content>
</mat-card>