import { NotificationMessages } from './../../shared/constants/notification-messages';
import { NotificationService } from './../../services/notification.service';
import { NewsletterService } from './../../services/newsletter.service';
import { Component, OnInit } from '@angular/core';
import {
  FormControl,
  FormBuilder,  
  FormGroup,
  Validators,
} from '@angular/forms';
import { emailValidator } from 'src/app/shared/custom-validators/email-validator';
import { trigger, transition, useAnimation } from '@angular/animations';
import { FadeAnimation } from 'src/app/shared/animations/fade-animation';

@Component({ 
  selector: 'app-new-subscriber',
  templateUrl: './new-subscriber.component.html',
  styleUrls: ['./new-subscriber.component.scss'],
  animations: [
    trigger('fadeAnimation', [
      transition('void => *', [useAnimation(FadeAnimation)]),
    ]),
  ],
})
export class NewSubscriberComponent implements OnInit {
  loadingData: boolean;
  createForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private notificationService: NotificationService,
    private newsletterService: NewsletterService
  ) {}

  ngOnInit() {
    this.setCreateForm(); 
  }

  setCreateForm(): void {
    this.createForm = this.fb.group({
      email: new FormControl('', emailValidator),
      categories: new FormControl([], Validators.required),
    });

    this.loadingData = false;
  }

  onSubmit(): void {

    this.prepareCategoriesBeforeSubmit();

    this.newsletterService.subscribe(this.createForm.value).subscribe(() => {
      this.notificationService.successMessage(NotificationMessages.SUBSCRIBE_SUCCESS);
    },
      (error) => {
        this.notificationService.errorMessage(NotificationMessages.SUBSCRIBE_ERROR);
      });
  }

  prepareCategoriesBeforeSubmit(): void {
    const realnewsCategories = this.createForm
      .get('categories')
      .value.map((x) => x.id);
    this.createForm.get('categories').setValue(realnewsCategories);
  }
}
