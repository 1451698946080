import { NotificationService } from 'src/app/services/notification.service';
import { EquipmentService } from './../../../services/equipment.service';
import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-equipment',
  templateUrl: './equipment.component.html',
  styleUrls: ['./equipment.component.css']
})
export class EquipmentComponent implements OnInit {

  @Input() scientistId: string;

  constructor() { }

  ngOnInit() {

  }
}
