<h1 mat-dialog-title>Potvrda brisanja unosa</h1>

<div mat-dialog-content>
  <p>Molimo da potvrdite brisanje zapisa.</p>
    <p>
        <b>Upozorenje:</b> 
        Nakon što je unos obrisan<br>
        on više neće biti vidljiv u aplikaciji.
    </p>
</div>
<div mat-dialog-actions align="end">
  <button mat-raised-button color="warn" (click)="onNoClick()">Odustani</button>&nbsp;
  <button mat-raised-button color="primary" [mat-dialog-close]="true" cdkFocusInitial>Potvrdi brisanje unosa</button>
</div>