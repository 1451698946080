<div class="container">
  <app-edit-company 
  [id]="entityId" 
  *ngIf="companyProfile" [formTitle]="myProfileTitle"></app-edit-company>
  
  <app-edit-public-organization
    [id]="entityId"
    *ngIf="publicOrganizationProfile" [formTitle]="myProfileTitle"></app-edit-public-organization>
  
  <app-edit-scientist
    [id]="entityId"
    *ngIf="scientistProfile" [formTitle]="myProfileTitle"></app-edit-scientist>
</div>
