<mat-card>
    <mat-card-title>
        Unos nove kategorije
    </mat-card-title>
  <mat-card-content>
    <form
      [formGroup]="createForm"
      autocomplete="off"
      (submit)="onSubmit()"
    >
      <p>
        <mat-form-field class="full-width-input" appearance="outline">
          <mat-label>Ime kategorije</mat-label>
          <input matInput formControlName="name" />
          <mat-error> Naziv kategorije je obavezan podatak. </mat-error>
        </mat-form-field>
      </p>
      <p align="end">
        <button
          type="submit"
          mat-raised-button
          color="primary"
          [disabled]="createForm.invalid"
        >
          Spremi novu kategoriju
        </button>
      </p>
    </form>
  </mat-card-content>
</mat-card>
