import { websiteValidator } from './../../../../shared/custom-validators/website-validator';
import { NotificationMessages } from 'src/app/shared/constants/notification-messages';
import { emailValidator } from './../../../../shared/custom-validators/email-validator';
import { NotificationService } from './../../../../services/notification.service';
import { AuthService } from './../../../../services/auth.service';
import { ScientistService } from './../../../../services/scientist.service';
import { Component, Input, OnInit } from '@angular/core';
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from '@angular/forms';
import { Router } from '@angular/router';
import { passwordValidator } from 'src/app/shared/custom-validators/password-validator';
import { EntityType } from 'src/app/shared/enums/entity-type';

@Component({
  selector: 'app-create-scientist',
  templateUrl: './create-scientist.component.html',
  styleUrls: ['./create-scientist.component.css'],
})
export class CreateScientistComponent implements OnInit {
  @Input() formTitle: string;
  @Input() submitButtonTitle: string;
  @Input() successMessage: string;
  @Input() errorMessage: string;

  loadingData: boolean;
  createForm: FormGroup;
  usernameCheckLoading: boolean;
  uniqueUsername: boolean;

  tagPickerTitle = 'Ključne riječi koje opisuju Vaš istraživački interes';
  categoryPickerTitle = 'Područja primjene Vaših istraživačkih usluga i kompetencija';

  constructor(
    private scientistService: ScientistService,
    private notificationService: NotificationService,
    private router: Router,
    private fb: FormBuilder,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.initFormFlags();
    this.setCreateForm();
  }

  public get entityType(): typeof EntityType {
    return EntityType;
  }

  initFormFlags(): void {
    this.uniqueUsername = false;
    this.usernameCheckLoading = false;
    this.loadingData = true;
  }

  setCreateForm(): void {
    this.createForm = this.fb.group({
      username: new FormControl('', emailValidator),
      title: new FormControl('', Validators.required),
      firstname: new FormControl('', Validators.required),
      lastname: new FormControl('', Validators.required),
      description : new FormControl(''),
      website: new FormControl('', websiteValidator),
      employmentCollege: new FormControl(''),
      functions: new FormControl(''),
      googleScholarLink: new FormControl(''),
      categoryTags: new FormControl(''),
      categories: new FormControl([], Validators.required),
      newsletterCategories: new FormControl([], Validators.required),
      password: new FormControl('', passwordValidator),
      confirmPassword: new FormControl('', Validators.required)
    },
    {
      validator: this.passwordMatchValidor
    });

    this.loadingData = false;
  }

  passwordMatchValidor(formGroup: FormGroup) {
    const passwordConfirmed =
      formGroup.get('password').value === formGroup.get('confirmPassword').value
        ? null
        : { mismatch: true };

    if (passwordConfirmed !== null) {
      formGroup.get('confirmPassword').setErrors({ mismatch: true });
    }

    return passwordConfirmed;
  }

  triggerUsernameCheck(): void {
    if (this.createForm.get('username').valid) {
      this.checkUsername();
    }

    return;
  }

  checkUsername(): void {
    this.usernameCheckLoading = true;

    const username = this.createForm.get('username').value;

    this.scientistService.checkScientistUsername(username).subscribe(
      (response: any) => {
        this.uniqueUsername = true;
      },
      (error) => {
        this.uniqueUsername = false;
        this.createForm.get('username').setErrors({ unique: true });
        this.notificationService.errorMessage(
          NotificationMessages.USERNAME_NOT_AVAILABLE
        );
      }
    );

    this.usernameCheckLoading = false;
  }

  onSubmit(): void {
    this.prepareCategoriesBeforeSubmit();
    this.scientistService.createScientist(this.createForm.value).subscribe(
      (response: any) => {
        this.router.navigateByUrl('');
        this.notificationService.successMessage(
          NotificationMessages.SUCCESSFULLY_REGISTERED_SCIENTIST
        );
      },
      (error) => {
        this.notificationService.errorMessage(this.errorMessage);
      }
    );
  }

  prepareCategoriesBeforeSubmit(): void {
    const realCategories = this.createForm
      .get('categories')
      .value.map((x) => x.id);
    const realnewsCategories = this.createForm
      .get('newsletterCategories')
      .value.map((x) => x.id);
    this.createForm.get('categories').setValue(realCategories);
    this.createForm.get('newsletterCategories').setValue(realnewsCategories);
  }
}
