<mat-sidenav-container class="sidenav-container">
  <mat-sidenav
    #drawer
    class="sidenav"
    fixedInViewport
    [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
    [mode]="(isHandset$ | async) ? 'over' : 'side'"
    [opened]="(isHandset$ | async) === false">
    <mat-toolbar>
      <a href="#" target="_blank" class="logo"> 
        <img class="img-fluid" src="assets/images/logo-dark.png" />
      </a>
    </mat-toolbar>
    <mat-nav-list>
      <a mat-list-item routerLink="" target="_blank">
        <i class="material-icons">home</i>
        <span class="navigation-item-text">Početna stranica</span>
      </a>
      <a mat-list-item routerLink="activities" routerLinkActive="selected">
        <i class="material-icons">info</i>
        <span class="navigation-item-text">Aktivnosti na platformi</span>
      </a>
      <a mat-list-item routerLink="news" routerLinkActive="selected">
        <i class="material-icons">dynamic_feed</i>
        <span class="navigation-item-text">Objave</span>
      </a>
      <a mat-list-item routerLink="categories" routerLinkActive="selected">
        <i class="material-icons">bookmark</i>
        <span class="navigation-item-text">Kategorije</span>
      </a>
      <a mat-list-item routerLink="companies" routerLinkActive="selected">
        <i class="material-icons">business</i>
        <span class="navigation-item-text">Tvrtke</span>
      </a>
      <a mat-list-item routerLink="scientists" routerLinkActive="selected">
        <i class="material-icons">biotech</i>
        <span class="navigation-item-text">Istraživači</span>
      </a>
      <a mat-list-item routerLink="users" routerLinkActive="selected">
        <i class="material-icons">group</i>
        <span class="navigation-item-text">Korisnici</span>
      </a>
      <a
        mat-list-item
        routerLink="public-organizations"
        routerLinkActive="selected">
        <i class="material-icons">group</i>
        <span class="navigation-item-text">Javne organizacije</span>
      </a>
      <a mat-list-item (click)="logOut()">
        <i class="material-icons">directions_run</i>
        <span class="navigation-item-text">Odjava</span>
      </a>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar color="primary">
      <button
        type="button"
        aria-label="Toggle sidenav"
        mat-icon-button
        (click)="drawer.toggle()"
        *ngIf="isHandset$ | async">
        <mat-icon color="primary" aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
      <span class="navigation-item-text">Administracija</span>
    </mat-toolbar>
    <div class="page-container">
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
