import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-visibility-dialog',
  templateUrl: './visibility-dialog.component.html',
  styleUrls: ['./visibility-dialog.component.css']
})
export class VisibilityDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<VisibilityDialogComponent>) { }

  ngOnInit() {
  }

  onNoClick() {
    this.dialogRef.close();
  }

}
