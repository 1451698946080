<div class="container">

    <app-loader *ngIf="loadingData"></app-loader>

    <!--latest news-->
    <section class="novosti">
        <h2 class="start-page-title page-header-title">Novosti i korisne informacije</h2>
        <div class="container">
            <div class="row newsletter-container">
                <div class="col-xs-12" *ngFor="let singleNews of latestNews">
                    <div class="image-flip">
                        <div class="mainflip flip-0">
                            <div class="frontside">
                                <div class="card">
                                    <div class="card-body text-left">
                                        <p>
                                            <a (click)="openNews(singleNews.id)">
                                                <img class=" img-fluid entity-profile-picture"
                                                    [src]="singleNews.featuredImage || 'assets/logo/image-default.png'"
                                                    alt="card image">
                                            </a>
                                        </p>
                                        <h3 class="card-title">
                                            <a (click)="openNews(singleNews.id)">
                                                {{singleNews.title}}
                                            </a>
                                        </h3>
                                        <div class="card-text">
                                            <p class="news-text-time">
                                                Objavljeno: {{singleNews.createdAt | date: 'dd.MM.yyyy. HH:mm' }}
                                            </p>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div class="backside ">
                                <div class="card ">
                                    <div class="card-body text-left  ">
                                        <button color="primary" (click)="openNews(singleNews.id)" mat-raised-button>
                                            Pročitaj više
                                        </button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-10 col-lg-8 col-xl-7 mx-auto text-center">
                <button mat-raised-button color="primary" type="button" class="homepage-buttons" (click)="goToNews()">
                    Pogledaj sve novosti
                </button>
            </div>
        </div>
    </section>

    <!--latest research problems - company -->
    <section class="p-2 razvojni">
        <div class="container">
            <h2 class="start-page-title">Novi razvojni prijedlozi tvrtki</h2>
            <div class="row">
                <div class="col-xs-12" [hidden]="loadingData" [@fadeAnimation]
                    *ngFor="let researchProblem of latestCompaniesResearchProblems">
                    <div class="image-flip">
                        <div class="mainflip flip-0">
                            <div class="frontside">
                                <div class="card">
                                    <div class="card-body text-left">
                                        <p>
                                            <a (click)="openCompany(researchProblem.companyId)">
                                                <img class="img-fluid entity-profile-picture"
                                                    [src]="researchProblem.image || 'assets/logo/image-default.png'"
                                                    alt="card image">
                                            </a>
                                        </p>
                                        <h3 class="card-title">
                                            <a (click)="openCompany(researchProblem.companyId)">
                                                {{researchProblem.title}}
                                            </a>
                                        </h3>
                                    </div>
                                </div>
                            </div>
                            <div class="backside ">
                                <div class="card ">
                                    <div class="card-body text-left  ">
                                        <button color="primary" (click)="openCompany(researchProblem.companyId)"
                                            mat-raised-button>
                                            Detalji i kontakt tvrtke
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div class="col-md-10 col-lg-8 col-xl-7 mx-auto text-center">
                <button mat-raised-button color="primary" class="homepage-buttons"
                    (click)="openCompanyResearchProblems()">
                    Pretraži razvojne prijedloge
                </button>
            </div>
        </div>
    </section>

    <!--latest research problems - public organization-->
    <section class="p-2  razvojni">
        <div class="container">
            <h2 class="start-page-title">Novi razvojni prijedlozi javnog sektora</h2>
            <div class="row">
                <div class="col-xs-12" [hidden]="loadingData" [@fadeAnimation]
                    *ngFor="let researchProblem of latestPublicOrganizationsResearchProblems">
                    <div class="image-flip">
                        <div class="mainflip flip-0">
                            <div class="frontside">
                                <div class="card">
                                    <div class="card-body text-left">
                                        <p>
                                            <a (click)="openPublicOrganization(researchProblem.publicOrganizationId)">
                                                <img class="img-fluid entity-profile-picture"
                                                    [src]="researchProblem.image || 'assets/logo/image-default.png'"
                                                    alt="card image">
                                            </a>
                                        </p>
                                        <h3 class="card-title">
                                            <a (click)="openPublicOrganization(researchProblem.publicOrganizationId)">
                                                {{researchProblem.title}}
                                            </a>
                                        </h3>
                                    </div>
                                </div>
                            </div>
                            <div class="backside ">
                                <div class="card ">
                                    <div class="card-body text-left  ">
                                        <button color="primary"
                                            (click)="openPublicOrganization(researchProblem.publicOrganizationId)"
                                            mat-raised-button>
                                            Detalji i kontakt organizacije
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-10 col-lg-8 col-xl-7 mx-auto text-center">
                <button mat-raised-button color="primary" class="homepage-buttons" type="button"
                    (click)="openPublicOrganizationResearchProblems()">
                    Pretraži razvojne prijedloge
                </button>
            </div>
        </div>
    </section>

    <!--latest companies-->
    <section class="p-2 tvrtke">
        <div class="container">
            <h2 class="start-page-title">Nove registrirane tvrtke</h2>
            <div class="row">
                <div class="col-xs-12" [hidden]="loadingData" [@fadeAnimation] *ngFor="let company of latestCompanies">
                    <div class="image-flip">
                        <div class="mainflip flip-0">
                            <div class="frontside">
                                <div class="card">
                                    <div class="card-body text-left">
                                        <p>
                                            <a (click)="openCompany(company.id)">
                                                <img class=" img-fluid entity-profile-picture"
                                                    [src]="company.logo || 'assets/logo/image-default.png'"
                                                    alt="card image">
                                            </a>
                                        </p>
                                        <h3 class="card-title">
                                            <a (click)="openCompany(company.id)">
                                                {{company.name}}
                                            </a>
                                        </h3>
                                        <div class="card-text">
                                            <p class="news-text-time" *ngIf="company.categoryTags?.length > 0">
                                                <span>
                                                    <mat-chip-list>
                                                        <strong>Djelatnosti:</strong>
                                                        <mat-chip
                                                            *ngFor="let tag of company.categoryTags?.split(';').slice(0, -1) | slice:0:3">
                                                            {{tag}}
                                                        </mat-chip>
                                                    </mat-chip-list>
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="backside">
                                <div class="card">
                                    <div class="card-body text-left ">
                                        <div class="card-text">
                                            <p class="adresa" *ngIf="company.adress !== ''">
                                                <strong>Adresa: </strong>
                                                {{company.address}}
                                            </p>
                                            <p class="web" *ngIf="company.website !== ''">
                                                <strong>Web stranica: </strong>
                                                <a [href]="company.website">{{company.website}}</a>
                                            </p>
                                            <div class="card-text">
                                                <p class="razvojni" *ngIf="company.researchProblems?.length > 0">
                                                    <strong>Razvojni prijedlozi: </strong>
                                                    <span>{{company.researchProblems?.length}}</span>
                                                </p>
                                                <p class="resursi" *ngIf="company.jobOffers?.length > 0">
                                                    <strong>Potrebe za ljudskim resursima: </strong>
                                                    <span>{{company.jobOffers?.length}}</span>
                                                </p>
                                            </div>
                                        </div>
                                        <button color="primary" (click)="openCompany(company.id)" mat-raised-button>
                                            Više detalja
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div class="col-md-10 col-lg-8 col-xl-7 mx-auto text-center">
                <button mat-raised-button color="primary" class="homepage-buttons" type="button"
                    (click)="openCompanies()">
                    Pretraži registrirane tvrtke
                </button>
            </div>
        </div>
    </section>

    <!--latest scientists-->
    <section class="p-2 istrazivaci">
        <div class="container">
            <h2 class="start-page-title">Novi registrirani istraživači</h2>

            <div class="row">
                <div class="col-xs-12" [hidden]="loadingData" [@fadeAnimation]
                    *ngFor="let scientist of latestScientists">

                    <div class="image-flip">
                        <div class="mainflip flip-0">
                            <div class="frontside">
                                <div class="card">
                                    <div class="card-body text-left">
                                        <p>
                                            <a (click)="openScientist(scientist.id)">
                                                <img class=" img-fluid entity-profile-picture"
                                                    [src]="scientist.profilePicture || 'assets/logo/image-default.png'"
                                                    alt="card image">
                                            </a>
                                        </p>
                                        <h3 class="card-title">
                                            <a (click)="openScientist(scientist.id)">
                                                {{scientist.firstname}} {{scientist.lastname}}
                                            </a>
                                        </h3>
                                        <div class="card-text">
                                            <p class="news-text-time" *ngIf="scientist.categoryTags?.length > 0">
                                                <span>
                                                    <mat-chip-list>
                                                        <mat-chip
                                                            *ngFor="let tag of scientist.categoryTags?.split(';').slice(0, -1) | slice:0:3">
                                                            {{tag}}
                                                        </mat-chip>
                                                    </mat-chip-list>
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="backside">
                                <div class="card">
                                    <div class="card-body text-left ">
                                        <div class="card-text">
                                            <p class="adresa" *ngIf="scientist.employmentCollege !== ''">
                                                <strong>Sastavnica sveučilišta: </strong>
                                                {{scientist.employmentCollege}}
                                            </p>
                                            <p class="web" *ngIf="scientist.website !== ''">
                                                <strong>Web stranica: </strong>
                                                <a target="_blank"
                                                    [href]="scientist.website">{{scientist.website}}</a>
                                            </p>
                                            <p class="radovi" *ngIf="scientist.googleScholarLink !== ''">
                                                <strong>Znanstveni radovi: </strong>
                                                <a target="_blank"
                                                    [href]="scientist.googleScholarLink">{{scientist.googleScholarLink}}</a>
                                            </p>
                                        </div>
                                        <button color="primary" (click)="openScientist(scientist.id)" mat-raised-button>
                                            Više detalja
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div class="col-md-10 col-lg-8 col-xl-7 mx-auto text-center">
                <button mat-raised-button color="primary" type="button" class="homepage-buttons"
                    (click)="openScientists()">
                    Pretraži registrirane istraživače
                </button>
            </div>
        </div>
    </section>


    <!--latest public organizations-->
    <section class="p-2  organizacije">
        <div class="container">
            <h2 class="start-page-title">Nove registrirane javne organizacije</h2>
            <div class="row">
                <div class="col-xs-12" [hidden]="loadingData" [@fadeAnimation]
                    *ngFor="let publicOrganization of latestPublicOrganizations">
                    <div class="image-flip">
                        <div class="mainflip flip-0">
                            <div class="frontside">
                                <div class="card">
                                    <div class="card-body text-left">
                                        <p>
                                            <a (click)="openPublicOrganization(publicOrganization.id)">
                                                <img class=" img-fluid entity-profile-picture"
                                                    [src]="publicOrganization.logo || 'assets/logo/image-default.png'"
                                                    alt="card image">
                                            </a>
                                        </p>
                                        <h3 class="card-title">
                                            <a (click)="openPublicOrganization(publicOrganization.id)">
                                                {{publicOrganization.name}}
                                            </a>
                                        </h3>
                                        <div class="card-text">
                                            <p class="news-text-time" *ngIf="publicOrganization.categoryTags?.length > 0">
                                                <span>
                                                    <mat-chip-list>
                                                        <mat-chip
                                                            *ngFor="let tag of publicOrganization.categoryTags?.split(';').slice(0, -1) | slice:0:3">
                                                            {{tag}}
                                                        </mat-chip>
                                                    </mat-chip-list>
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="backside">
                                <div class="card">
                                    <div class="card-body text-left ">
                                        <div class="card-text">
                                            <p class="adresa" *ngIf="publicOrganization.address !== ''">
                                                <strong>Adresa:</strong>
                                                {{publicOrganization.address}}
                                            </p>
                                            <p class="web" *ngIf="publicOrganization.website !== ''">
                                                <strong>Web stranica: </strong>
                                                <a [href]="publicOrganization.website">{{publicOrganization.website}}</a>
                                            </p>
                                            <div class="card-text">
                                                <p class="razvojni" *ngIf="publicOrganization.researchProblems?.length > 0">
                                                    <strong>Razvojni prijedlozi: </strong>
                                                    <span>{{publicOrganization.researchProblems?.length}}</span>
                                                </p>
                                            </div>
                                        </div>
                                        <button color="primary" (click)="openPublicOrganization(publicOrganization.id)"
                                            mat-raised-button>
                                            Više detalja
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div class="col-md-10 col-lg-8 col-xl-7 mx-auto text-center">
                <button mat-raised-button color="primary" type="button" class="homepage-buttons"
                    (click)="openPublicOrganizations()">
                    Pretraži javne organizacije
                </button>
            </div>
        </div>
    </section>

    <!--latest equipment-->
    <section class="p-2  oprema">
        <div class="container">
            <h2 class="start-page-title">Nova istraživačka oprema</h2>
            <div class="row">
                <div class="col-xs-12" [hidden]="loadingData" [@fadeAnimation]
                    *ngFor="let singleEquipment of latestEquipment">

                    <div class="image-flip">
                        <div class="mainflip flip-0">
                            <div class="frontside">
                                <div class="card">
                                    <div class="card-body text-left">
                                        <p>
                                            <a (click)="openScientist(singleEquipment.scientistId)">
                                                <img class=" img-fluid entity-profile-picture"
                                                    [src]="singleEquipment.image || 'assets/logo/image-default.png'"
                                                    alt="card image">
                                            </a>
                                        </p>
                                        <h3 class="card-title">
                                            <a (click)="openScientist(singleEquipment.scientistId)">
                                                {{singleEquipment.title}}
                                            </a>
                                        </h3>
                                    </div>
                                </div>
                            </div>
                            <div class="backside">
                                <div class="card">
                                    <div class="card-body text-left ">
                                        <div class="card-text">
                                            <p>
                                                <strong>Kratki opis:</strong>
                                                {{singleEquipment.description?.substring(0, 100) + '...'}}
                                            </p>
                                        </div>
                                        <button color="primary" (click)="openScientist(singleEquipment.scientistId)"
                                            mat-raised-button>
                                            Detalji i kontakt istraživača
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div class="col-md-10 col-lg-8 col-xl-7 mx-auto text-center">
                <button mat-raised-button color="primary" class="homepage-buttons" type="button"
                    (click)="openEquipment()">
                    Pretraži registriranu opremu
                </button>
            </div>
        </div>
    </section>


    <!--latest job offers -->
    <section class="p-2  resursi">
        <div class="container">
            <app-loader *ngIf="loadingData"></app-loader>
            <h2 class="start-page-title" class="start-page-title" class="start-page-title">Nove potrebe za ljudskim
                resursima</h2>
            <div class="row">
                <div [hidden]="loadingData" [@fadeAnimation] *ngFor="let jobOffer of latestJobOffers" class="col-xs-12">

                    <div class="image-flip">
                        <div class="mainflip flip-0">
                            <div class="frontside">
                                <div class="card">
                                    <div class="card-body text-left">
                                        <p>
                                            <a (click)="openCompany(jobOffer.companyId)">
                                                <img class=" img-fluid entity-profile-picture"
                                                    [src]="jobOffer.image || 'assets/logo/image-default.png'"
                                                    alt="card image">
                                            </a>
                                        </p>
                                        <h3 class="card-title">
                                            <a (click)="openCompany(jobOffer.companyId)">{{jobOffer.name}}</a>
                                        </h3>
                                        <div class="card-text">
                                            <p class="rok-prijave">
                                                {{jobOffer.deadline | date: 'dd.MM.yyyy'}}
                                            </p>
                                            <p class="adresa">
                                                {{jobOffer.location}}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="backside">
                                <div class="card">
                                    <div class="card-body text-left ">
                                        <button color="primary" (click)="openCompany(jobOffer.companyId)"
                                            mat-raised-button>
                                            Detalji i kontakt tvrtke
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div class="col-md-10 col-lg-8 col-xl-7 mx-auto text-center">
                <button mat-raised-button color="primary" class="homepage-buttons" type="button"
                    (click)="openJobOffers()">
                    Pretraži potrebe za ljudskim resursima
                </button>
            </div>
        </div>
    </section>
</div>