<header class="masthead header-news">
    <div class="container-fluid h-100">
        <div class="row h-100 align-items-center">
            <div class="container text-center">
                <h1 *ngIf="!loadingData" class="page-header-title">{{news.title}}</h1>
            </div>
        </div>
    </div>
</header>

<div class="container">
    <div class="row">
        <div [@fadeAnimation] class="col-xs-12 offset-md-2 col-md-8 offset-lg-2  col-lg-8">
            <div>
                <div class="mainflip flip-0">
                    <div class="frontside">
                        <div class="card">
                            <app-loader *ngIf="loadingData"></app-loader>
                            <div *ngIf="!loadingData" class="card-body">
                                <p class="text-center">
                                    <img class=" img-fluid entity-profile-picture"
                                        [src]="news.featuredImage || 'assets/logo/image-default.png'" 
                                        alt="card image">
                                </p>
                                
                                <div class="card-text">
                                    <p class="news-text-time">
                                        Objavljeno: {{news.createdAt | date: 'dd.MM.yyyy. HH:mm' }}
                                    </p>
                                </div>
                                <hr/>
                                <div class="card-text">
                                    <p [innerHTML]="news.content | safeHtml"></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>