import { VisibilityDialogComponent } from './../../../../components/visibility-dialog/visibility-dialog.component';
import { NotificationService } from './../../../../services/notification.service';
import { ScientistService } from './../../../../services/scientist.service';
import { MatDialog } from '@angular/material';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { PublicOrganization as Scientist } from 'src/app/shared/models/public-organization/public-organization';
import { NotificationMessages } from 'src/app/shared/constants/notification-messages';
import { ViewChild } from '@angular/core';
import { Component } from '@angular/core';
import { OnInit } from '@angular/core';

const COLUMNS: string[] = [
  'edit-buttons',
  'firstname',
  'lastname',
  'email',
  'numberOfViews',
  'delete-buttons',
];

@Component({
  selector: 'app-scientists-list',
  templateUrl: './scientists-list.component.html',
  styleUrls: ['./scientists-list.component.css'],
})

export class ScientistsListComponent implements OnInit {
  loadingData: boolean;
  displayedColumns: string[] = COLUMNS;
  scientists: Scientist[];
  dataSource: MatTableDataSource<Scientist[]>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private router: Router,
    private service: ScientistService,
    private notificationService: NotificationService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.loadingData = true;
    this.getScientists();
  }

  getScientists(): void {
    this.service.getScientists().subscribe((scientists: any) => {
      this.dataSource = new MatTableDataSource<Scientist[]>(scientists);
      this.loadingData = false;
      this.dataSource.paginator = this.paginator;
      this.initPaginator();
      this.dataSource.sort = this.sort;
    });
  }

  initPaginator() {
    this.paginator._intl.itemsPerPageLabel = 'Rezultata po stranici';
    this.paginator._intl.nextPageLabel = 'Slijedeća stranica';
        this.paginator._intl.previousPageLabel = 'Prethodna stranica';
    this.paginator._intl.getRangeLabel = (
      page: number,
      pageSize: number,
      length: number
    ) => {
      const start = page * pageSize + 1;
      const end = (page + 1) * pageSize;
      return `${start} - ${end} od ${this.paginator.length}`;
    };
  }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  goToEditPage(scientistId: string): void {
    this.router.navigateByUrl('/admin/scientists/edit/' + scientistId);
  }

  openVisibilityDialog(scientistId: string): void {
    const dialogRef = this.dialog.open(VisibilityDialogComponent);

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.loadingData = true;
        this.flipActiveScientist(scientistId);
        this.notificationService.successMessage(
          NotificationMessages.UPDATE_SUCCESS
        );
      } else {
        this.notificationService.errorMessage(
          NotificationMessages.UPDATE_ERROR
        );
      }
    });
  }

  flipActiveScientist(scientistId: string): void {
    this.service.flipScientistActive(scientistId).subscribe(
      (response: any) => {
        this.getScientists();
      },
      (error) => {
        this.notificationService.errorMessage(NotificationMessages.GET_ERROR);
      }
    );
  }
}
