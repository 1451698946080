<app-loader *ngIf="loadingData"></app-loader>

<mat-card *ngIf="!loadingData">
    <form [formGroup]="changePasswordForm" (submit)="onSubmit()">
        <mat-form-field appearance="outline">
            <mat-label>Nova lozinka</mat-label>
            <input type="password" matInput formControlName="password">
            <mat-error class="help-block" *ngIf="changePasswordForm.get('password').hasError('required')">Lozinka je obavezna.</mat-error>
            <mat-error>Lozinka nije ispravna. Lozinka mora imati minimalno 8 znakova.</mat-error>
         </mat-form-field>
         <mat-form-field appearance="outline">
            <mat-label>Potvrda nove lozinke</mat-label>
            <input matInput type="password" formControlName="confirmPassword">
            <mat-error>Potvrda lozinke nije ispravna.</mat-error>
         </mat-form-field>
         <div align="end">
             <button color="primary" type="submit" mat-raised-button [disabled]="changePasswordForm.invalid">Promjeni lozinku</button>
         </div>
    </form>
</mat-card>

