<header class="masthead header-search">
    <div class="container-fluid h-100">
        <div class="row h-100 align-items-center">
            <div class="container text-center">
                <h1 class="page-header-title">Pretraga registriranih subjekata</h1>
            </div>
        </div>
    </div>
</header>

<div class="container mt-3">
    <div class="d-flex justify-content-center">
        <mat-form-field appearance="outline">
            <input matInput type="text" autocomplete="off" (keyup)="applyFilter($event)" placeholder="Pretraga...">
        </mat-form-field>
    </div>
    <div class="d-flex justify-content-center">
        <div [formGroup]="searchForm">
            <app-category-search-picker [parentForm]="searchForm"></app-category-search-picker>
        </div>
    </div>
    <div class="row">
        <mat-tab-group mat-align-tabs="center" class="col-lg-12">
            <mat-tab label="Tvrtke">
                <section class="tvrtke pb-5">
                    <div class="container">
                        <app-loader *ngIf="loadingData"></app-loader>
                        <div class="row companies-row">
                            <div class="col-xs-12" [hidden]="loadingData" [@fadeAnimation]
                                *ngFor="let company of companies | async">
                                <div class="image-flip">
                                    <div class="mainflip flip-0">
                                        <div class="frontside">
                                            <div class="card">
                                                <div class="card-body text-left">
                                                    <p>
                                                        <a (click)="openCompany(company.id)">
                                                            <img class=" img-fluid entity-profile-picture"
                                                                [src]="company.logo || 'assets/logo/image-default.png'"
                                                                alt="card image">
                                                        </a>
                                                    </p>
                                                    <h3 class="card-title">
                                                        <a (click)="openCompany(company.id)">
                                                            {{company.name}}
                                                        </a>
                                                    </h3>
                                                    <div class="card-text">
                                                        <p class="news-text-time"
                                                            *ngIf="company.categoryTags?.length > 0">
                                                            <span>
                                                                <mat-chip-list>
                                                                    <strong>Djelatnosti:</strong>
                                                                    <mat-chip
                                                                        *ngFor="let tag of company.categoryTags.split(';').slice(0, -1) | slice:0:3">
                                                                        {{tag}}
                                                                    </mat-chip>
                                                                </mat-chip-list>
                                                            </span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="backside">
                                            <div class="card">
                                                <div class="card-body text-left ">
                                                    <div class="card-text">
                                                        <p class="adresa" *ngIf="company.adress !== ''">
                                                            <strong>Adresa: </strong>
                                                            {{company.address}}
                                                        </p>
                                                        <p class="web" *ngIf="company.website !== ''">
                                                            <strong>Web stranica: </strong>
                                                            <a [href]="company.website">{{company.website}}</a>
                                                        </p>
                                                        <div class="card-text">
                                                            <p class="razvojni"
                                                                *ngIf="company.researchProblems?.length > 0">
                                                                <strong>Razvojni prijedlozi: </strong>
                                                                <span>{{company.researchProblems.length}}</span>
                                                            </p>
                                                            <p class="resursi" *ngIf="company.jobOffers.length > 0">
                                                                <strong>Potrebe za ljudskim resursima: </strong>
                                                                <span>{{company.jobOffers.length}}</span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <button color="primary" (click)="openCompany(company.id)"
                                                        mat-raised-button>
                                                        Više detalja
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <mat-paginator #companiesPaginator [pageSizeOptions]="[6, 9, 12, 15]"></mat-paginator>
                    </div>
                </section>
            </mat-tab>
            <mat-tab label="Istraživači">
                <section class="tvrtke istrazivaci pb-5">
                    <div class="container">

                        <app-loader *ngIf="loadingData"></app-loader>

                        <div class="row companies-row">
                            <div [hidden]="loadingData" [@fadeAnimation] *ngFor="let scientist of scientists | async"
                                class="col-xs-12">

                                <div class="image-flip">
                                    <div class="mainflip flip-0">
                                        <div class="frontside">
                                            <div class="card">
                                                <div class="card-body text-left">
                                                    <p>
                                                        <a (click)="openScientist(scientist.id)">
                                                            <img class=" img-fluid entity-profile-picture"
                                                                [src]="scientist.profilePicture || 'assets/logo/image-default.png'"
                                                                alt="card image">
                                                        </a>
                                                    </p>
                                                    <h3 class="card-title">
                                                        <a (click)="openScientist(scientist.id)">
                                                            {{scientist.firstname}} {{scientist.lastname}}
                                                        </a>
                                                    </h3>
                                                    <div class="card-text">
                                                        <p class="news-text-time"
                                                            *ngIf="scientist.categoryTags?.length > 0">
                                                            <span>
                                                                <mat-chip-list>
                                                                    <mat-chip
                                                                        *ngFor="let tag of scientist.categoryTags.split(';').slice(0, -1) | slice:0:3">
                                                                        {{tag}}
                                                                    </mat-chip>
                                                                </mat-chip-list>
                                                            </span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="backside">
                                            <div class="card">
                                                <div class="card-body text-left ">
                                                    <div class="card-text">
                                                        <p class="adresa" *ngIf="scientist.employmentCollege !== ''">
                                                            <strong>Sastavnica sveučilišta: </strong>
                                                            {{scientist.employmentCollege}}
                                                        </p>
                                                        <p class="web" *ngIf="scientist.website !== ''">
                                                            <strong>Web stranica: </strong>
                                                            <a target="_blank"
                                                                [href]="scientist.website">{{scientist.website}}</a>
                                                        </p>
                                                        <p class="radovi" *ngIf="scientist.googleScholarLink !== ''">
                                                            <strong>Znanstveni radovi: </strong>
                                                            <a target="_blank"
                                                                [href]="scientist.googleScholarLink">{{scientist.googleScholarLink}}</a>
                                                        </p>
                                                    </div>
                                                    <button color="primary" (click)="openScientist(scientist.id)"
                                                        mat-raised-button>
                                                        Više detalja
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <mat-paginator #scientistsPaginator [pageSizeOptions]="[6, 9, 12, 15]"></mat-paginator>
                    </div>
                </section>
            </mat-tab>
            <mat-tab label="Javni sektor">
                <section class="tvrtke organizacije pb-5">
                    <app-loader *ngIf="loadingData"></app-loader>
                    <div class="container">
                        <div class="row companies-row">
                            <div [hidden]="loadingData" [@fadeAnimation]
                                *ngFor="let publicOrganization of publicOrganizations | async" class="col-xs-12">

                                <div class="image-flip">
                                    <div class="mainflip flip-0">
                                        <div class="frontside">
                                            <div class="card">
                                                <div class="card-body text-left">
                                                    <p>
                                                        <a (click)="openPublicOrganization(publicOrganization.id)">
                                                            <img class=" img-fluid entity-profile-picture"
                                                                [src]="publicOrganization.logo || 'assets/logo/image-default.png'"
                                                                alt="card image">
                                                        </a>
                                                    </p>
                                                    <h3 class="card-title">
                                                        <a (click)="openPublicOrganization(publicOrganization.id)">
                                                            {{publicOrganization.name}}
                                                        </a>
                                                    </h3>
                                                    <div class="card-text">
                                                        <p class="news-text-time"
                                                            *ngIf="publicOrganization.categoryTags?.length > 0">
                                                            <span>
                                                                <mat-chip-list>
                                                                    <mat-chip
                                                                        *ngFor="let tag of publicOrganization.categoryTags.split(';').slice(0, -1) | slice:0:3">
                                                                        {{tag}}
                                                                    </mat-chip>
                                                                </mat-chip-list>
                                                            </span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="backside">
                                            <div class="card">
                                                <div class="card-body text-left ">
                                                    <div class="card-text">
                                                        <p class="adresa" *ngIf="publicOrganization.address !== ''">
                                                            <strong>Adresa:</strong>
                                                            {{publicOrganization.address}}
                                                        </p>
                                                        <p class="web" *ngIf="publicOrganization.website !== ''">
                                                            <strong>Web stranica: </strong>
                                                            <a
                                                                [href]="publicOrganization.website">{{publicOrganization.website}}</a>
                                                        </p>
                                                        <div class="card-text">
                                                            <p class="razvojni"
                                                                *ngIf="publicOrganization.researchProblems?.length > 0">
                                                                <strong>Razvojni prijedlozi: </strong>
                                                                <span>{{publicOrganization.researchProblems.length}}</span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <button color="primary"
                                                        (click)="openPublicOrganization(publicOrganization.id)"
                                                        mat-raised-button>
                                                        Više detalja
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <mat-paginator #publicOrganizationsPaginator [pageSizeOptions]="[6, 9, 12, 15]"></mat-paginator>
                    </div>
                </section>
            </mat-tab>
        </mat-tab-group>
    </div>

</div>