import { News } from 'src/app/shared/models/news/news';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatTableDataSource } from '@angular/material';
import { Observable } from 'rxjs';
import { PublicService } from 'src/app/services/public.service';
import { trigger, transition, useAnimation } from '@angular/animations';
import { FadeAnimation } from 'src/app/shared/animations/fade-animation';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-active-news',
  templateUrl: './active-news.component.html',
  styleUrls: ['./active-news.component.css'],
  animations: [
    trigger('fadeAnimation', [
      transition('void => *', [useAnimation(FadeAnimation)]),
    ]),
  ],
})
export class ActiveNewsComponent implements OnInit {
  news: Observable<News[]>;
  newsDataSource: MatTableDataSource<News>;

  @ViewChild('newsPaginator') newsPaginator: MatPaginator;

  loadingData: boolean;

  constructor(
    private publicService: PublicService,
    private router: Router,
    private changeDetectorRef: ChangeDetectorRef,
    private titleService: Title
  ) {
    this.titleService.setTitle('Novosti | RIMAP');
  }

  ngOnInit(): void {
    this.loadingData = true;
    this.getNews();
  }

  initPaginator() {
    this.newsPaginator._intl.itemsPerPageLabel = 'Rezultata po stranici';
    this.newsPaginator._intl.nextPageLabel = 'Slijedeća stranica';
    this.newsPaginator._intl.previousPageLabel = 'Prethodna stranica';
    this.newsPaginator._intl.getRangeLabel = (
      page: number,
      pageSize: number,
      length: number
    ) => {
      const start = page * pageSize + 1;
      const end = (page + 1) * pageSize;
      return `${start} - ${end} od ${this.newsPaginator.length}`;
    };
  }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.newsDataSource.filter = filterValue.trim().toLowerCase();
  }

  openNews(newsId: string): void {
    this.router.navigateByUrl('/news/details/' + newsId);
  }

  getNews() {
    this.publicService.getNews().subscribe((response: News[]) => {
      this.newsDataSource = new MatTableDataSource<News>(response);
      this.news = this.newsDataSource.connect();
      this.loadingData = false;
      this.changeDetectorRef.detectChanges();
      this.newsDataSource.paginator = this.newsPaginator;
      this.initPaginator();
    });
  }
}
