  <div class="container">
    <div class="row">
        <div [@fadeAnimation] class="col-xs-12 offset-md-2 col-md-8 offset-lg-2  col-lg-8">
            <div class="mainflip flip-0">
                <div class="frontside">
                    <div class="card about-card text-center">
                        <div class="card-body text-center">
                            <div class="card-text text-center">  
                                <h4>Email uspješno potvrđen!</h4>
                            </div>  
                        </div> 
                        <img class="img img-fluid check-image" src="assets/icons/check.svg" alt="card image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> 