<header class="masthead header-companies">
    <div class="container-fluid h-100">
        <div class="row h-100 align-items-center">
            <div class="container text-center">
                <h1 *ngIf="!loadingData" class="page-header-title">{{company.name}}</h1>
            </div>
        </div>
    </div>
</header>

<div class="container">
    <div [@fadeAnimation] class="container">
        <div class="mainflip flip-0">
            <div class="frontside">
                <div class="card">
                    <app-loader *ngIf="loadingData"></app-loader>
                    <div *ngIf="!loadingData" class="card-body">
                        <p class="text-center">
                            <img class="img-fluid entity-profile-picture"
                                [src]="company.logo || 'assets/logo/image-default.png'" alt="card image">
                        </p>
                        <div class="row buttons-container mt-3 mb-3 text-center">
                            <button type="button" mat-raised-button color="primary"
                                (click)="openSendMessage()">Kontaktiraj tvrtku
                            </button>
                        </div>
                        <div class="card-text">
                            <p class="adresa" *ngIf="company.address !== ''">
                                <strong>Adresa:</strong>
                                {{company.address}}
                            </p>
                            <p class="resursi" *ngIf="company.numberOfEmployees !== null">
                                <strong>Broj zaposlenih:</strong>
                                {{company.numberOfEmployees}}
                            </p>
                            <p class="web" *ngIf="company.website !== ''">
                                <strong>Web stranica: </strong>
                                <a [href]="company.website" target="_blank">{{company.website}}</a>
                            </p>
                            <p *ngIf="company.categories.length > 0">
                                <mat-chip-list>
                                    <strong>Područja djelatnosti:</strong>
                                    <mat-chip *ngFor="let category of company.categories">
                                        {{category.name}}
                                    </mat-chip>
                                </mat-chip-list>
                            </p>
                            <p *ngIf="company.categoryTags.length > 0">
                                <mat-chip-list>
                                    <strong>Potpodručja djelatnosti:</strong>
                                    <mat-chip *ngFor="let tag of company.categoryTags.split(';').slice(0, -1)">
                                        {{tag}}
                                    </mat-chip>
                                </mat-chip-list>
                            </p>
                            <p *ngIf="company.description !== null">
                                <strong>Kratki opis tvrtke i djelatnosti: </strong>
                                {{company.description}}
                            </p>

                            <hr *ngIf="company.researchProblems.length !== 0">
                            <div class="mt-3">
                                <h2 *ngIf="company.researchProblems.length !== 0">
                                    <strong>Razvojni prijedlozi</strong>
                                </h2>
                                <div class="sub-entity-details-container mt-3"
                                    *ngFor="let researchProblem of company.researchProblems">
                                    <img [src]="researchProblem.image || 'assets/logo/image-default.png'"
                                        alt={{researchProblem.title}} />
                                    <h3 class="mt-3 mb-3 sub-entity-main-title">
                                        <strong>{{researchProblem.title}}</strong>
                                    </h3>
                                    <div class="card-text">
                                        <div [innerHTML]="researchProblem.description | safeHtml"></div>
                                        <p class="mt-4 mb-3"
                                            *ngIf="researchProblem.academicCommunityContributionPossibility !== null">
                                            <strong>Mogućnosti inovacijskog doprinosa: </strong>
                                            {{researchProblem.academicCommunityContributionPossibility}}
                                        </p>
                                    </div>
                                    <hr />
                                </div>
                            </div>
                            <!-- <hr *ngIf="company.jobOffers.length !== 0">
                                    <div class="mt-3">
                                        <h3 *ngIf="company.jobOffers.length !== 0">
                                            
                                            <strong>Potrebe za ljudskim
                                                resursima</strong>
                                        </h3>
                                        <div class="sub-entity-details-container mt-3"
                                            *ngFor="let jobOffer of company.jobOffers">
                                            <h4 class="text-center mt-3 mb-3 sub-entity-main-title">
                                                <strong>{{jobOffer.name}}</strong>
                                            </h4>
                                            <div class="card-text">
                                                <p>
                                                    <mat-icon class="news-clock-icon" matPrefix>query_builder</mat-icon>
                                                    Objavljeno: {{jobOffer.createdAt | date: 'dd.MM.yyyy. HH:mm' }}
                                                </p>
                                            </div>
                                            <hr />
                                            <p>
                                                <strong>Opis:</strong> {{jobOffer.description}}
                                            </p>
                                            <p>
                                                <strong>Razina edukacije:</strong> {{jobOffer.education}}
                                            </p>
                                            <p>
                                                <strong>Iskustvo:</strong> {{jobOffer.experience}}
                                            </p>
                                            <p>
                                                <strong>Rok prijave:</strong> {{jobOffer.deadline | date: 'dd.MM.yyyy'}}
                                            </p>
                                        </div>
                                    </div> -->
                        </div>
                        <hr>
                        <div class="row buttons-container mt-3 text-center">
                            <button type="button" mat-raised-button color="primary"
                                (click)="openSendMessage()">Kontaktiraj tvrtku
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>