import { Component, Input, OnInit } from '@angular/core';
import {
  FormGroup,
  FormBuilder, 
  FormControl,
  Validators,
} from '@angular/forms';
import { NotificationService } from 'src/app/services/notification.service';
import { NotificationMessages } from 'src/app/shared/constants/notification-messages';
import { MatDialogRef } from '@angular/material/dialog';
import { SpecificKnowledgeService } from 'src/app/services/specific-knowledge.service';
import { SpecificKnowledge } from 'src/app/shared/models/specific-knowledge/specific-knowledge';
import { EntityType } from 'src/app/shared/enums/entity-type';

@Component({
  selector: 'app-edit-specific-knowledge',
  templateUrl: './edit-specific-knowledge.component.html',
  styleUrls: ['./edit-specific-knowledge.component.css'],
})
export class EditSpecificKnowledgeComponent implements OnInit {
  @Input() scientistId: string;
  @Input() specificKnowledgeId: string;

  loadingData: boolean;
  editForm: FormGroup;
  specificKnowledge: SpecificKnowledge;

  public entityTypeEnum = EntityType;

  constructor(
    private specificKnowledgeService: SpecificKnowledgeService,
    private notificationService: NotificationService,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<EditSpecificKnowledgeComponent>
  ) {}

  ngOnInit() {
    this.loadingData = true;
    this.getSpecificKnowledge();
  }

  getSpecificKnowledge(): void {
    this.specificKnowledgeService
      .getSpecificKnowledge(this.scientistId, this.specificKnowledgeId)
      .subscribe(
        (response: SpecificKnowledge) => {
          this.specificKnowledge = response as SpecificKnowledge;
          this.setEditForm();
        },
        (error) => {
          this.notificationService.errorMessage(NotificationMessages.GET_ERROR);
        }
      );
  }

  setEditForm(): void {
    this.editForm = this.fb.group({
      description: new FormControl(
        this.specificKnowledge.description,
        Validators.required
      ),
      categories: new FormControl(
        this.specificKnowledge.categories,
        Validators.required
      ),
      title: new FormControl(this.specificKnowledge.title, Validators.required),
    });

    this.loadingData = false;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSubmit(): void {
    this.loadingData = true;

    this.prepareCategoriesBeforeSubmit();

    this.specificKnowledgeService
      .editSpecificKnowledge(
        this.scientistId,
        this.specificKnowledgeId,
        this.editForm.value
      )
      .subscribe(
        (response: any) => {
          this.notificationService.successMessage(
            NotificationMessages.UPDATE_SUCCESS
          );
          this.editForm.reset();
          this.specificKnowledgeService.pingSpecificKnowledges();
        },
        (error) => {
          this.notificationService.errorMessage(
            NotificationMessages.UPDATE_ERROR
          );
        }
      );

    this.loadingData = false;
  }

  prepareCategoriesBeforeSubmit(): void {
    const realCategories = this.editForm
      .get('categories')
      .value.map((x) => x.id);
    this.editForm.get('categories').setValue(realCategories);
  }
}
