<!-- Navigation -->
<nav class="navbar navbar-expand-lg navbar-dark bg-dark static-top">
    <div class="container">
        <a class="navbar-brand" href="#">RIMAP</a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarResponsive"
            aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>

        <div id="navbarResponsive" class="collapse navbar-collapse">
            <ul class="navbar-nav ml-auto">
                <li class="nav-item">
                    <a class="nav-link" [routerLink]="['/about']">
                        O nama
                    </a>
                </li>
                
                <li class="nav-item">
                    <a class="nav-link" [routerLink]="['/main-search']">
                        Pretraga
                    </a>
                </li>

                <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" href="#" id="companiesDropdown" role="button"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Tvrtke
                    </a>
                    <ul class="dropdown-menu" aria-labelledby="companiesDropdown">
                        <li>
                            <a class="dropdown-item" [routerLink]="['/companies']">Pretraga tvrtki</a>
                        </li>
                        <li>
                            <a class="dropdown-item" [routerLink]="['/companies/research-problems']">Razvojni prijedlozi
                                tvrtki</a>
                        </li>
                        <li>
                            <a class="dropdown-item" [routerLink]="['/companies/job-offers']">Potrebe za ljudskim
                                resursima</a>
                        </li>
                    </ul>
                </li>
                <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" href="#" id="scientistsDropdown" role="button"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Istraživači
                    </a>
                    <ul class="dropdown-menu" aria-labelledby="scientistsDropdown">
                        <li>
                            <a class="dropdown-item" [routerLink]="['/scientists']">Pretraga istraživača</a>
                        </li>
                        <li>
                            <a class="dropdown-item" [routerLink]="['/scientists/equipment']">Istraživačka oprema</a>
                        </li>
                        <li>
                            <a class="dropdown-item" [routerLink]="['/scientists/specific-knowledges']">Usluge i
                                kompetencije</a>
                        </li>

                    </ul>
                </li>

                <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" href="#" id="publicOrganizationsDropdown" role="button"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Javni sektor
                    </a>
                    <ul class="dropdown-menu" aria-labelledby="publicOrganizationsDropdown">
                        <li>
                            <a class="dropdown-item" [routerLink]="['/public-organizations']">Pretraga javnog sektora</a>
                        </li>
                        <li>
                            <a class="dropdown-item" [routerLink]="['/public-organizations/research-problems']">Razvojni
                                prijedlozi javnog sektora</a>
                        </li>
                        
                    </ul>
                </li>
                <li class="nav-item">
                    <a class="nav-link" [routerLink]="['/news']"> Novosti </a>
                </li>
                <li class="nav-item ng-star-inserted" *ngIf="!userLogged">
                    <a class="nav-link" [routerLink]="['/user-login']"> Prijava </a>
                </li>
                <li class="nav-item dropdown" *ngIf="userLogged">
                    <a class="nav-link dropdown-toggle" href="#" id="profileDropdown" role="button"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Moj profil
                    </a>
                    <div class="dropdown-menu" aria-labelledby="profileDropdown">
                        <a class="dropdown-item" [routerLink]="['/my-profile']">Uredi podatke</a>
                        <a class="dropdown-item" (click)="logOut()">Odjava</a>
                    </div>
                </li>

            </ul>
        </div>
    </div>
</nav>

<div class="container-fluid p-0">
    <router-outlet></router-outlet>
    <app-footer></app-footer>
</div>