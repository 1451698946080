<h1 mat-dialog-title>Nova potreba za ljudskim resursima</h1>
<app-loader *ngIf="loadingData"></app-loader>
<div mat-dialog-content *ngIf="!loadingData">

    <p class="news-text-time warning-text">
        Nova potreba za ljudskim resursima bit će vidljiva nakon
        što ju pregleda i odobri administrator RIMAP-a. Hvala na razumijevanju!
    </p>

    <form [formGroup]="createForm" autocomplete="off" (submit)="onSubmit()">
        <mat-form-field appearance="outline">
            <mat-label>Naziv</mat-label>
            <input matInput formControlName="name" />
            <mat-error>Naziv posla je obavezan.</mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>Opis</mat-label>
            <textarea rows="10" cols="40" matInput formControlName="description"></textarea>
            <mat-error>Opis posla je obavezan.</mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline">
            <mat-label>Lokacija</mat-label>
            <mat-select matInput formControlName="location">
                <mat-option *ngFor="let place of places" [value]="place.name">
                    {{place.name}}
                </mat-option>
            </mat-select>
            <mat-error>Odabir lokacije je obavezan.</mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline">
            <mat-label>Rok trajanja natječaja</mat-label>
            <input matInput [matDatepicker]="deadline" formControlName="deadline">
            <mat-datepicker-toggle matInput matSuffix [for]="deadline"></mat-datepicker-toggle>
            <mat-datepicker #deadline></mat-datepicker>
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>Iskustvo</mat-label>
            <input matInput formControlName="experience" />
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>Obrazovanje</mat-label>
            <input matInput formControlName="education" />
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>Posebni uvjeti</mat-label>
            <input matInput formControlName="specialConditions" />
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>Trajanje natječaja</mat-label>
            <input matInput formControlName="duration" />
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>Način prijave</mat-label>
            <input matInput formControlName="applyMethod" />
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>Dodatne napomene</mat-label>
            <textarea rows="10" cols="40" matInput formControlName="additional"></textarea>
        </mat-form-field>

        <app-category-picker [categoriesEnabled]="true" [parentForm]="createForm" [newsLettersEnabled]="false">
        </app-category-picker>

        <div mat-dialog-actions align="end">
            <button type="submit" mat-raised-button color="primary" [disabled]="createForm.invalid">
                Spremi novu potrebu za ljudskim resursima
            </button>
        </div>
    </form>
</div>