import { ActivatedRoute } from '@angular/router';

import { Component, Input, OnInit } from '@angular/core';
import { EntityType } from 'src/app/shared/enums/entity-type';
   
@Component({
  selector: 'app-research-problems',
  templateUrl: './research-problems.component.html',
  styleUrls: ['./research-problems.component.css']
}) 
export class ResearchProblemsComponent implements OnInit {

  @Input() entityType: EntityType;
  @Input() entityId: string;
  loadingData: boolean;

  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
  }
}
