import { MatDialogRef } from '@angular/material';
import { Component, Input, OnInit } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
} from '@angular/forms';
import { NotificationService } from 'src/app/services/notification.service';
import { ResearchProblemService } from 'src/app/services/research-problem.service';
import { NotificationMessages } from 'src/app/shared/constants/notification-messages';
import { EntityType } from 'src/app/shared/enums/entity-type';
import { ResearchProblem } from 'src/app/shared/models/research-problem/research-problem';
import * as Editor from 'src/app/shared/ckeditor/build/ckeditor';
import { UploadAdapter } from 'src/app/shared/helpers/upload-adapter';
import { EditorConfig } from 'src/app/shared/constants/editor-config';

@Component({
  selector: 'app-create-research-problem-dialog',
  templateUrl: './create-research-problem-dialog.component.html',
  styleUrls: ['./create-research-problem-dialog.component.css'],
})
export class CreateResearchProblemDialogComponent implements OnInit {
  @Input() entityType: EntityType;
  @Input() entityId: string;

  createForm: FormGroup;
  loadingData: boolean;

  public Editor = Editor;

  public get editorConfig(): typeof EditorConfig{
    return EditorConfig;
  }

  constructor(
    private fb: FormBuilder,
    private researchProblemService: ResearchProblemService,
    private dialogRef: MatDialogRef<CreateResearchProblemDialogComponent>,
    private notificationService: NotificationService
  ) {}

  ngOnInit() {
    this.loadingData = true;
    this.setCreateForm();
  }
  setCreateForm(): void {
    this.createForm = this.fb.group({
      title: new FormControl('', Validators.required),
      description: new FormControl('', Validators.required),
      categories: new FormControl([], Validators.required),
      academicCommunityContributionPossibility: new FormControl(
        '',
        Validators.required
      ),
    });

    this.loadingData = false;
  }

  onSubmit(): void {
    this.loadingData = true;

    this.prepareCategoriesBeforeSubmit();

    this.researchProblemService
      .createResearchProblem(
        this.createForm.value,
        this.entityType,
        this.entityId
      )
      .subscribe(
        (response: ResearchProblem) => {
          this.notificationService.successMessage(
            NotificationMessages.CREATE_SUCCESS
          );
          this.researchProblemService.pingNewResearchProblem();
          this.loadingData = false;
          this.dialogRef.close();
        },
        (error) => {
          this.notificationService.errorMessage(
            NotificationMessages.CREATE_ERROR
          );

          this.loadingData = false;
        }
      );
  }

  onReady(eventData) {
    eventData.plugins.get(
      'FileRepository'
    ).createUploadAdapter = function (loader: { file: string }) {
      return new UploadAdapter(loader);
    };
  }

  prepareCategoriesBeforeSubmit(): void {
    const realCategories = this.createForm
      .get('categories')
      .value.map((x) => x.id);
    this.createForm.get('categories').setValue(realCategories);
  }
}
