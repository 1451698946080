<div align="end">
    <mat-form-field appearance="outline">
        <mat-label>Pretraga pretplatnika</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="npr. test@gmail.com" #input />
    </mat-form-field> 
</div>

<app-loader *ngIf="loadingData"></app-loader>

<div class="mat-elevation-z8" [hidden]="loadingData">
    <table mat-table class="full-width" matSort [dataSource]="dataSource">

        <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
            <td mat-cell *matCellDef="let element">{{ element.email }}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="4">
                Nema podataka koji odgovaraju "{{ input.value }}"
            </td>
        </tr>
    </table>

    <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
</div>