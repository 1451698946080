import { trigger, transition, useAnimation } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { FadeAnimation } from 'src/app/shared/animations/fade-animation';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.css'],
  animations: [
    trigger('fadeAnimation', [
      transition('void => *', [useAnimation(FadeAnimation)]),
    ]),
  ],
})
export class NotFoundComponent implements OnInit {

  constructor(private titleService: Title) {
    this.titleService.setTitle('Stranica nije pronađena | RIMAP');
  }

  ngOnInit(): void {
  }

}
