import { AuthService } from './../../../../services/auth.service';
import { FileUploaderService } from './../../../../services/file-uploader.service';
import { websiteValidator } from './../../../../shared/custom-validators/website-validator';
import {
  passwordValidator,
} from 'src/app/shared/custom-validators/password-validator';
import { NotificationMessages } from './../../../../shared/constants/notification-messages';
import { NotificationService } from './../../../../services/notification.service';
import { Company } from './../../../../shared/models/company/company';
import { CompanyService } from './../../../../services/company.service';
import { Component, Input, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  Validators,
  FormGroup,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { taxCodeValidator } from 'src/app/shared/custom-validators/tax-code-validator';
import { EntityType } from 'src/app/shared/enums/entity-type';
 
@Component({ 
  selector: 'app-edit-company',
  templateUrl: './edit-company.component.html', 
  styleUrls: ['./edit-company.component.css'],
})
export class EditCompanyComponent implements OnInit {
  @Input() id: string; 
  @Input() formTitle: string;

  tagPickerTitle = 'Ključne riječi koje opisuju djelatnost Vaše tvrtke';
  categoryPickerTitle = 'Odaberite Vaše kategorije interesa';

  company: Company;
  companyId: string;
  editFormTitle: string;
  editForm: FormGroup;
  admin: boolean;

  loadingData: boolean;

  constructor(
    private companyService: CompanyService,
    private notificationService: NotificationService,
    private uploadService: FileUploaderService,
    private authService: AuthService,
    private fb: FormBuilder,
    private route: ActivatedRoute
  ) {
    this.listenToImageUpload();
    this.admin = this.authService.isSuperAdminLogged();
  }

  public get entityType(): typeof EntityType {
    return EntityType;
  }

  ngOnInit(): void {
    this.loadingData = true;
    this.setFormControls();
    this.getCompany();
  }

  listenToImageUpload() {
    this.uploadService.listenImageUpload().subscribe(() => {
      this.getCompany();
    },
      () => {
        this.notificationService.errorMessage(NotificationMessages.SYNC_ERROR);
      }
    );
  }

  setFormControls(): void {
    this.companyId =
      this.id === undefined ? this.route.snapshot.paramMap.get('id') : this.id;
    this.editFormTitle =
      this.formTitle === undefined ? 'Uređivanje tvrtke' : this.formTitle;
  }

  getCompany() {
    this.companyService.getCompany(this.companyId).subscribe(
      (company: Company) => {
        this.company = company as Company;
        this.setEditCompanyForm();
      },
      () => {
        this.notificationService.errorMessage(NotificationMessages.GET_ERROR);
      }
    );
  }

  setEditCompanyForm(): void {
    this.editForm = this.fb.group({
      name: new FormControl(this.company.name, Validators.required),
      description : new FormControl(this.company.description),
      address: new FormControl(this.company.address, Validators.required),
      taxCode: new FormControl(this.company.taxCode, taxCodeValidator),
      website: new FormControl(this.company.website, websiteValidator),
      categoryTags: new FormControl(
        this.company.categoryTags?.split(';').slice(0, -1)
      ),
      numberOfEmployees: new FormControl(this.company.numberOfEmployees),
      categories: new FormControl(this.company.categories),
      newsletterCategories: new FormControl(this.company.newsletterCategories),
    });

    this.loadingData = false;
  }

  

  onSubmit(): void {
    this.loadingData = true;
    this.prepareCategoriesBeforeSubmit();

    this.companyService
      .editCompany(this.companyId, this.editForm.value)
      .subscribe(
        (response: any) => {
          this.company = response as Company;
          this.setEditCompanyForm();
          this.notificationService.successMessage(
            NotificationMessages.UPDATE_SUCCESS
          );
        },
        () => {
          this.notificationService.errorMessage(
            NotificationMessages.UPDATE_ERROR
          );
        }
      );
  }


  prepareCategoriesBeforeSubmit(): void {
    const realCategories = this.editForm.get('categories').value.map((x) => x.id);
    const realnewsCategories = this.editForm
      .get('newsletterCategories')
      .value.map((x) => x.id);
    this.editForm.get('categories').setValue(realCategories);
    this.editForm.get('newsletterCategories').setValue(realnewsCategories);
  }
}
