import { NotFoundComponent } from './pages/not-found/not-found.component';
import { ActiveEquipmentComponent } from './pages/active-equipment/active-equipment.component';
import { ActiveSpecificKnowledgesComponent } from './pages/active-specific-knowledges/active-specific-knowledges.component';
import { ActiveResearchProblemsComponent } from './pages/active-research-problems/active-research-problems.component';
import { NewsDetailsComponent } from './pages/details-pages/news-details/news-details.component';
import { CompanyDetailsComponent } from './pages/details-pages/company-details/company-details.component';
import { PublicOrganizationDetailsComponent } from './pages/details-pages/public-organization-details/public-organization-details.component';
import { ScientistDetailsComponent } from './pages/details-pages/scientist-details/scientist-details.component';
import { StartComponent } from './components/start/start.component';
import { ActiveNewsComponent } from './pages/active-news/active-news.component';
import { ActivePublicOrganizationsComponent } from './pages/active-public-organizations/active-public-organizations.component';
import { ActiveScientistsComponent } from './pages/active-scientists/active-scientists.component';
import { ActiveCompaniesComponent } from './pages/active-companies/active-companies.component';
import { UnsubscribedComponent } from './pages/unsubscribed/unsubscribed.component';
import { AlreadyConfirmedComponent } from './pages/already-confirmed/already-confirmed.component';
import { MyProfileComponent } from './pages/my-profile/my-profile.component';
import { HelpComponent } from './pages/admin/help/help.component';
import { HomeComponent } from './pages/home/home.component';
import { LoginComponent } from './pages/login/login.component'; 
import { RegistrationComponent } from './pages/registration/registration.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { UserLoginComponent } from './pages/user-login/user-login.component';
import { MainSearchComponent } from './pages/main-search/main-search.component';
import { ThankYouComponent } from './pages/thank-you/thank-you.component';
import { AboutComponent } from './pages/about/about.component';
import { ContactComponent } from './pages/contact/contact.component';
import { ActiveJobOffersComponent } from './pages/active-job-offers/active-job-offers.component';
import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    children: [
      {
        path: '',
        component: StartComponent,
      },
      {
        path: 'contact',
        component: ContactComponent,
      },
      {
        path: 'about',
        component: AboutComponent,
      },
      {
        path: 'main-search',
        component: MainSearchComponent,
      },
      {
        path: 'user-login',
        component: UserLoginComponent,
      },
      { path: 'registration', component: RegistrationComponent },
      {
        path: 'scientists',
        component: ActiveScientistsComponent,
      },
      {
        path: 'scientists/details/:id',
        component: ScientistDetailsComponent,
      },
      {
        path: 'companies',
        component: ActiveCompaniesComponent,
      },
      {
        path: 'companies/details/:id',
        component: CompanyDetailsComponent,
      },
      {
        path: 'public-organizations',
        component: ActivePublicOrganizationsComponent,
      },
      {
        path: 'public-organizations/details/:id',
        component: PublicOrganizationDetailsComponent,
      },
      {
        path: 'news',
        component: ActiveNewsComponent,
      },
      {
        path: 'news/details/:id',
        component: NewsDetailsComponent,
      },
      {
        path: 'companies/research-problems',
        component: ActiveResearchProblemsComponent,
      },
      {
        path: 'public-organizations/research-problems',
        component: ActiveResearchProblemsComponent,
      },
      {
        path: 'companies/job-offers',
        component: ActiveJobOffersComponent,
      },
      {
        path: 'scientists/specific-knowledges',
        component: ActiveSpecificKnowledgesComponent,
      },
      {
        path: 'scientists/equipment',
        component: ActiveEquipmentComponent,
      },
      {
        path: 'my-profile',
        component: MyProfileComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'unsubscribed',
        component: UnsubscribedComponent,
      },
      {
        path: 'mail-confirmed',
        component: ThankYouComponent,
      },
      {
        path: 'already-confirmed',
        component: AlreadyConfirmedComponent,
      },
      { path: '**', component: NotFoundComponent },
    ],
  },
  { path: 'help', component: HelpComponent },
  { path: 'login', component: LoginComponent },
];

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forRoot(routes, {
      relativeLinkResolution: 'legacy',
      scrollPositionRestoration: 'enabled',
    }),
    CommonModule,
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
