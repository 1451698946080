<!-- Toolbar -->
<mat-toolbar color="primary" class="app-header">
  <div class="flex">
    <a href="#" target="_blank" class="logo">
      <img class="img-fluid" src="assets/logo/rimap-logo-bijeli.svg" />
    </a>
  </div>
  <div class="nav-tool-items"> 
    <a class="nav-item-spacing" mat-raised-button [routerLink]="['']">Naslovna</a>
    <a class="nav-item-spacing" mat-raised-button [routerLink]="['/main-search']">Tražilica</a>
    <a *ngIf="userLogged" class="nav-item-spacing" mat-raised-button [routerLink]="['/my-profile']">Moj profil</a>
    <a *ngIf="!userLogged" class="nav-item-spacing" mat-raised-button [routerLink]="['/user-login']">Prijava</a>
    <a *ngIf="!userLogged" class="nav-item-spacing" mat-raised-button [routerLink]="['/registration']">Registracija</a>
    <a *ngIf="userLogged" class="nav-item-spacing" mat-raised-button (click)="logOut()">Odjava</a>
</div>
</mat-toolbar>
