import { ActivatedRoute } from '@angular/router';
import { Component, Input, OnInit } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators, 
} from '@angular/forms';
import { NotificationService } from 'src/app/services/notification.service';
import { ResearchProblemService } from 'src/app/services/research-problem.service';
import { NotificationMessages } from 'src/app/shared/constants/notification-messages';
import { EntityType } from 'src/app/shared/enums/entity-type';
import { ResearchProblem } from 'src/app/shared/models/research-problem/research-problem';
import { MatDialogRef } from '@angular/material/dialog';
import * as Editor from 'src/app/shared/ckeditor/build/ckeditor';
import { UploadAdapter } from 'src/app/shared/helpers/upload-adapter';
import { EditorConfig } from 'src/app/shared/constants/editor-config';

@Component({
  selector: 'app-edit-research-problem',
  templateUrl: './edit-research-problem.component.html',
  styleUrls: ['./edit-research-problem.component.css'],
})
export class EditResearchProblemComponent implements OnInit {
  constructor(
    private fb: FormBuilder,
    private researchProblemService: ResearchProblemService,
    private notificationService: NotificationService,
    public dialogRef: MatDialogRef<EditResearchProblemComponent>
  ) {}
  @Input() entityType: EntityType;
  @Input() entityId: string;
  @Input() researchProblemId: string;


  editForm: FormGroup;
  loadingData: boolean;
  researchProblem: ResearchProblem;

  public Editor = Editor;

  public get editorConfig(): typeof EditorConfig{
    return EditorConfig;
  }

  public entityTypeEnum = EntityType;

  ngOnInit() {
    this.loadingData = true;
    this.getResearchProblem();
  }

  getResearchProblem() {
    this.researchProblemService
      .getResearchProblem(
        this.researchProblemId,
        this.entityId,
        this.entityType
      )
      .subscribe(
        (response: ResearchProblem) => {
          this.researchProblem = response as ResearchProblem;
          this.setEditForm();
        },
        (error) => {
          this.notificationService.errorMessage(NotificationMessages.GET_ERROR);
        }
      );
  }

  setEditForm(): void {
    this.editForm = this.fb.group({
      title: new FormControl(this.researchProblem.title, Validators.required),
      description: new FormControl(
        this.researchProblem.description,
        Validators.required
      ),
      categories: new FormControl(
        this.researchProblem.categories,
        Validators.required
      ),
      academicCommunityContributionPossibility: new FormControl(
        this.researchProblem.academicCommunityContributionPossibility,
        Validators.required
      ),
    });

    this.loadingData = false;
  }

  onSubmit(): void {
    this.loadingData = true;

    this.prepareCategoriesBeforeSubmit();

    this.researchProblemService
      .editResearchProblem(
        this.researchProblemId,
        this.entityType,
        this.editForm.value,
        this.entityId
      )
      .subscribe(
        (response: ResearchProblem) => {
          this.notificationService.successMessage(
            NotificationMessages.UPDATE_SUCCESS
          );
          this.researchProblemService.pingNewResearchProblem();
        },
        (error) => {
          this.notificationService.errorMessage(
            NotificationMessages.UPDATE_ERROR
          );
        }
      );

    this.loadingData = false;
  }

  onNoClick() {
    this.dialogRef.close();
  }

  onReady(eventData) {
    eventData.plugins.get(
      'FileRepository'
    ).createUploadAdapter = function (loader: { file: string }) {
      return new UploadAdapter(loader);
    };
  }

  prepareCategoriesBeforeSubmit(): void {
    const realCategories = this.editForm
      .get('categories')
      .value.map((x) => x.id);
    this.editForm.get('categories').setValue(realCategories);
  }
}
