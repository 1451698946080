import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { CategoryService } from './../../services/category.service';
import { PublicService } from './../../services/public.service';
import { Observable, Subscription } from 'rxjs';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { Company } from 'src/app/shared/models/company/company';
import { trigger, transition, useAnimation } from '@angular/animations';
import { FadeAnimation } from 'src/app/shared/animations/fade-animation';
import { MatPaginator, MatTableDataSource } from '@angular/material';
import { OnDestroy } from '@angular/core';
import { Scientist } from 'src/app/shared/models/scientist/scientist';
import { PublicOrganization } from 'src/app/shared/models/public-organization/public-organization';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
 
@Component({  
  selector: 'app-main-search', 
  templateUrl: './main-search.component.html',
  styleUrls: ['./main-search.component.css'],
  animations: [
    trigger('fadeAnimation', [
      transition('void => *', [useAnimation(FadeAnimation)]),
    ]),
  ],
})
export class MainSearchComponent implements OnInit, OnDestroy {
  @ViewChild('companiesPaginator') companiesPaginator: MatPaginator;
  @ViewChild('scientistsPaginator') scientistsPaginator: MatPaginator;
  @ViewChild('publicOrganizationsPaginator')
  publicOrganizationsPaginator: MatPaginator;

  subscription: Subscription;

  searchForm: FormGroup;

  companies: Observable<Company[]>;
  companiesDataSource: MatTableDataSource<Company>;

  scientists: Observable<Scientist[]>;
  scientistsDataSource: MatTableDataSource<Scientist>;

  publicOrganizations: Observable<PublicOrganization[]>;
  publicOrganizationsDataSource: MatTableDataSource<PublicOrganization>;

  loadingData: boolean;

  constructor(
    private publicService: PublicService,
    private fb: FormBuilder,
    private categoryService: CategoryService,
    private router: Router,
    private changeDetectorRef: ChangeDetectorRef,
    private titleService: Title
  ) {
    this.titleService.setTitle('Pretraga | RIMAP');
    this.setSearchForm();
  }

  ngOnInit() {
    this.loadingData = true;

    this.subscription = this.categoryService
      .listenSelectedCategories()
      .subscribe(() => {
        this.loadingData = true;
        this.getSearchEntities();
      });

    this.getSearchEntities();
  }

  initCompaniesPaginator(): void {
    this.companiesPaginator._intl.itemsPerPageLabel = 'Rezultata po stranici';
    this.companiesPaginator._intl.nextPageLabel = 'Slijedeća stranica';
    this.companiesPaginator._intl.previousPageLabel = 'Prethodna stranica';
    this.companiesPaginator._intl.getRangeLabel = (
      page: number,
      pageSize: number,
      length: number
    ) => {
      const start = page * pageSize + 1;
      const end = (page + 1) * pageSize;
      return `${start} - ${end} od ${this.companiesPaginator.length}`;
    };
  }

  initScientisPaginator(): void {
    this.scientistsPaginator._intl.itemsPerPageLabel = 'Rezultata po stranici';
    this.scientistsPaginator._intl.nextPageLabel = 'Slijedeća stranica';
    this.scientistsPaginator._intl.previousPageLabel = 'Prethodna stranica';
    this.scientistsPaginator._intl.getRangeLabel = (
      page: number,
      pageSize: number,
      length: number
    ) => {
      const start = page * pageSize + 1;
      const end = (page + 1) * pageSize;
      return `${start} - ${end} od ${this.scientistsPaginator.length}`;
    };
  }

  initOrganizationsPaginator(): void {
    this.publicOrganizationsPaginator._intl.itemsPerPageLabel =
      'Rezultata po stranici';
    this.publicOrganizationsPaginator._intl.nextPageLabel =
      'Slijedeća stranica';
    this.publicOrganizationsPaginator._intl.previousPageLabel =
      'Prethodna stranica';
    this.publicOrganizationsPaginator._intl.getRangeLabel = (
      page: number,
      pageSize: number,
      length: number
    ) => {
      const start = page * pageSize + 1;
      const end = (page + 1) * pageSize;
      return `${start} - ${end} od ${this.publicOrganizationsPaginator.length}`;
    };
  }

  setSearchForm(): void {
    this.searchForm = this.fb.group({
      categories: new FormControl(),
    });
  }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.companiesDataSource.filter = filterValue.trim().toLowerCase();
    this.scientistsDataSource.filter = filterValue.trim().toLowerCase();
    this.publicOrganizationsDataSource.filter = filterValue
      .trim()
      .toLowerCase();
  }

  getSearchEntities(): void {
    this.getCompanies();
    this.getScientists();
    this.getPublicOrganizations();
  }

  getCompanies() {
    this.publicService
      .getCompanies(this.searchForm.value)
      .subscribe((response: Company[]) => {
        this.companiesDataSource = new MatTableDataSource<Company>(response);
        this.companies = this.companiesDataSource.connect();
        this.changeDetectorRef.detectChanges();

        this.companiesDataSource.paginator = this.companiesPaginator;
        this.initCompaniesPaginator();
      });
  }

  getScientists() {
    this.publicService
      .getScientists(this.searchForm.value)
      .subscribe((response: Scientist[]) => {
        this.scientistsDataSource = new MatTableDataSource<Scientist>(response);
        this.scientists = this.scientistsDataSource.connect();
        this.changeDetectorRef.detectChanges();

        this.scientistsDataSource.paginator = this.scientistsPaginator;
        this.initScientisPaginator();
      });
  }

  getPublicOrganizations() {
    this.publicService
      .getPublicOrganizations(this.searchForm.value)
      .subscribe((response: PublicOrganization[]) => {
        this.publicOrganizationsDataSource =
          new MatTableDataSource<PublicOrganization>(response);
        this.loadingData = false;
        this.publicOrganizations = this.publicOrganizationsDataSource.connect();
        this.changeDetectorRef.detectChanges();
        this.publicOrganizationsDataSource.paginator =
          this.publicOrganizationsPaginator;
        this.initOrganizationsPaginator();
      });
  }

  openCompany(companyId: string): void {
    this.router.navigateByUrl('/companies/details/' + companyId);
  }

  openScientist(scientistId: string): void {
    this.router.navigateByUrl('/scientists/details/' + scientistId);
  }

  openPublicOrganization(publicOrganizationId: string): void {
    this.router.navigateByUrl(
      '/public-organizations/details/' + publicOrganizationId
    );
  }

  ngOnDestroy() {
    if (this.companiesDataSource) {
      this.companiesDataSource.disconnect();
    }

    if (this.publicOrganizationsDataSource) {
      this.publicOrganizationsDataSource.disconnect();
    }

    if (this.scientistsDataSource) {
      this.scientistsDataSource.disconnect();
    }
  }
}
