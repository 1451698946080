<div class="container" [@fadeAnimation]>
  <div>
    <div class="mainflip flip-0">
      <div class="frontside">
        <div class="card" align="center">
          <div class="card-body text-center">
            <div class="row user-type-container">
              <mat-radio-group aria-labelledby="Odabir vrste registracije" class="full-width" [(ngModel)]="authType">
                <h2>Molimo odaberite vrstu prijave</h2>
                <span *ngFor="let loginType of loginTypes">
                  <mat-radio-button class="radio-button" [value]="loginType.value">
                    {{ loginType.name }}
                  </mat-radio-button>
                </span>
              </mat-radio-group>
            </div>
            <form [formGroup]="loginUserForm" autocomplete="off" (submit)="onSubmit()">
              <mat-form-field class="full-width-input" appearance="outline">
                <mat-label>Korisničko ime</mat-label>
                <input matInput formControlName="username" />
                <mat-error> Korisničko ime je obavezan podatak. </mat-error>
              </mat-form-field>&nbsp;
              <mat-form-field class="full-width-input" appearance="outline">
                <mat-label>Lozinka</mat-label>
                <input matInput formControlName="password" type="password" />
                <mat-error> Lozinka je obavezan podatak. </mat-error>
              </mat-form-field>
              <div class="buttons-container" align="center">

                <p>
                  <button mat-raised-button type="submit" color="primary" [disabled]="loginUserForm.invalid">
                    Prijavi se</button>&nbsp;
                  <button type="button" mat-flat-button color="accent" (click)="openPasswordReset()">
                    Zaboravljena lozinka
                  </button>
                </p>
                <button type="button" mat-flat-button color="primary" (click)="goToRegistration()">
                  Registrirajte se na RIMAP platformi
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>