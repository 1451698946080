import { VisibilityDialogComponent } from './../../../../components/visibility-dialog/visibility-dialog.component';
import { CompanyService } from './../../../../services/company.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatSort, MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { PublicOrganization as Company } from 'src/app/shared/models/public-organization/public-organization';

const COLUMNS: string[] = [
  'edit-buttons',
  'name',
  'address',
  'email',
  'numberOfViews',
  'delete-buttons'
];

@Component({
  selector: 'app-companies-list',
  templateUrl: './companies-list.component.html',
  styleUrls: ['./companies-list.component.css']
})
export class CompaniesListComponent implements OnInit {

  loadingData: boolean;
  displayedColumns: string[] = COLUMNS;
  companies: Company[];
  dataSource: MatTableDataSource<Company[]>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private router: Router,
    private service: CompanyService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.loadingData = true;
    this.getCompanies();
  }

  initPaginator() {
    this.paginator._intl.itemsPerPageLabel = 'Rezultata po stranici';
    this.paginator._intl.nextPageLabel = 'Slijedeća stranica';
        this.paginator._intl.previousPageLabel = 'Prethodna stranica';
    this.paginator._intl.getRangeLabel = (
      page: number,
      pageSize: number,
      length: number
    ) => {
      const start = page * pageSize + 1;
      const end = (page + 1) * pageSize;
      return `${start} - ${end} od ${this.paginator.length}`;
    };
  }


  getCompanies(): void {
    this.service.getCompanies().subscribe((companies: any) => {
      this.dataSource = new MatTableDataSource<Company[]>(companies);
      this.loadingData = false;
      this.dataSource.paginator = this.paginator;
      this.initPaginator();
      this.dataSource.sort = this.sort;
    });
  }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  goToEditPage(companyId: string): void {
    this.router.navigateByUrl('/admin/companies/edit/' + companyId);
  }

  openVisibilityDialog(companyId: string): void {
    const dialogRef = this.dialog.open(VisibilityDialogComponent);

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.loadingData = true;
        this.flipActiveCompany(companyId);
      }
    });
  }

  flipActiveCompany(companyId: string): void{
    this.service.flipCompanyActive(companyId).subscribe(
      (() => {
        this.getCompanies();
      })
    );
  }
}
