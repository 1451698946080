export class EditorConfig {
  public static getConfig() {
    return {
      removePlugins: ['Title'],
      toolbar: {
        items: [
          'undo',
          'redo',
          '|',
          'heading',
          '|',
          'bold',
          'italic',
          'blockQuote',
          'alignment',
          'numberedList',
          'bulletedList',
          'link',
          '|',
          'outdent',
          'indent',
          '|',
          'imageUpload',
          // 'CKFinder',
          'mediaEmbed',
          'code',
          'insertTable'
        ],
      },
      headings: [
        { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
        { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
        { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' }
      ],
      title: {
        placeholder: ''
      },
      placeholder: 'Unesite sadržaj ovdje',
      image: {
        toolbar: ['imageTextAlternative', 'imageStyle:full', 'imageStyle:side'],
      },
      // ckfinder: {
      //   options: {
      //     language: 'en',
      //   },
      //   openerMethod: 'popup',
      // },
      table: {
        contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells'],
      },
    };
  }
}
