<app-loader *ngIf="loadingData"></app-loader>

  <h1 mat-dialog-title *ngIf="!loadingData">Izmjena poslovne ponude</h1>
  <mat-dialog-content *ngIf="!loadingData">
    <form
      [formGroup]="editForm"
      autocomplete="off"
      (submit)="onSubmit()"
    >

    <div class="row centered-container">
      <img class="img img-fluid entity-image" src="{{ jobOffer.image || 'assets/logo/image-default.png' }}"
        alt="Slika razvojnog prijedloga" />
    </div>

    <app-file-uploader [entityType]="entityType.JobOffer" [entityId]="jobOffer.id" [parentEntityId]="companyId">
    </app-file-uploader>

    <mat-form-field class="full-width" appearance="outline">
        <mat-label>Naziv</mat-label>
        <input matInput formControlName="name" />
        <mat-error>Naziv posla je obavezan.</mat-error>
     </mat-form-field>
     <mat-form-field class="full-width" appearance="outline">
        <mat-label>Opis</mat-label>
        <textarea rows="10" cols="40" matInput formControlName="description"></textarea>
        <mat-error>Opis posla je obavezan.</mat-error>
     </mat-form-field>
     <mat-form-field appearance="outline">
      <mat-label>Lokacija</mat-label>
      <mat-select matInput formControlName="location">
          <mat-option *ngFor="let place of places" [value]="place.name">
              {{place.name}}
          </mat-option>
      </mat-select>
      <mat-error>Odabir lokacije je obavezan.</mat-error>
  </mat-form-field>

     <mat-form-field appearance="outline">
      <mat-label>Rok trajanja natječaja</mat-label>
      <input matInput [matDatepicker]="deadline" formControlName="deadline">
      <mat-datepicker-toggle matInput matSuffix [for]="deadline"></mat-datepicker-toggle>
      <mat-datepicker #deadline></mat-datepicker>
  </mat-form-field>
     <mat-form-field class="full-width" appearance="outline">
        <mat-label>Iskustvo</mat-label>
        <input matInput formControlName="experience" />
     </mat-form-field>
     <mat-form-field class="full-width" appearance="outline">
        <mat-label>Obrazovanje</mat-label>
        <input matInput formControlName="education" />
     </mat-form-field>
     <mat-form-field class="full-width" appearance="outline">
        <mat-label>Posebni uvjeti</mat-label>
        <input matInput formControlName="specialConditions" />
     </mat-form-field>
     <mat-form-field class="full-width" appearance="outline">
        <mat-label>Trajanje natječaja</mat-label>
        <input matInput formControlName="duration" />
     </mat-form-field>
     <mat-form-field class="full-width" appearance="outline">
        <mat-label>Način prijave</mat-label>
        <input matInput formControlName="applyMethod" />
     </mat-form-field>
     <mat-form-field class="full-width" appearance="outline">
        <mat-label>Dodatne napomene</mat-label>
        <textarea rows="10" cols="40" matInput formControlName="additional"></textarea>
     </mat-form-field>

      <app-category-picker [categoriesEnabled]="true" [parentForm]="editForm" [newsLettersEnabled]="false"></app-category-picker>

      <div align="end" mat-dialog-actions align="end">
        <button type="button" mat-raised-button color="warn" (click)="onNoClick()">Odustani</button>&nbsp;
        <button
          type="submit"
          mat-raised-button
          color="primary"
          [mat-dialog-close]="true" cdkFocusInitial
          [disabled]="editForm.invalid"
        >
          Spremi promjene
        </button>
      </div>
    </form>
  </mat-dialog-content>