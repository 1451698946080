import { ActiveEntities } from './../shared/models/active-entities';
import { HttpClient } from '@angular/common/http';
import { JobOffer } from './../shared/models/job-offer/job-offer';
import { Scientist } from './../shared/models/scientist/scientist';
import { Observable } from 'rxjs';
import { ResearchProblem } from './../shared/models/research-problem/research-problem';
import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';
import { Company } from '../shared/models/company/company';
import { PublicOrganization } from '../shared/models/public-organization/public-organization';
import { Equipment } from '../shared/models/equipment/equipment';
import { SpecificKnowledge } from '../shared/models/specific-knowledge/specific-knowledge';
import { News } from '../shared/models/news/news';

@Injectable({
  providedIn: 'root'
})
export class PublicService {

  publicUrl = environment.apiUrl + '/public';

  ScientistsUrl = this.publicUrl + '/scientists/';

  CompaniesUrl = this.publicUrl + '/companies/';

  PublicOrganizationsUrl = this.publicUrl + '/publicOrganizations/';

  NewsUrl = this.publicUrl + '/news/';

  constructor(private http: HttpClient) { }

  getAlls(): Observable<ActiveEntities>{
    return this.http.get<ActiveEntities>(this.publicUrl + '/getAll');
  }

  // scientists
  getScientists(categories: string[]): Observable<Scientist[]>{
    return this.http.post<Scientist[]>(this.ScientistsUrl + 'get', categories);
  }

  getMainScientists(): Observable<Scientist[]>{
    return this.http.get<Scientist[]>(this.ScientistsUrl + 'get');
  }

  getScientist(scientistId: string): Observable<Scientist>{
    return this.http.get<Scientist>(this.ScientistsUrl + 'get/' + scientistId);
  }

  getScientistEquipment(scientistId: string): Observable<Equipment[]>{
    return this.http.get<Equipment[]>(this.ScientistsUrl + scientistId + '/getEquipment');
  }

  getScienitstEquipment(scientistId: string, equipmentId: string): Observable<Equipment>{
    return this.http.get<Equipment>(this.ScientistsUrl + scientistId + '/getEquipment/' + equipmentId);
  }

  getScientistSpecificKnowledges(scientistId: string): Observable<SpecificKnowledge[]>{
    return this.http.get<SpecificKnowledge[]>(this.ScientistsUrl + scientistId + '/getSpecificKnowledge');
  }

  getScientistSpecificKnowledge(scientistId: string, specificKnowledgeId: string): Observable<SpecificKnowledge>{
    return this.http.get<SpecificKnowledge>(this.ScientistsUrl + scientistId + '/getSpecificKnowledge/' +  specificKnowledgeId);
  }

  getLatestEquipment(numberOfEquipment: number): Observable<Equipment[]>{
    return this.http.get<Equipment[]>(this.ScientistsUrl + 'getLatestEquipment/' + numberOfEquipment);
  }

  getLatestSpecificKnowledge(): Observable<SpecificKnowledge[]>{
    return this.http.get<SpecificKnowledge[]>(this.ScientistsUrl + '/getLatestSpecificKnowledge');
  }

  getScientistsSpecificKnowledges(categories: string[]): Observable<SpecificKnowledge[]>{
    return this.http.post<SpecificKnowledge[]>(this.ScientistsUrl + 'getSpecificKnowledge', categories);
  }

  getScientistsEquipment(categories: string[]): Observable<Equipment[]>{
    return this.http.post<Equipment[]>(this.ScientistsUrl + 'getEquipment', categories);
  }

  getLatestScientists(numberOfScientists: number): Observable<Scientist[]> {
    return this.http.get<Scientist[]>(this.ScientistsUrl + 'getLatest/' + numberOfScientists);
  }


  // companies
  getCompanies(categories: string[]): Observable<Company[]>{
    return this.http.post<Company[]>(this.CompaniesUrl + 'get', categories);
  }

  getMainCompanies(): Observable<Company[]>{
    return this.http.get<Company[]>(this.CompaniesUrl + 'get');
  }

  getCompany(companyId: string): Observable<Company>{
    return this.http.get<Company>(this.CompaniesUrl + 'get/' + companyId);
  }

  getCompanyResearchProblems(companyId: string): Observable<ResearchProblem[]> {
    return this.http.get<ResearchProblem[]>(this.CompaniesUrl + companyId + '/getResearchProblems');

  }

  getCompanyResearchProblem(companyId: string, researchProblemId: string): Observable<ResearchProblem>{
    return this.http.get<ResearchProblem>(this.CompaniesUrl + companyId + '/getResearchProblem/' + researchProblemId);

  }

  getCompanyJobOffers(companyId: string): Observable<JobOffer[]>{
    return this.http.get<JobOffer[]>(this.CompaniesUrl + companyId + '/getJobOffers');
  }

  getCompanyJobOffer(companyId: string, jobOfferId: string): Observable<JobOffer>{
    return this.http.get<JobOffer>(this.CompaniesUrl + companyId + '/getJobOffer/' + jobOfferId);
  }

  getLatestJobOffers(numberOfJobOffers: number): Observable<JobOffer[]>{
    return this.http.get<JobOffer[]>(this.CompaniesUrl + 'getLatestJobOffers/' +  numberOfJobOffers);
  }

  getLatestResearchProblems(numberOfResearchProblems: number): Observable<ResearchProblem[]>{
    return this.http.get<ResearchProblem[]>(this.CompaniesUrl + 'getResearchProblems/' + numberOfResearchProblems);
  }

  getLatestCompaniesResearchProblems(numberOfResearchProblems: number) {
    return this.http.get<ResearchProblem[]>(this.CompaniesUrl + 'getLatestResearchProblems/' + numberOfResearchProblems);
  }

  getCompaniesJobOffers(categories: string[]): Observable<JobOffer[]>{
    return this.http.post<JobOffer[]>(this.CompaniesUrl + 'getJobOffers', categories);
  }

  getCompaniesResearchProblems(categories: string[]): Observable<ResearchProblem[]>{
    return this.http.post<ResearchProblem[]>(this.CompaniesUrl + 'getResearchProblems', categories);
  }

  getLatestCompanies(numberOfCompanies: number): Observable<Company[]>{
    return this.http.get<Company[]>(this.CompaniesUrl + 'getLatest/' + numberOfCompanies);
  }

  // public organizations
  getPublicOrganizations(categories: string[]): Observable<PublicOrganization[]>{
    return this.http.post<PublicOrganization[]>(this.PublicOrganizationsUrl + 'get', categories);
  }

  getMainPublicOrganizations(): Observable<PublicOrganization[]>{
    return this.http.get<PublicOrganization[]>(this.PublicOrganizationsUrl + 'get');
  }

  getPublicOrganization(publicOrganizationId: string): Observable<PublicOrganization>{
    return this.http.get<PublicOrganization>(this.PublicOrganizationsUrl + 'get/' + publicOrganizationId);
  }

  getPublicOrganizationResearchProblems(publicOrganizationId: string): Observable<ResearchProblem[]>{
    return this.http.get<ResearchProblem[]>(this.PublicOrganizationsUrl + publicOrganizationId + '/getResearchProblems');
  }

  getPublicOrganizationResearchProblem(publicOrganizationId: string, researchProblemId: string): Observable<ResearchProblem>{
    return this.http.get<ResearchProblem>(this.PublicOrganizationsUrl + publicOrganizationId + '/getResearchProblem/' + researchProblemId );
  }

  getLatestPublicOrganizationResearchProblems(numberOfResearchProblems: number): Observable<ResearchProblem[]>{
    return this.http.get<ResearchProblem[]>(this.PublicOrganizationsUrl + 'getLatestResearchProblems/' + numberOfResearchProblems);
  }

  getPublicOrganizationsResearchProblems(categories: string[]): Observable<ResearchProblem[]>{
    return this.http.post<ResearchProblem[]>(this.PublicOrganizationsUrl + 'getResearchProblems', categories);
  }

  getLatestPublicOrganizations(numberOfPublicOrganizations: number): Observable<PublicOrganization[]>{
    return this.http.get<PublicOrganization[]>(this.PublicOrganizationsUrl + 'getLatest/' + numberOfPublicOrganizations);
  }

  // news
  getNews(): Observable<News[]>{
    return this.http.get<News[]>(this.NewsUrl + 'get');
  }

  getSingleNews(newsId: string): Observable<News>{
    return this.http.get<News>(this.NewsUrl + 'get/' + newsId);

  }

  getLatestNews(numberOfPosts: number): Observable<News[]>{
    return this.http.get<News[]>(this.NewsUrl + 'getLatest/' + numberOfPosts);

  }

}
