import { EntityType } from 'src/app/shared/enums/entity-type';
import { AuthService } from 'src/app/services/auth.service';
import { NotificationService } from 'src/app/services/notification.service';
import { NotificationMessages } from 'src/app/shared/constants/notification-messages';
import { UserService } from './../../services/user.service';
import { Component, Input, OnInit } from '@angular/core';
import {
  NgForm,
  FormGroup,
  FormControl,
  FormBuilder,
} from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Validators } from '@angular/forms';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.css'], 
})
export class PasswordResetComponent implements OnInit {
  loadingData: boolean;
  @Input() entityType: EntityType;

  resetPasswordForm: FormGroup;

  constructor(
    private authService: AuthService,
    private fb: FormBuilder,
    private notificationService: NotificationService,
    public dialogRef: MatDialogRef<PasswordResetComponent>
  ) {}

  ngOnInit() {
    this.loadingData = true; 
    this.setResetPasswordForm();
  }

  setResetPasswordForm() {
    this.resetPasswordForm = this.fb.group({
      email: new FormControl('', Validators.required),
    });
  }

  onSubmit() {
    this.authService.resetPassword(this.resetPasswordForm.get('email').value, this.entityType).subscribe(
      (response: any) => {
        this.notificationService.successMessage(NotificationMessages.EMAIL_SENT);
      },
      (error) => {
        this.notificationService.errorMessage(NotificationMessages.EMAIL_NOT_SENT);
      }
    );
  }

  onNoClick() {
    this.dialogRef.close();
  }
}
