import { CreateEquipmentDialogComponent } from './../../../../components/create-dialogs/create-equipment-dialog/create-equipment-dialog.component';
import { AuthService } from 'src/app/services/auth.service';
import { EditEquipmentComponent } from './../edit-equipment/edit-equipment.component';
import { VisibilityDialogComponent } from './../../../../components/visibility-dialog/visibility-dialog.component';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router'; 
import { NotificationMessages } from './../../../../shared/constants/notification-messages';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { EquipmentService } from 'src/app/services/equipment.service';
import { NotificationService } from 'src/app/services/notification.service';
import { Scientist as Equipment } from 'src/app/shared/models/scientist/scientist';
import { DeleteDialogComponent } from 'src/app/components/delete-dialog/delete-dialog.component';
 
const COLUMNS: string[] = ['edit-buttons', 'description', 'delete-buttons'];

@Component({
  selector: 'app-equipment-list',
  templateUrl: './equipment-list.component.html',
  styleUrls: ['./equipment-list.component.css'],
})
export class EquipmentListComponent implements OnInit {
  loadingData: boolean;
  displayedColumns: string[] = COLUMNS;
  equipment: Equipment[];
  dataSource: MatTableDataSource<Equipment[]>;

  admin: boolean;

  @Input() scientistId: string;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private equipmentService: EquipmentService,
    private authService: AuthService,
    private router: Router,
    private dialog: MatDialog,
    private notificationService: NotificationService
  ) {
    this.admin = this.authService.isSuperAdminLogged();
  }

  ngOnInit() {
    this.loadingData = true;
    this.getEquipment();
    this.listenEquipmentChanges();
  }

  initPaginator() {
    this.paginator._intl.itemsPerPageLabel = 'Rezultata po stranici';
    this.paginator._intl.nextPageLabel = 'Slijedeća stranica';
        this.paginator._intl.previousPageLabel = 'Prethodna stranica';
    this.paginator._intl.getRangeLabel = (
      page: number,
      pageSize: number,
      length: number
    ) => {
      const start = page * pageSize + 1;
      const end = (page + 1) * pageSize;
      return `${start} - ${end} od ${this.paginator.length}`;
    };
  }


  listenEquipmentChanges(): void {
    this.equipmentService.listenEquipment().subscribe(
      (response: any) => {
        this.getEquipment();
      },
      (error) => {
        this.notificationService.warningMessage(
          NotificationMessages.SYNC_ERROR
        );
      }
    );
  }

  getEquipment(): void {
    this.equipmentService.getEquipment(this.scientistId).subscribe(
      (equipment: any) => {
        this.dataSource = new MatTableDataSource<Equipment[]>(equipment);
        this.dataSource.paginator = this.paginator;
        this.initPaginator();
        this.dataSource.sort = this.sort;
      },
      (error) => {
        this.notificationService.errorMessage(NotificationMessages.GET_ERROR);
      }
    );

    this.loadingData = false;
  }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  openVisibilityDialog(equipmentId: string): void {
    const dialogRef = this.dialog.open(VisibilityDialogComponent);

    dialogRef.afterClosed().subscribe((result) => {
      if (result !== undefined) {
        this.flipEquipmentActive(equipmentId);
      }
    });
  }

  flipEquipmentActive(equipmentId: string): void {
    this.equipmentService
      .flipEquipmentActive(this.scientistId, equipmentId)
      .subscribe(
        (response: any) => {
          this.getEquipment();
          this.notificationService.successMessage(NotificationMessages.UPDATE_SUCCESS);
        },
        (error) => {
          this.notificationService.errorMessage(NotificationMessages.UPDATE_ERROR);
        }
      );
  }

  openDeleteDialog(equipmentId: string): void {
    const dialogRef = this.dialog.open(DeleteDialogComponent);

    dialogRef.afterClosed().subscribe((result) => {
      if (result !== undefined) {
        this.deleteEquipment(equipmentId);
      }
    });
  }

  deleteEquipment(equipmentId: string): void {
    this.equipmentService
      .deleteEquipment(
        this.scientistId,
        equipmentId
      )
      .subscribe(
        (response: any) => {
          this.notificationService.successMessage(
            NotificationMessages.DELETE_SUCCESS
          );
          this.getEquipment();
        },
        (error) => {
          this.notificationService.errorMessage(
            NotificationMessages.DELETE_ERROR
          );
        }
      );
  }

  openCreateEquipmentDialog(): void {
    const dialogRef = this.dialog.open(CreateEquipmentDialogComponent);
    dialogRef.componentInstance.scientistId = this.scientistId;
    dialogRef.afterClosed().subscribe((result) => {});
  }

  openEditDialog(equipmentId: string): void {
    const dialogRef = this.dialog.open(EditEquipmentComponent);
    dialogRef.componentInstance.scientistId = this.scientistId;
    dialogRef.componentInstance.equipmentId = equipmentId;

    dialogRef.afterClosed().subscribe((result) => {});
  }
}
