<header _ngcontent-mlh-c230="" class="masthead header-about-us ng-tns-c230-5">
    <div _ngcontent-mlh-c230="" class="container-fluid h-100 ng-tns-c230-5">
        <div _ngcontent-mlh-c230="" class="row h-100 align-items-center ng-tns-c230-5">
            <div _ngcontent-mlh-c230="" class="container text-center ng-tns-c230-5">
                <h1 _ngcontent-mlh-c230="" class="page-header-title ng-tns-c230-5">O nama</h1>
            </div>
        </div>
    </div>
</header>

<div class="container">
    <div [@fadeAnimation] class="container">
        <div class="mainflip flip-0">
            <div class="frontside">
                <div class="card about-card">
                    <div class="card-body text-center">
                        <div class="card-text">
                            <p>
                                <strong>RIMAP</strong> je regionalna digitalna inovacijska platforma za prijenos
                                znanja između
                                akademske zajednice i
                                gospodarstva u Primorsko-goranskoj županiji i šire s ciljem razvoja inovacijskog
                                ekosustava u regiji.
                            </p>
                            <p>
                                Platforma omogućuje umrežavanje gospodarskih subjekata, poduzetnika,
                                istraživača, znanstvenika, javnog i
                                nevladinog sektora te ostalih zainteresiranih sudionika s ciljem transfera
                                znanja temeljenog na rezultatima
                                istraživanja i razvojnih djelatnosti.
                            </p>
                            <p>
                                Platforma potiče višesmjernu interakciju i proaktivno umrežavanje za povezivanje
                                razvojnih projekata i
                                potreba gospodarskog i javnog sektora s istraživačkim i inovacijskim rješenjima,
                                dostignućima,
                                kompetencijama i potencijalima znanstvenog sektora.
                            </p>
                            <p>
                                Platforma omogućuje mapiranje potreba gospodarstva i javnog sektora na polju
                                razvoja i implementacije
                                naprednih digitalnih tehnologija s potencijalom stvaranja proizvoda i usluga
                                više dodane vrijednosti kroz
                                multidisciplinaran pristup objedinjujući resurse, infrastrukturu, znanja,
                                kompentencije, te dostupnost
                                ljudskog kapitala u regiji.
                            </p>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>