import { DomSanitizer } from '@angular/platform-browser';
import { CreateResearchProblemDialogComponent } from './../../../../components/create-dialogs/create-research-problem-dialog/create-research-problem-dialog.component';
import { AuthService } from 'src/app/services/auth.service'; 
import { VisibilityDialogComponent } from 'src/app/components/visibility-dialog/visibility-dialog.component';
import { EditResearchProblemComponent } from './../edit-research-problem/edit-research-problem.component';
import { MatDialog } from '@angular/material';   
import { NotificationMessages } from 'src/app/shared/constants/notification-messages';
import { NotificationService } from './../../../../services/notification.service';
import { EntityType } from '../../../../shared/enums/entity-type'; 
import { ResearchProblemService } from 'src/app/services/research-problem.service';
import { ResearchProblem } from './../../../../shared/models/research-problem/research-problem';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { DeleteDialogComponent } from 'src/app/components/delete-dialog/delete-dialog.component';

const COLUMNS: string[] = [ 
  'edit-buttons',  
  'title', 
  'delete-buttons', 
];

@Component({
  selector: 'app-research-problems-list',
  templateUrl: './research-problems-list.component.html',
  styleUrls: ['./research-problems-list.component.css'],
})
export class ResearchProblemsListComponent implements OnInit {
  @Input() entityType: EntityType;
  @Input() entityId: string;

  loadingData: boolean;
  displayedColumns: string[] = COLUMNS;
  researchProblems: ResearchProblem[];
  dataSource: MatTableDataSource<ResearchProblem[]>;

  admin: boolean;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private researchProblemService: ResearchProblemService,
    private authService: AuthService,
    private notificationService: NotificationService,
    private dialog: MatDialog
  ) {
    this.admin = this.authService.isSuperAdminLogged();
  }

  ngOnInit(): void {
    this.loadingData = true;
    this.getResearchProblems();
    this.listenNewResearchProblems();
  }

  initPaginator() {
    this.paginator._intl.itemsPerPageLabel = 'Rezultata po stranici';
    this.paginator._intl.nextPageLabel = 'Slijedeća stranica';
        this.paginator._intl.previousPageLabel = 'Prethodna stranica';
    this.paginator._intl.getRangeLabel = (
      page: number,
      pageSize: number,
      length: number
    ) => {
      const start = page * pageSize + 1;
      const end = (page + 1) * pageSize;
      return `${start} - ${end} od ${this.paginator.length}`;
    };
  }


  getResearchProblems(): void {
    this.loadingData = true;

    this.researchProblemService
      .getResearchProblems(this.entityType, this.entityId)
      .subscribe(
        (researchProblems: any) => {
          this.researchProblems = researchProblems as ResearchProblem[];
          this.dataSource = new MatTableDataSource<ResearchProblem[]>(
            researchProblems
          );
          this.loadingData = false;
          this.dataSource.paginator = this.paginator;
          this.initPaginator();
          this.dataSource.sort = this.sort;
        },
        (error) => {
          this.notificationService.errorMessage(NotificationMessages.GET_ERROR);
        }
      );

    this.loadingData = false;
  }

  listenNewResearchProblems(): void {
    this.researchProblemService.listenNewResearchProblems().subscribe(
      (response: any) => {
        this.getResearchProblems();
      },
      (error) => {
        this.notificationService.warningMessage(
          NotificationMessages.SYNC_ERROR
        );
      }
    );
  }

  openVisibilityDialog(researchProblemId: string): void {
    const dialogRef = this.dialog.open(VisibilityDialogComponent);

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.flipResearchProblemActive(researchProblemId);
      }
    });
  }

  openDeleteDialog(researchProblemId: string): void {
    const dialogRef = this.dialog.open(DeleteDialogComponent);

    dialogRef.afterClosed().subscribe((result) => {
      if (result !== undefined) {
        this.deleteResearchProblem(researchProblemId);
      }
    });
  }

  flipResearchProblemActive(researchProblemId: string): void {
    this.researchProblemService
      .flipResearchProblemActive(
        researchProblemId,
        this.entityType,
        this.entityId
      )
      .subscribe(
        (response: any) => {
          this.notificationService.successMessage(
            NotificationMessages.UPDATE_SUCCESS
          );
          this.getResearchProblems();
        },
        (error) => {
          this.notificationService.errorMessage(
            NotificationMessages.UPDATE_ERROR
          );
        }
      );
  }

  deleteResearchProblem(researchProblemId: string): void {
    this.researchProblemService
      .deleteResearchProblem(
        researchProblemId,
        this.entityType,
        this.entityId
      )
      .subscribe(
        () => {

          this.notificationService.successMessage(
            NotificationMessages.DELETE_SUCCESS
          );
          this.getResearchProblems();
        },
        (error) => {
          this.notificationService.errorMessage(
            NotificationMessages.DELETE_ERROR
          );
        }
      );
  }

  openCreateResearchProblemDialog(): void {
    const dialogRef = this.dialog.open(CreateResearchProblemDialogComponent);
    dialogRef.componentInstance.entityId = this.entityId;
    dialogRef.componentInstance.entityType = this.entityType;
    dialogRef.afterClosed().subscribe((result) => {});
  }

  openEditDialog(researchProblemId: string): void {
    const dialogRef = this.dialog.open(EditResearchProblemComponent);
    dialogRef.componentInstance.entityId = this.entityId;
    dialogRef.componentInstance.researchProblemId = researchProblemId;
    dialogRef.componentInstance.entityType = this.entityType;

    dialogRef.afterClosed().subscribe((result) => {});
  }


  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }


}
