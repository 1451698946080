import { CategoryService } from './../../../../services/category.service';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-create-category',
  templateUrl: './create-category.component.html',
  styleUrls: ['./create-category.component.css']
})
export class CreateCategoryComponent implements OnInit {

  wrongLogin = false;
  loginSubmited = false;

  createForm = new FormGroup({
    name: new FormControl('', Validators.required)
  });

  constructor(private service: CategoryService, private router: Router) { }

  ngOnInit() {}

  onSubmit(): void {
    this.service.createCategory(this.createForm.value).subscribe(
      (response: any) => {
        this.router.navigateByUrl('/admin/categories');
      },
      () => {
        this.setErrorProperties();
      }
    );
  }

  setErrorProperties(): void {
    this.wrongLogin = true;
    this.loginSubmited = false;
  }

}
