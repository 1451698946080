import { PublicOrganization } from './../shared/models/public-organization/public-organization';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PublicOrganizationService {

  publicOrganizationsUrl = environment.apiUrl + '/publicOrganizations';

  constructor(private http: HttpClient) { }

  getPublicOrganizations(): Observable<PublicOrganization[]> {
    return this.http.get<PublicOrganization[]>(this.publicOrganizationsUrl + '/get');
  }

  getPublicOrganization(publicOrganizationId: string) {
    return this.http.get<PublicOrganization>(this.publicOrganizationsUrl + '/get/' + publicOrganizationId);
  }

  createPublicOrganization(publicOrganization: PublicOrganization) {
    return this.http.post(this.publicOrganizationsUrl + '/create', publicOrganization);
  }

  editPublicOrganization(publicOrganizationId: string, publicOrganization: PublicOrganization) {
    return this.http.post(this.publicOrganizationsUrl + '/edit/' + publicOrganizationId, publicOrganization);
  }

  checkPublicOrganizationUsername(username: string) {
    return this.http.post(this.publicOrganizationsUrl + '/checkUsername', {
      username
    });
  }

  flipPublicOrganizationActive(publicOrganizationId: string) {
    return this.http.post(this.publicOrganizationsUrl + '/flipActive/' +  publicOrganizationId, null);
  }
}
