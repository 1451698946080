<app-loader *ngIf="loadingData"></app-loader>

<mat-card *ngIf="!loadingData">
  <mat-card-title>Nova objava</mat-card-title>
  <mat-card-content>
    <form [formGroup]="createForm" autocomplete="off" (submit)="onSubmit()">
      <mat-form-field appearance="outline">
        <mat-label>Naslov objave</mat-label>
        <input matInput formControlName="title" />
        <mat-error>Naslov objave je obavezan podatak.</mat-error>
      </mat-form-field>

      <mat-label>Sadržaj objave</mat-label>
      <ckeditor [editor]="Editor" [config]="editorConfig.getConfig()" formControlName="content"
        (ready)="onReady($event)"></ckeditor>

      <p align="end">
        <button type="submit" mat-raised-button color="primary" [disabled]="createForm.invalid">
          Spremi novu objavu
        </button>
      </p>
    </form>
  </mat-card-content>
</mat-card>