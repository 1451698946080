<mat-tab-group>
  <mat-tab label="Tvrtka">
    <app-loader *ngIf="loadingData"></app-loader>
    <mat-card *ngIf="!loadingData">
      <mat-card-title>{{ editFormTitle }}</mat-card-title>
      <mat-card-content>
        <div class="container-fluid">
          <div class="row">
            <img class="img img-fluid entity-image" src="{{ company.logo || 'assets/logo/image-default.png' }}"
              alt="Slika tvrtke" />
            <app-file-uploader [entityType]="entityType.Company" [entityId]="company.id">
            </app-file-uploader>
          </div>
        </div>
        <form [formGroup]="editForm" autocomplete="off" (submit)="onSubmit()">
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Naziv tvrtke</mat-label>
            <input matInput formControlName="name" />
            <mat-error>Naziv tvrtke je obavezan podatak.</mat-error>
          </mat-form-field>
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Kratko o tvrtci i opis djelatnosti (5-6 rečenica)</mat-label>
            <textarea rows="10" cols="40" matInput formControlName="description" maxLength="2000"></textarea>
        </mat-form-field>
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Adresa</mat-label>
            <input matInput formControlName="address" />
            <mat-error>Adresa tvrtke je obavezan podatak.</mat-error>
          </mat-form-field>
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>OIB</mat-label>
            <input matInput formControlName="taxCode" />
            <mat-error *ngIf="editForm.get('taxCode').hasError('required')">OIB je obavezan podatak.</mat-error>
            <mat-error>OIB nije ispravan.</mat-error>
          </mat-form-field>
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Web stranica</mat-label>
            <input matInput formControlName="website" />
            <mat-error>Web stranica mora počinjati s http:// ili https://</mat-error>
          </mat-form-field>

          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Broj zaposlenih</mat-label>
            <input matInput formControlName="numberOfEmployees" />
          </mat-form-field>

          <app-category-picker [title]="categoryPickerTitle" [parentForm]="editForm" [newsLettersEnabled]="true"
            [categoriesEnabled]="true"></app-category-picker>

          <app-tag-picker [title]="tagPickerTitle" [parentForm]="editForm"></app-tag-picker>

          <p align="end">
            <button type="submit" mat-raised-button color="primary" [disabled]="editForm.invalid">
              Spremi promjene
            </button>
          </p>
        </form>
      </mat-card-content>
    </mat-card>
  </mat-tab>
  <mat-tab label="Promjena lozinke">
    <app-change-password [entityId]="companyId" [entityType]="entityType.Company"></app-change-password>
  </mat-tab>
  <mat-tab label="Razvojni prijedlozi">
    <app-research-problems [entityType]="entityType.Company" [entityId]="companyId"></app-research-problems>
  </mat-tab>
  <mat-tab label="Potrebe za ljudskim resursima">
    <app-job-offers [companyId]="companyId"></app-job-offers>
  </mat-tab>
</mat-tab-group>