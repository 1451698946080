
<h3 class="mt-4" *ngIf="!loadingData">Popis potreba za ljudskim resursima</h3>

<div align="end" *ngIf="!loadingData">
    <mat-form-field appearance="outline">
        <mat-label>Pretraga potreba za ljudskim resursima</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="npr. inžinjer elektrotehnike" #input />
    </mat-form-field>
    
    <button class="mb-3" color="primary" (click)="openCreateJobOfferDialog()" mat-raised-button>
        Dodaj novu potrebu za ljudskim resursima
    </button>
    
</div>

<app-loader *ngIf="loadingData"></app-loader>

<div [hidden]="loadingData">
    <table mat-table class="full-width" matSort [dataSource]="dataSource">

        <ng-container matColumnDef="edit-buttons">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
                <button mat-icon-button color="primary" (click)="openEditDialog(element.id)">
                    <mat-icon color="primary">create</mat-icon>
                </button>
            </td>
        </ng-container>

        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Naziv</th>
            <td mat-cell *matCellDef="let element">{{ element.name }}</td>
        </ng-container>

        <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Opis</th>
            <td mat-cell *matCellDef="let element">{{ element.description }}</td>
        </ng-container>

        <ng-container matColumnDef="location">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Lokacija</th>
            <td mat-cell *matCellDef="let element">{{ element.location }}</td>
        </ng-container>

        <ng-container matColumnDef="deadline">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Rok za prijavu</th>
            <td mat-cell *matCellDef="let element">{{ element.deadline | date: 'dd.MM.yyyy. HH:mm:ss' }}</td>
        </ng-container>

        <ng-container matColumnDef="delete-buttons">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
              <button *ngIf="admin" mat-icon-button color="primary" (click)="openVisibilityDialog(element.id)">
                <mat-icon *ngIf="element.isActive" color="primary">visibility</mat-icon>
                <mat-icon *ngIf="!element.isActive" color="primary">visibility_off</mat-icon>
              </button>
              <button mat-icon-button color="primary" (click)="openDeleteDialog(element.id)">
                <mat-icon color="primary"></mat-icon>
            </button>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

    </table>

    <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
</div>