import { trigger, transition, useAnimation } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { FadeAnimation } from 'src/app/shared/animations/fade-animation';

@Component({
  selector: 'app-already-confirmed',
  templateUrl: './already-confirmed.component.html',
  styleUrls: ['./already-confirmed.component.css'],
  animations: [
    trigger('fadeAnimation', [
      transition('void => *', [useAnimation(FadeAnimation)]),
    ]),
  ],
})
export class AlreadyConfirmedComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
