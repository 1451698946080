<header class="masthead">
  <div class="container h-100">
    <div class="h-100">
      <div class="offset-md-1 col-lg-5 rimap-heading">
        <h1>Regional innovation matchmaking platform</h1>
        <p class="lead mt-4"> Regionalna digitalna inovacijska platforma za
          prijenos znanja između akademske zajednice i gospodarstva s ciljem razvoja inovacijskog ekosustava u regiji.
        </p>
        <p class="lead mt-4"> Pozivamo Vas da se registrirate, unesete Vaše
          razvojne prijedloge/potrebe za ljudskim resursima/kompetencije/istraživačku opremu te pretražite profile
          ostalih korisnika i kontaktirate ih o mogućoj suradnji klikom na
          <strong>"Kontaktiraj"</strong>.
        </p>
        <button mat-raised-button="" color="primary" type="button"
          class="homepage-buttons mat-raised-button mat-button-base mat-primary" (click)="goToRegistration()">
          Pridruži se
        </button>
      </div>
    </div>
  </div>
</header>
<section class="call-to-action pretraga text-center">
  <div class="overlay"></div>
  <div class="container">
    <div class="row newsletter-container">
      <div class="col-xl-9 mx-auto">
        <h2 class="mb-4 call-to-action-title">Pretražite registrirane subjekte</h2>
      </div>
      <div class="col-md-10 col-lg-8 col-xl-7 mx-auto">
        <button mat-raised-button="" color="primary" type="button"
          class="homepage-buttons mat-raised-button mat-button-base mat-primary" (click)="goToMainSearch()">
          Pretraga
        </button>
      </div>
    </div>
  </div>
</section>

<app-latest></app-latest>