import { trigger, transition, useAnimation } from '@angular/animations';
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { FadeAnimation } from 'src/app/shared/animations/fade-animation';

export interface SnackbarData{
  text: string;
  iconType: string;
} 

@Component({
  selector: 'app-notification-message',
  templateUrl: './notification-message.component.html',
  styleUrls: ['./notification-message.component.css'],
  animations: [
    trigger('fadeAnimation', [
      transition('void => *', [useAnimation(FadeAnimation)]),
    ]),
  ],
})

export class NotificationMessageComponent implements OnInit {

  snackBarText: string;
  snackBarIcon: string;

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: SnackbarData,
              public snackBarRef: MatSnackBarRef<NotificationMessageComponent>) { }

  ngOnInit(): void {
    this.snackBarText = this.data.text;
    this.snackBarIcon = this.data.iconType;
  }

  onDismiss(): void{
    this.snackBarRef.dismiss();
  }

}
