<app-loader *ngIf="loadingData"></app-loader>

  <h1 mat-dialog-title *ngIf="!loadingData">Izmjena usluge i kompetencije</h1>
  <mat-dialog-content *ngIf="!loadingData">
    <form
      [formGroup]="editForm"
      autocomplete="off"
      (submit)="onSubmit()"
    >

    <div class="row centered-container">
      <img class="img img-fluid entity-image"
        src="{{ specificKnowledge.image || 'assets/logo/image-default.png' }}"
        alt="Slika usluge i/ili kompetencije" />
    </div>

    <app-file-uploader [entityType]="entityTypeEnum.SpecificKnowledge" [entityId]="specificKnowledge.id"
      [parentEntityId]="scientistId" [parentEntityType]="entityTypeEnum.Scientist">
    </app-file-uploader>

    <mat-form-field class="full-width" appearance="outline">
      <mat-label>Naziv usluge i kompetencije istraživača</mat-label>
      <input matInput formControlName="title" />
      <mat-error>Naziv usluge i kompetencije istraživača je obavezan podatak.</mat-error>
    </mat-form-field>
    
    <mat-form-field class="full-width" appearance="outline">
        <mat-label>Opis usluge i kompetencije istraživača</mat-label>
        <textarea rows="10" cols="40" matInput formControlName="description"></textarea>
        <mat-error>Opis usluge i kompetencije istraživača je obavezan podatak.</mat-error>
      </mat-form-field>

      <app-category-picker [categoriesEnabled]="true" [parentForm]="editForm" [newsLettersEnabled]="false"></app-category-picker>

      <div align="end" mat-dialog-actions align="end">
        <button type="button" mat-raised-button color="warn" (click)="onNoClick()">Odustani</button>&nbsp;
        <button
          type="submit"
          mat-raised-button
          color="primary"
          [mat-dialog-close]="true" cdkFocusInitial
          [disabled]="editForm.invalid"
        >
          Spremi promjene
        </button>
      </div>
    </form>
  </mat-dialog-content>