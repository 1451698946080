import { NotificationMessages } from 'src/app/shared/constants/notification-messages';
import { NotificationService } from './../../services/notification.service';
import { EntityType } from '../../shared/enums/entity-type';
import { AuthService } from './../../services/auth.service';
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { PasswordResetComponent } from '../password-reset/password-reset.component';
import { trigger, transition, useAnimation } from '@angular/animations';
import { FadeAnimation } from 'src/app/shared/animations/fade-animation';
import { environment } from 'src/environments/environment';
import { Title } from '@angular/platform-browser';

export interface LoginType{
  name: string;
  value: EntityType;
}

export const LOGIN_TYPES: LoginType[] = [
  { name: 'Tvrtka', value: EntityType.Company},
  { name: 'Istraživač', value: EntityType.Scientist},
  { name: 'Javna organizacija', value: EntityType.PublicOrganization}
];

@Component({
  selector: 'app-user-login',
  templateUrl: './user-login.component.html',
  styleUrls: ['./user-login.component.css'],
  animations: [
    trigger('fadeAnimation', [
      transition('void => *', [useAnimation(FadeAnimation)]),
    ]),
  ],
})

export class UserLoginComponent implements OnInit {
  loginUserForm: FormGroup;
  authType: EntityType;
  loginTypes: LoginType[] = LOGIN_TYPES;

  constructor(
    private dialog: MatDialog,
    private fb: FormBuilder,
    private router: Router,
    private authService: AuthService,
    private notificationService: NotificationService,
    private titleService: Title) {
      this.titleService.setTitle('Prijava | RIMAP');

  }

  ngOnInit() {
    this.setLoginForm();
    this.authType = EntityType.Company;
  }

  setLoginForm(): void {
    this.loginUserForm = this.fb.group({
      username: new FormControl('', Validators.required),
      password: new FormControl('', Validators.required)
    });
  }

  openPasswordReset(): void {
    const dialogRef = this.dialog.open(PasswordResetComponent);
    dialogRef.componentInstance.entityType = this.authType;
  }

  goToRegistration(): void {
    this.router.navigateByUrl('/registration');
  }

  onSubmit(): void {
    this.authService
      .loginUser(this.loginUserForm.value, this.authType)
      .subscribe((response: any) => {
        localStorage.setItem('token', response.token);
        localStorage.setItem('version', environment.appVersion.toString());
        this.router.navigateByUrl('/my-profile');
        this.authService.userSuccessFullyLogged();
        this.notificationService.successMessage(NotificationMessages.LOGIN_SUCCESS);
      },
        (error) => {
          this.notificationService.errorMessage(NotificationMessages.LOGIN_ERROR);
        }
      );
  }
}
