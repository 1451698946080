import { NotificationMessages } from 'src/app/shared/constants/notification-messages';
import { NotificationService } from './../../services/notification.service';
import { EntityType } from './../../shared/enums/entity-type';
import { CompanyService } from './../../services/company.service';
import { PublicOrganizationService } from './../../services/public-organization.service';
import { ScientistService } from './../../services/scientist.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Component, Input, OnInit } from '@angular/core';
import { passwordValidator } from 'src/app/shared/custom-validators/password-validator';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css'],
})
export class ChangePasswordComponent implements OnInit {
  loadingData: boolean;
  changePasswordForm: FormGroup;

  @Input() entityId: string;
  @Input() entityType: EntityType;

  constructor(
    private fb: FormBuilder,
    private notificationService: NotificationService,
    private scientistService: ScientistService,
    private publicOrganizationService: PublicOrganizationService,
    private companyService: CompanyService
  ) {}

  ngOnInit(): void {
    this.loadingData = true;
    this.setChangePasswordForm();
  }

  setChangePasswordForm(): void {
    this.changePasswordForm = this.fb.group(
      {
        password: new FormControl('', passwordValidator),
        confirmPassword: new FormControl('', passwordValidator),
      },
      {
        validator: this.passwordMatchValidor,
      }
    );

    this.loadingData = false;
  }

  passwordMatchValidor(formGroup: FormGroup) {
    return formGroup.get('password').value ===
      formGroup.get('confirmPassword').value
      ? null
      : { mismatch: true };
  }

  onSubmit(): void {
    this.loadingData = true;

    switch (this.entityType) {
      case EntityType.PublicOrganization:
        this.changePublicOrganizationPassword();
        break;
      case EntityType.Company:
        this.changeCompanyPassword();
        break;
      case EntityType.Scientist:
        this.changeScientistPassword();
        break;
      default:
        break;
    }
  }

  changeScientistPassword(): void {
    this.scientistService
      .editScientist(this.entityId, this.changePasswordForm.value)
      .subscribe(
        (response: any) => {
          this.notificationService.successMessage(
            NotificationMessages.PASSWORD_CHANGE_SUCCESS
          );
        },
        (error) => {
          this.notificationService.errorMessage(
            NotificationMessages.PASSWORD_CHANGE_ERROR
          );
        }
      );
    this.loadingData = false;
  }

  changePublicOrganizationPassword(): void {
    this.publicOrganizationService
      .editPublicOrganization(this.entityId, this.changePasswordForm.value)
      .subscribe(
        (response: any) => {
          this.notificationService.successMessage(
            NotificationMessages.PASSWORD_CHANGE_SUCCESS
          );
        },
        (error) => {
          this.notificationService.errorMessage(
            NotificationMessages.PASSWORD_CHANGE_ERROR
          );
        }
      );
    this.loadingData = false;
  }

  changeCompanyPassword(): void {
    this.companyService
      .editCompany(this.entityId, this.changePasswordForm.value)
      .subscribe(
        (response: any) => {
          this.notificationService.successMessage(
            NotificationMessages.PASSWORD_CHANGE_SUCCESS
          );
        },
        (error) => {
          this.notificationService.errorMessage(
            NotificationMessages.PASSWORD_CHANGE_ERROR
          );
        }
      );
    this.loadingData = false;
  }
}
