import { CategoryService } from './../../services/category.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Scientist } from 'src/app/shared/models/scientist/scientist';
import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatTableDataSource, MatPaginator } from '@angular/material';
import { Observable, Subscription } from 'rxjs';
import { PublicService } from 'src/app/services/public.service';
import { trigger, transition, useAnimation } from '@angular/animations';
import { FadeAnimation } from 'src/app/shared/animations/fade-animation';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-active-scientists',
  templateUrl: './active-scientists.component.html',
  styleUrls: ['./active-scientists.component.css'],
  animations: [
    trigger('fadeAnimation', [
      transition('void => *', [useAnimation(FadeAnimation)]),
    ]),
  ],
})
export class ActiveScientistsComponent implements OnInit, OnDestroy {
  searchForm: FormGroup;
  subscription: Subscription;
  scientists: Observable<Scientist[]>;
  scientistsDataSource: MatTableDataSource<Scientist>;

  @ViewChild('scientistsPaginator') scientistsPaginator: MatPaginator;
  loadingData: boolean;

  constructor(
    private publicService: PublicService,
    private categoryService: CategoryService,
    private router: Router,
    private fb: FormBuilder,
    private changeDetectorRef: ChangeDetectorRef,
    private titleService: Title
  ) {
    this.titleService.setTitle('Istraživači | RIMAP');
    this.setSearchForm();
  }

  ngOnInit(): void {
    this.loadingData = true;

    this.subscription = this.categoryService
      .listenSelectedCategories()
      .subscribe(() => {
        this.loadingData = true;
        this.getScientists();
      });

    this.getScientists();
  }

  initPaginator() {
    this.scientistsPaginator._intl.itemsPerPageLabel = 'Rezultata po stranici';
    this.scientistsPaginator._intl.nextPageLabel = 'Slijedeća stranica';
    this.scientistsPaginator._intl.previousPageLabel = 'Prethodna stranica';
    this.scientistsPaginator._intl.getRangeLabel = (
      page: number,
      pageSize: number,
      length: number
    ) => {
      const start = page * pageSize + 1;
      const end = (page + 1) * pageSize;
      return `${start} - ${end} od ${this.scientistsPaginator.length}`;
    };
  }

  setSearchForm() {
    this.searchForm = this.fb.group({
      categories: new FormControl([]),
    });
  }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.scientistsDataSource.filter = filterValue.trim().toLowerCase();
  }

  openScientist(scientistId: string): void {
    this.router.navigateByUrl('/scientists/details/' + scientistId);
  }

  getScientists() {
    this.publicService
      .getScientists(this.searchForm.value)
      .subscribe((response: Scientist[]) => {
        this.scientistsDataSource = new MatTableDataSource<Scientist>(response);
        this.scientists = this.scientistsDataSource.connect();
        this.loadingData = false;
        this.changeDetectorRef.detectChanges();
        this.scientistsDataSource.paginator = this.scientistsPaginator;
        this.initPaginator();
      });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
