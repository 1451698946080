<header class="masthead header-scientists">
    <div class="container-fluid h-100">
        <div class="row h-100 align-items-center">
            <div class="container text-center">
                <h1 *ngIf="!loadingData" class="page-header-title">
                    {{scientist.firstname}} {{scientist.lastname}}
                </h1>
            </div>
        </div>
    </div>
</header>

<div class="container">
    <div [@fadeAnimation] class="container">
        <div class="mainflip flip-0">
            <div class="frontside">
                <div class="card">
                    <app-loader *ngIf="loadingData"></app-loader>
                    <div *ngIf="!loadingData" class="card-body">
                        <p class="text-center"><img class=" img-fluid entity-profile-picture"
                                [src]="scientist.profilePicture || 'assets/logo/image-default.png'" alt="card image">
                        </p>
                        <div class="row buttons-container mt-3 mb-3 text-center">
                            <button type="button" mat-raised-button color="primary"
                                (click)="openSendMessage()">Kontaktiraj znanstvenika
                            </button>
                        </div>
                        <div class="card-text">
                            <p class="titula" *ngIf="scientist.title !== ''">
                                <strong>Titula:</strong>
                                {{scientist.title}}
                            </p>
                            <p class="adresa" *ngIf="scientist.employmentCollege !== ''">
                                <strong>Zaposlenje:</strong>
                                {{scientist.employmentCollege}}
                            </p>
                            <p class="web" *ngIf="scientist.website !== ''">
                                <strong>Web stranica: </strong>
                                <a [href]="scientist.website" target="_blank">{{scientist.website}}</a>
                            </p>

                            <p *ngIf="scientist.categories.length > 0">
                                <mat-chip-list>
                                    <strong>Primjene istraživanja:</strong>
                                    <mat-chip *ngFor="let category of scientist.categories">
                                        {{category.name}}
                                    </mat-chip>
                                </mat-chip-list>
                            </p>
                            <p *ngIf="scientist.categoryTags.length > 0">
                                <mat-chip-list>
                                    <strong>Istraživački interesi:</strong>
                                    <mat-chip *ngFor="let tag of scientist.categoryTags?.split(';').slice(0, -1)">
                                        {{tag}}
                                    </mat-chip>
                                </mat-chip-list>
                            </p>
                            <p *ngIf="scientist.description !== null">
                                <strong>Kratki životopis i opis znanstvenog rada: </strong>
                                {{scientist.description}}
                            </p>

                            <div class="mt-3">
                                <h2 *ngIf="scientist.equipment.length > 0">
                                    <strong>Oprema</strong>
                                </h2>
                                <div class="sub-entity-details-container mt-3"
                                    *ngFor="let equipment of scientist.equipment">
                                    <h3 class="mt-3 mb-3 sub-entity-main-title">
                                        <strong>{{equipment.title}}</strong>
                                    </h3>
                                    <img [src]="equipment.image || 'assets/logo/image-default.png'"
                                        alt={{equipment.title}} />

                                    <div class="card-text">
                                        <p>
                                            {{equipment.description}}
                                        </p>
                                    </div>
                                    <hr />

                                </div>
                            </div>

                            <div class="mt-3">
                                <h2 *ngIf="scientist.specificKnowledge.length > 0">
                                    <strong>Usluge i kompetencije</strong>
                                </h2>
                                <div class="sub-entity-details-container mt-3"
                                    *ngFor="let specificKnowledge of scientist.specificKnowledge">
                                    <h3 class="mt-3 mb-3 sub-entity-main-title">
                                        <strong>{{specificKnowledge.title}}</strong>
                                    </h3>
                                    <img [src]="specificKnowledge.image || 'assets/logo/image-default.png'"
                                        alt={{specificKnowledge.title}} />

                                    <div class="card-text">
                                        <p>
                                            {{specificKnowledge.description}}
                                        </p>
                                    </div>
                                    <hr />

                                </div>
                            </div>
                        </div>
                        <hr>
                        <div class="row buttons-container mt-3 text-center">
                            <button type="button" mat-raised-button color="primary"
                                (click)="openSendMessage()">Kontaktiraj znanstvenika
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>