import { CategoryService } from './../../../../services/category.service';

import { AfterViewInit, Component, ViewChild, OnInit } from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { Router } from '@angular/router';
import { Category } from 'src/app/shared/models/category/category';
import { MatDialog } from '@angular/material';
import { DeleteDialogComponent } from 'src/app/components/delete-dialog/delete-dialog.component';

const COLUMNS: string[] = [
  'edit-buttons',
  'name',
  'delete-buttons'
];

/**
 * @title Table with filtering
 */
@Component({
  selector: 'app-categories-list',
  templateUrl: './categories-list.component.html',
  styleUrls: ['./categories-list.component.css']
})
export class CategoriesListComponent implements OnInit {

  loadingData: boolean;
  displayedColumns: string[] = COLUMNS;
  categories: Category[];
  dataSource: MatTableDataSource<Category[]>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private router: Router,
    private service: CategoryService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.loadingData = true;
    this.getCategories();
  }

  initPaginator() {
    this.paginator._intl.itemsPerPageLabel = 'Rezultata po stranici';
    this.paginator._intl.nextPageLabel = 'Slijedeća stranica';
        this.paginator._intl.previousPageLabel = 'Prethodna stranica';
    this.paginator._intl.getRangeLabel = (
      page: number,
      pageSize: number,
      length: number
    ) => {
      const start = page * pageSize + 1;
      const end = (page + 1) * pageSize;
      return `${start} - ${end} od ${this.paginator.length}`;
    };
  }


  getCategories(): void {
    this.service.getCategories().subscribe((categories: any) => {
      this.dataSource = new MatTableDataSource<Category[]>(categories);
      this.loadingData = false;
      this.dataSource.paginator = this.paginator;
      this.initPaginator();
      this.dataSource.sort = this.sort;
    });
  }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  
  goToCreatePage(): void {
    this.router.navigateByUrl('/admin/categories/create');
  }

  goToEditPage(categoryId: string): void {
    this.router.navigateByUrl('/admin/categories/edit/' + categoryId);
  }

  openDeleteDialog(categoryId: string): void {
    const dialogRef = this.dialog.open(DeleteDialogComponent);

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.loadingData = true;
        this.deleteCategory(categoryId);
      }
    });
  }

  deleteCategory(categoryId: string): void{
    this.service.deleteCategory(categoryId).subscribe(
      (() => {
        this.getCategories();
      })
    );
  }
}