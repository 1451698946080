
  <div align="end">
    <mat-form-field appearance="outline">
        <mat-label>Pretraga istraživača</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="npr. Ivan Horvat" #input />
    </mat-form-field>
  </div>
  
  <app-loader *ngIf="loadingData"></app-loader>
  
  <div class="mat-elevation-z8" [hidden]="loadingData">
    <table mat-table class="full-width" matSort [dataSource]="dataSource">
  
        <ng-container matColumnDef="edit-buttons">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
                <button mat-icon-button color="primary" (click)="goToEditPage(element.id)">
                    <mat-icon color="primary">create</mat-icon>
                </button>
            </td>
        </ng-container>
  
        <ng-container matColumnDef="firstname">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Ime</th>
            <td mat-cell *matCellDef="let element">{{ element.firstname }}</td>
        </ng-container>
  
        <ng-container matColumnDef="lastname">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Prezime</th>
          <td mat-cell *matCellDef="let element">{{ element.lastname }}</td>
      </ng-container>
  
      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
        <td mat-cell *matCellDef="let element">{{ element.email }}</td>
    </ng-container>

    <ng-container matColumnDef="numberOfViews">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Broj pregleda</th>
        <td mat-cell *matCellDef="let element">{{ element.numberOfViews }}</td>
    </ng-container>
  
        <ng-container matColumnDef="delete-buttons">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
                <button mat-icon-button color="primary" (click)="openVisibilityDialog(element.id)">
                    <mat-icon *ngIf="element.isActive" color="primary">visibility</mat-icon>
                    <mat-icon *ngIf="!element.isActive" color="primary">visibility_off</mat-icon>
                </button>
            </td>
        </ng-container>
  
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  
        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="4">
                Nema podataka koji odgovaraju "{{ input.value }}"
            </td>
        </tr>
    </table>
  
    <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
  </div>