import { PublicService } from 'src/app/services/public.service';
import { Component, OnInit, AfterViewInit, AfterContentInit, OnChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NotificationService } from 'src/app/services/notification.service';
import { PublicOrganizationService } from 'src/app/services/public-organization.service';
import { NotificationMessages } from 'src/app/shared/constants/notification-messages';
import { News } from 'src/app/shared/models/news/news';
import { PublicOrganization } from 'src/app/shared/models/public-organization/public-organization';
import { trigger, transition, useAnimation } from '@angular/animations';
import { FadeAnimation } from 'src/app/shared/animations/fade-animation';
import { DomSanitizer } from '@angular/platform-browser';
import { iframely } from '@iframely/embed.js';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-news-details',
  templateUrl: './news-details.component.html',
  styleUrls: ['./news-details.component.css'],
  animations: [
    trigger('fadeAnimation', [
      transition('void => *', [useAnimation(FadeAnimation)]),
    ]),
  ],
})
export class NewsDetailsComponent implements OnInit {
  newsId: string;
  news: News;

  loadingData: boolean;

  constructor(
    private route: ActivatedRoute,
    private notificationService: NotificationService,
    private publicService: PublicService,
    private titleService: Title) {
      iframely.load();
  }

  ngOnInit(): void {
    this.loadingData = true;
    this.getPublicOrganization();
  }

  getPublicOrganization() {
    this.newsId = this.route.snapshot.paramMap.get('id');

    this.publicService.getSingleNews(this.newsId).subscribe(
      (news: News) => {
        this.news = news as News;
        this.titleService.setTitle(this.news.title + ' | RIMAP');
        this.loadingData = false;
      },
      (error) => {
        this.notificationService.errorMessage(NotificationMessages.GET_ERROR);
      }
    );
  }
}
