<section fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px">
  <mat-card>
    <mat-error *ngIf="wrongLogin" align="center">
      Neispravno korisničko ime ili lozinka. Molimo pokušajte ponovno.
    </mat-error>
    <mat-card-content>
      <form
        #loginForm="ngForm"
        autocomplete="off"
        (submit)="onSubmit(loginForm)"
      >
        <h2 class="login-title">Prijava u administraciju B2B platforme</h2>
        <mat-form-field class="full-width-input">
          <input
            matInput
            placeholder="Username"
            name="username"
            #username="ngModel"
            [(ngModel)]="loginModel.username"
            required
          />
          <mat-error> Korisničko ime je obavezan podatak. </mat-error>
        </mat-form-field>
        <mat-form-field class="full-width-input">
          <input
            matInput
            type="Password"
            name="password"
            #password="ngModel"
            [(ngModel)]="loginModel.password"
            placeholder="Password"
            required
          />
          <mat-error> Lozinka je obavezan podatak. </mat-error>
        </mat-form-field>
        <div class="buttons-container" align="center">
          <p>
            <button mat-raised-button type="submit" color="primary" [disabled]="loginForm.invalid" class="btn-block">
              Prijavi se!
            </button>&nbsp;
          </p>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</section>
