import { NotificationService } from './../../services/notification.service';
import { SendMessageRequest } from './../../models/send-message-request';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { CommunicationService } from './../../services/communication.service';
import { MatDialogRef } from '@angular/material/dialog';
import { Component, Input, OnInit } from '@angular/core';
import { NotificationMessages } from 'src/app/shared/constants/notification-messages';

@Component({
  selector: 'app-send-message',
  templateUrl: './send-message.component.html',
  styleUrls: ['./send-message.component.css'],
})
export class SendMessageComponent implements OnInit {
  @Input() receiverId: string;
  @Input() receiverType: string;

  sendMessageForm: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<SendMessageComponent>,
    private communicationService: CommunicationService,
    private fb: FormBuilder,
    private notificationService: NotificationService
  ) {}

  ngOnInit(): void {
    this.setSendMessageForm();
  }

  setSendMessageForm() {
    this.sendMessageForm = this.fb.group({
      content: new FormControl('', Validators.required),
    });
  }

  onNoClick() {
    this.dialogRef.close();
  }

  onSubmit(): void {

    const request = new SendMessageRequest();
    request.content = this.sendMessageForm.get('content').value;
    request.receiverId = this.receiverId;
    request.receiverUserType = this.receiverType;

    this.communicationService
      .sendMessage(request)
      .subscribe((response: any) => {
        this.notificationService.successMessage(NotificationMessages.MESSAGE_SENT);
        this.dialogRef.close();
      },
        (error) => {
          this.notificationService.errorMessage(NotificationMessages.ERROR_SENDING_MESSAGE);
        }
      );
  }
}
