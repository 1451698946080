import { CategoryService } from 'src/app/services/category.service';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { PublicService } from './../../services/public.service';
import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { MatTableDataSource, MatPaginator } from '@angular/material';
import { Observable, Subscription } from 'rxjs';
import { Company } from 'src/app/shared/models/company/company';
import { ViewChild } from '@angular/core';
import { trigger, transition, useAnimation } from '@angular/animations';
import { FadeAnimation } from 'src/app/shared/animations/fade-animation';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-active-companies',
  templateUrl: './active-companies.component.html',
  styleUrls: ['./active-companies.component.css'],
  animations: [
    trigger('fadeAnimation', [
      transition('void => *', [useAnimation(FadeAnimation)]),
    ]),
  ],
})
export class ActiveCompaniesComponent implements OnInit, OnDestroy {
  companies: Observable<Company[]>;
  companiesDataSource: MatTableDataSource<Company>;

  searchForm: FormGroup;

  subscription: Subscription;

  companiesCount: number;

  @ViewChild('companiesPaginator') companiesPaginator: MatPaginator;

  loadingData: boolean;

  constructor(
    private publicService: PublicService,
    private categoryService: CategoryService,
    private router: Router,
    private changeDetectorRef: ChangeDetectorRef,
    private fb: FormBuilder,
    private titleService: Title
  ) {
    this.titleService.setTitle('Tvrtke | RIMAP');
    this.setSearchForm();
  }

  initPaginator() {
    this.companiesPaginator._intl.itemsPerPageLabel = 'Rezultata po stranici';
    this.companiesPaginator._intl.nextPageLabel = 'Slijedeća stranica';
    this.companiesPaginator._intl.previousPageLabel = 'Prethodna stranica';
    this.companiesPaginator._intl.getRangeLabel = (
      page: number,
      pageSize: number,
      length: number
    ) => {
      const start = page * pageSize + 1;
      const end = (page + 1) * pageSize;
      return `${start} - ${end} od ${this.companiesPaginator.length}`;
    };
  }

  ngOnInit(): void {
    this.loadingData = true;

    this.subscription = this.categoryService
      .listenSelectedCategories()
      .subscribe(() => {
        this.loadingData = true;
        this.getCompanies();
      });

    this.getCompanies();
  }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.companiesDataSource.filter = filterValue.trim().toLowerCase();
  }

  setSearchForm() {
    this.searchForm = this.fb.group({
      categories: new FormControl([]),
    });
  }

  openCompany(companyId: string): void {
    this.router.navigateByUrl('/companies/details/' + companyId);
  }

  getCompanies() {
    this.publicService
      .getCompanies(this.searchForm.value)
      .subscribe((response: Company[]) => {
        this.companiesDataSource = new MatTableDataSource<Company>(response);
        this.companies = this.companiesDataSource.connect();
        this.loadingData = false;
        this.changeDetectorRef.detectChanges();
        this.companiesDataSource.paginator = this.companiesPaginator;
        this.initPaginator();
      });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
