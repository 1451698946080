import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';
import { Place } from '../shared/models/place/place';

@Injectable({
  providedIn: 'root'
})
export class PlaceService {

  placesUrl = environment.apiUrl + '/places';

  constructor(private http: HttpClient) { }

  getPlaces(): Observable<Place[]> {
    return this.http.get<Place[]>(this.placesUrl + '/get');
  }

  getPlaceByZipCode(zipCode: string): Observable<Place> {
    return this.http.get<Place>(this.placesUrl + '/getByZip/' + zipCode);
  }
}
