import { NotificationService } from 'src/app/services/notification.service';
import { NotificationMessages } from 'src/app/shared/constants/notification-messages';
import { ActivityService } from './../../../services/activity.service';
import { Component, OnInit } from '@angular/core';
import { Activity } from 'src/app/shared/models/activity';

@Component({
  selector: 'app-activities',
  templateUrl: './activities.component.html',
  styleUrls: ['./activities.component.css'],
})
export class ActivitiesComponent implements OnInit {
  loadingData: boolean;
  activity: Activity;

  constructor(
    private activityService: ActivityService,
    private notificationService: NotificationService
  ) {}

  ngOnInit(): void {
    this.loadingData = true;
    this.getActivities();
  }

  getActivities(): void {
    this.activityService.getLastActivities().subscribe(
      (response: Activity) => {
        this.activity = response as Activity;
        this.loadingData = false;
      },
      (error) => {
        this.notificationService.errorMessage(NotificationMessages.GET_ERROR);
        this.loadingData = false;
      }
    );
  }
}
