import { websiteValidator } from './../../../../shared/custom-validators/website-validator';
import { NotificationMessages } from 'src/app/shared/constants/notification-messages';
import { emailValidator } from './../../../../shared/custom-validators/email-validator';
import { passwordValidator } from './../../../../shared/custom-validators/password-validator';
import { NotificationService } from './../../../../services/notification.service';
import { AuthService } from './../../../../services/auth.service';
import { PublicOrganizationService } from './../../../../services/public-organization.service';
import {
  FormControl,
  FormGroup,
  Validators,
  FormBuilder,
} from '@angular/forms';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EntityType } from 'src/app/shared/enums/entity-type';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-create-public-organization',
  templateUrl: './create-public-organization.component.html',
  styleUrls: ['./create-public-organization.component.css'],
})
export class CreatePublicOrganizationComponent implements OnInit {
  @Input() formTitle: string;
  @Input() submitButtonTitle: string;
  @Input() successMessage: string;
  @Input() errorMessage: string;

  createForm: FormGroup;
  loadingData: boolean;
  usernameCheckLoading: boolean;
  uniqueUsername: boolean;

  tagPickerTitle: 'Ključne riječi koje opisuju Vašu djelatnost';
  categoryPickerTitle: 'Kategorije djelatnosti Vaše javne organizacije';

  constructor(
    private publicOrganizationService: PublicOrganizationService,
    private authService: AuthService,
    private notificationService: NotificationService,
    private fb: FormBuilder,
    private router: Router
  ) {}

  public get entityType(): typeof EntityType {
    return EntityType;
  }

  ngOnInit(): void {
    this.initFormFlags();
    this.setCreateForm();
  }

  initFormFlags(): void {
    this.uniqueUsername = false;
    this.usernameCheckLoading = false;
    this.loadingData = true;
  }

  setCreateForm(): void {
    this.createForm = this.fb.group(
      {
        username: new FormControl('', emailValidator),
        name: new FormControl('', Validators.required),
        description : new FormControl(''),
        address: new FormControl('', Validators.required),
        website: new FormControl('', websiteValidator),
        categoryTags: new FormControl(''),
        categories: new FormControl([], Validators.required),
        newsletterCategories: new FormControl([], Validators.required),
        password: new FormControl('', passwordValidator),
        confirmPassword: new FormControl('', Validators.required),
      },
      {
        validator: this.passwordMatchValidor,
      }
    );

    this.loadingData = false;
  }

  passwordMatchValidor(formGroup: FormGroup) {
    const passwordConfirmed =
      formGroup.get('password').value === formGroup.get('confirmPassword').value
        ? null
        : { mismatch: true };

    if (passwordConfirmed !== null) {
      formGroup.get('confirmPassword').setErrors({ mismatch: true });
    }

    return passwordConfirmed;
  }

  triggerUsernameCheck(): void {
    if (this.createForm.get('username').valid) {
      this.checkUsername();
    }

    return;
  }

  checkUsername(): void {
    this.usernameCheckLoading = true;

    const username = this.createForm.get('username').value;

    this.publicOrganizationService
      .checkPublicOrganizationUsername(username)
      .subscribe(
        (response: any) => {
          this.uniqueUsername = true;
        },
        (error) => {
          this.uniqueUsername = false;
          this.createForm.get('username').setErrors({ unique: true });
          this.notificationService.errorMessage(
            NotificationMessages.USERNAME_NOT_AVAILABLE
          );
        }
      );

    this.usernameCheckLoading = false;
  }

  onSubmit(): void {
    this.prepareCategoriesBeforeSubmit();

    this.publicOrganizationService
      .createPublicOrganization(this.createForm.value)
      .subscribe(
        (response: any) => {
          this.router.navigateByUrl('');
          this.notificationService.successMessage(
            NotificationMessages.SUCCESSFULLY_REGISTERED_PUBLIC_ORGANIZATION
          );
        },
        (error) => {
          this.notificationService.errorMessage(this.errorMessage);
        }
      );
  }

  prepareCategoriesBeforeSubmit(): void {
    const realCategories = this.createForm
      .get('categories')
      .value.map((x) => x.id);
    const realnewsCategories = this.createForm
      .get('newsletterCategories')
      .value.map((x) => x.id);
    this.createForm.get('categories').setValue(realCategories);
    this.createForm.get('newsletterCategories').setValue(realnewsCategories);
  }
}
