<app-loader *ngIf="loadingData"></app-loader>

<mat-card *ngIf="!loadingData">
  <mat-card-title>{{ formTitle }}</mat-card-title>
  <p class="text-left mt-2">Sva polja su obavezna.</p>
  <mat-card-content>
    <form [formGroup]="createForm" autocomplete="off" (submit)="onSubmit()">
      <mat-form-field appearance="outline">
        <mat-label>E-mail za korisničko ime</mat-label>
        <input matInput formControlName="username" (keyup)="triggerUsernameCheck()" />
        <mat-error *ngIf="createForm.get('username').hasError('required')">Korisničko ime je obavezan podatak.</mat-error>
        <mat-error *ngIf="createForm.get('username').hasError('email')">Korisničko ime nije ispravno (mora biti email adresa).</mat-error>
        <mat-error *ngIf="!uniqueUsername && this.createForm.get('username').touched">Korisničko ime nije dostupno.</mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Lozinka</mat-label>
        <input matInput formControlName="password" type="password" />
        <mat-error *ngIf="createForm.get('password').hasError('required')">Lozinka je obavezan podatak.</mat-error>
        <mat-error>Lozinka je prekratka (minimalno 8 znakova).</mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Potvrda lozinke</mat-label>
        <input matInput formControlName="confirmPassword" type="password" />
        <mat-error>Potvrda lozinke ne odgovara upisanoj lozinki.</mat-error>
      </mat-form-field>
      <mat-form-field class="full-width" appearance="outline">
        <mat-label>Ime</mat-label>
        <input matInput formControlName="name" />
        <mat-error>Ime je obavezan podatak.</mat-error>
      </mat-form-field>
      <mat-form-field class="full-width" appearance="outline">
        <mat-label>Kratko o javnog organizaciji i opis djelatnosti (5-6 rečenica)</mat-label>
        <textarea rows="10" cols="40" matInput formControlName="description" maxLength="2000"></textarea>
    </mat-form-field>
      <mat-form-field class="full-width" appearance="outline">
        <mat-label>Adresa</mat-label>
        <input matInput formControlName="address" />
        <mat-error>Adresa je obavezan podatak.</mat-error>
      </mat-form-field>
      <mat-form-field class="full-width" appearance="outline">
        <mat-label>Web stranica</mat-label>
        <input matInput formControlName="website" />
        <mat-error>Web stranica mora počinjati s http:// ili https://</mat-error>
      </mat-form-field>

      <app-tag-picker [title]="tagPickerTitle" [parentForm]="createForm"></app-tag-picker>


      <app-category-picker [title]="categoryPickerTitle" [categoriesEnabled]="true" [parentForm]="createForm"
        [newsLettersEnabled]="true"></app-category-picker>

      
      <p align="end">
        <button type="submit" mat-raised-button color="primary" [disabled]="createForm.invalid || !uniqueUsername">
          {{ submitButtonTitle }}
        </button>
      </p>
    </form>
  </mat-card-content>
</mat-card>