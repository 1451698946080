import { EntityType } from 'src/app/shared/enums/entity-type';
import { MatDialogRef } from '@angular/material/dialog';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { EquipmentService } from 'src/app/services/equipment.service';
import { NotificationService } from 'src/app/services/notification.service';
import { NotificationMessages } from 'src/app/shared/constants/notification-messages';
import { Equipment } from 'src/app/shared/models/equipment/equipment';
import { trigger, transition, useAnimation } from '@angular/animations';
import { FadeAnimation } from 'src/app/shared/animations/fade-animation';

@Component({
  selector: 'app-edit-equipment',
  templateUrl: './edit-equipment.component.html',
  styleUrls: ['./edit-equipment.component.css'],
  animations: [
    trigger('fadeAnimation', [
      transition('void => *', [useAnimation(FadeAnimation)]),
    ]),
  ],
})
export class EditEquipmentComponent implements OnInit {

  @Input() scientistId: string;
  @Input() equipmentId: string;

  loadingData: boolean;
  editForm: FormGroup;
  equipment: Equipment;

  entityType = EntityType.Equipment;

  constructor(
    private equipmentService: EquipmentService,
    private notificationService: NotificationService,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<EditEquipmentComponent>
  ) { }

  ngOnInit() {
    this.loadingData = true;
    this.getEquipment();
  }

  getEquipment(): void {
    this.equipmentService.getSingleEquipment(this.scientistId, this.equipmentId).subscribe(
      (response: Equipment) => {
        this.equipment = response as Equipment;
        this.setEditForm();
      },
      (error) => {
        this.notificationService.errorMessage(NotificationMessages.GET_ERROR);
      });
  }

  setEditForm(): void {
    this.editForm = this.fb.group({
      description: new FormControl(this.equipment.description, Validators.required),
      title: new FormControl(this.equipment.title, Validators.required),
      categories: new FormControl(this.equipment.categories),
    });

    this.loadingData = false;
  }

  onNoClick(): void{
    this.dialogRef.close();
  }

  onSubmit(): void {
    this.loadingData = true;

    this.prepareCategoriesBeforeSubmit();

    this.equipmentService
      .editEquipment(this.scientistId, this.equipmentId, this.editForm.value)
      .subscribe(
        (response: any) => {
          this.notificationService.successMessage(
            NotificationMessages.UPDATE_SUCCESS
          );
          this.editForm.reset();
          this.equipmentService.pingNewEquipment();
        },
        (error) => {
          this.notificationService.errorMessage(
            NotificationMessages.UPDATE_ERROR
          );
        }
      );

    this.loadingData = false;
  }

  prepareCategoriesBeforeSubmit(): void {
    const realCategories = this.editForm
      .get('categories')
      .value.map((x) => x.id);
    this.editForm.get('categories').setValue(realCategories);
  }

}
