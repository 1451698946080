import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from '@angular/router';
import jwtDecode from 'jwt-decode';
import { Observable } from 'rxjs';
import { AuthToken } from '../shared/models/auth/auth-token';

@Injectable({
  providedIn: 'root',
})
export class AdminGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    if (localStorage.getItem('token') != null) {
      const tokenStorageValue = localStorage.getItem('token');
      const decodedToken = jwtDecode(tokenStorageValue) as AuthToken;
      const now = Date.now().valueOf() / 1000;

      if (decodedToken.exp < now) {
        this.router.navigateByUrl('/login');
        localStorage.clear();
        return false;
      }

      return true;
    } else {
      this.router.navigateByUrl('/login');
    }
  }
}
