import { NotificationService } from 'src/app/services/notification.service';
import { NotificationMessages } from 'src/app/shared/constants/notification-messages';
import { UserService } from './../../../../services/user.service';
import { User } from './../../../../shared/models/user/user';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material';

const COLUMNS: string[] = ['email'];

@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.css'],
})
export class UsersListComponent implements OnInit {
  loadingData: boolean;
  displayedColumns: string[] = COLUMNS;
  users: User[];
  dataSource: MatTableDataSource<User[]>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private userService: UserService,
    private notificationService: NotificationService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.loadingData = true;
    this.getUsers();
  }

  initPaginator() {
    this.paginator._intl.itemsPerPageLabel = 'Rezultata po stranici';
    this.paginator._intl.nextPageLabel = 'Slijedeća stranica';
        this.paginator._intl.previousPageLabel = 'Prethodna stranica';
    this.paginator._intl.getRangeLabel = (
      page: number,
      pageSize: number,
      length: number
    ) => {
      const start = page * pageSize + 1;
      const end = (page + 1) * pageSize;
      return `${start} - ${end} od ${this.paginator.length}`;
    };
  }

  getUsers(): void {
    this.userService.getUsers().subscribe((users: any) => {
      this.dataSource = new MatTableDataSource<User[]>(users);
      this.loadingData = false;
      this.dataSource.paginator = this.paginator;
      this.initPaginator();
      this.dataSource.sort = this.sort;
    },
      (error) => {
        this.notificationService.errorMessage(NotificationMessages.GET_ERROR);
      }
    );
  }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}
