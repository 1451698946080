
<mat-tab-group>
  <mat-tab label="Istraživač">
    <app-loader *ngIf="loadingData"></app-loader>
    <mat-card *ngIf="!loadingData">
      <mat-card-title>{{ editFormTitle }}</mat-card-title>
      <mat-card-content>
        <div class="container-fluid">
          <div class="row">
            <img class="img img-fluid entity-image" src="{{ scientist.profilePicture || 'assets/logo/image-default.png' }}"
            alt="Slika istraživača" />
            <app-file-uploader [entityType]="entityType.Scientist" [entityId]="scientist.id">
            </app-file-uploader>
          </div>
        </div>
        <form [formGroup]="editForm" autocomplete="off" (submit)="onSubmit()">
          <mat-form-field  appearance="outline">
            <mat-label>Titula</mat-label>
            <input matInput formControlName="title" />
            <mat-error>Titula je obavezan podatak.</mat-error>
          </mat-form-field>
          <mat-form-field  appearance="outline">
            <mat-label>Ime</mat-label>
            <input matInput formControlName="firstname" />
            <mat-error>Ime je obavezan podatak.</mat-error>
          </mat-form-field>
          <mat-form-field  appearance="outline">
            <mat-label>Prezime</mat-label>
            <input matInput formControlName="lastname" />
            <mat-error>Prezime je obavezan podatak.</mat-error>
          </mat-form-field>
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Kratki životopis i opis znanstvenog rada (5-6 rečenica)</mat-label>
            <textarea rows="10" cols="40" matInput formControlName="description" maxLength="2000"></textarea>
        </mat-form-field>
          <mat-form-field  appearance="outline">
            <mat-label>Web stranica</mat-label>
            <input matInput formControlName="website" />
            <mat-error>Web stranica mora počinjati s http:// ili https://</mat-error>
          </mat-form-field>
          <mat-form-field  appearance="outline">
            <mat-label>Naziv fakulteta</mat-label>
            <input matInput formControlName="employmentCollege" />
            <mat-error>Naziv fakulteta je obavezan podatak.</mat-error>
          </mat-form-field>
          <mat-form-field  appearance="outline">
            <mat-label>Google Scholar poveznica</mat-label>
            <input matInput formControlName="googleScholarLink" />
          </mat-form-field>
    
          <app-category-picker [title]="categoryPickerTitle" [categoriesEnabled]="true" [parentForm]="editForm" [newsLettersEnabled]="true"></app-category-picker>

          <app-tag-picker [title]="tagPickerTitle" [parentForm]="editForm"></app-tag-picker>
    
          <p align="end">
            <button
              type="submit"
              mat-raised-button
              color="primary"
              [disabled]="editForm.invalid"
            >
              Spremi promjene
            </button>
          </p>
        </form>
      </mat-card-content>
    </mat-card>
  </mat-tab>
  <mat-tab label="Promjena lozinke">
    <app-change-password [entityId]="scientistId" [entityType]="entityType.Scientist"></app-change-password>
  </mat-tab>
  <mat-tab label="Istraživačka oprema">
    <app-equipment [scientistId]="scientistId"></app-equipment>
  </mat-tab>
  <mat-tab label="Istraživačke usluge i kompetencije">
    <app-specific-knowledges [scientistId]="scientistId"></app-specific-knowledges>
  </mat-tab>
</mat-tab-group>


