import { Router } from '@angular/router';
import { NotificationService } from './../../services/notification.service';
import { NotificationMessages } from './../../shared/constants/notification-messages';
import { EntityType } from '../../shared/enums/entity-type';
import { FileUploaderService } from './../../services/file-uploader.service';
import { Component, Input, OnInit } from '@angular/core'; 

@Component({
  selector: 'app-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.css'],
})
export class FileUploaderComponent implements OnInit {
  @Input() entityType: EntityType;
  @Input() entityId: string;
  @Input() parentEntityType: EntityType;
  @Input() parentEntityId: string;

  progress: number;
  infoMessage: any;
  isUploading = false;
  activeUploadButton: boolean;
  file: File;

  imageUrl: string | ArrayBuffer = '../../assets/logo/image-default.png';
  fileName = 'Nema odabrane datoteke.';

  constructor(
    private uploaderService: FileUploaderService,
    private router: Router,
    private notificationService: NotificationService
  ) {}

  ngOnInit() {
    this.uploaderService.progressSource.subscribe((progress) => {
      this.progress = progress;
    });

    this.activeUploadButton = false;
  }

  onChange(file: File): void {
    if (file) {
      this.fileName = file.name;
      this.file = file;

      if (file.size > 1000000) {
        this.infoMessage = 'Maksimalna dopuštena veličina datoteke je 1MB.';
        return;
      }

      const reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = (event) => {
        this.imageUrl = reader.result;
      };

      this.activeUploadButton = true;
    }
  }

  onUpload() {
    this.infoMessage = null;
    this.progress = 0;
    this.isUploading = true;

    this.uploaderService
      .upload(
        this.file,
        this.entityType,
        this.entityId,
        this.parentEntityType,
        this.parentEntityId
      )
      .subscribe(
        (message) => {
          this.isUploading = false;
          this.progress = 0;
          this.infoMessage = message;
          this.activeUploadButton = false;
          this.notificationService.successMessage(
            NotificationMessages.UPLOAD_SUCCESS
          );
          this.uploaderService.pingImageUploaded();
        },
        (error) => {
          this.isUploading = false;
          this.progress = 0;
          this.activeUploadButton = false;
          this.notificationService.errorMessage(
            NotificationMessages.UPLOAD_ERROR
          );
          this.uploaderService.pingImageUploaded();
        }
      );
  }
}
