import { AuthService } from './../../../../services/auth.service';
import { FileUploaderService } from './../../../../services/file-uploader.service';
import { EntityType } from 'src/app/shared/enums/entity-type';
import { NotificationService } from './../../../../services/notification.service';
import { PublicOrganizationService } from './../../../../services/public-organization.service';
import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import {
  FormBuilder, 
  FormControl,
  FormGroup,
  Validators, 
} from '@angular/forms';
import { PublicOrganization } from 'src/app/shared/models/public-organization/public-organization';
import { ActivatedRoute } from '@angular/router';
import { emailValidator } from 'src/app/shared/custom-validators/email-validator';
import { NotificationMessages } from 'src/app/shared/constants/notification-messages';

@Component({
  selector: 'app-edit-public-organization',
  templateUrl: './edit-public-organization.component.html',
  styleUrls: ['./edit-public-organization.component.css'],
})
export class EditPublicOrganizationComponent implements OnInit {

  @Input() id: string;
  @Input() formTitle: string;

  publicOrganization: PublicOrganization;
  publicOrganizationId: string;
  editFormTitle: string;
  editForm: FormGroup;

  admin: boolean;

  loadingData: boolean;
  passwordConfirmed: boolean;

  tagPickerTitle: 'Ključne riječi koje opisuju Vašu djelatnost';
  categoryPickerTitle: 'Kategorije djelatnosti Vaše javne organizacije';

  constructor(
    private publicOrganizationService: PublicOrganizationService,
    private notificationService: NotificationService,
    private authService: AuthService,
    private uploadService: FileUploaderService,
    private fb: FormBuilder,
    private route: ActivatedRoute
  ) {
    this.listenToImageUpload();
    this.admin = this.authService.isSuperAdminLogged();
  }

  public get entityType(): typeof EntityType {
    return EntityType;
  }

  ngOnInit(): void {
    this.loadingData = true;
    this.setFormControls();
    this.getPublicOrganization();
  }

  listenToImageUpload() {
    this.uploadService.listenImageUpload().subscribe((response: any) => {
      this.getPublicOrganization();
    },
      (error) => {
        this.notificationService.errorMessage(NotificationMessages.SYNC_ERROR);
      }
    );
  }

  setFormControls(): void {
    this.publicOrganizationId = this.id === undefined ? this.route.snapshot.paramMap.get('id') : this.id;
    this.editFormTitle = this.formTitle === undefined ? 'Uređivanje javne organizacije' : this.formTitle;
  }

  getPublicOrganization() {
    this.publicOrganizationService
      .getPublicOrganization(this.publicOrganizationId)
      .subscribe(
        (publicOrganization: PublicOrganization) => {
          this.publicOrganization = publicOrganization as PublicOrganization;
          this.setEditPublicOrganizationForm();
        },
        (error) => {
          this.notificationService.errorMessage(NotificationMessages.GET_ERROR);
        }
      );
  }

  setEditPublicOrganizationForm(): void {
    this.editForm = this.fb.group({
      name: new FormControl(this.publicOrganization.name, Validators.required),
      description : new FormControl(this.publicOrganization.description),
      address: new FormControl(
        this.publicOrganization.address,
        Validators.required
      ),
      website: new FormControl(this.publicOrganization.website),
      email: new FormControl(this.publicOrganization.email, emailValidator),
      categoryTags: new FormControl(
        this.publicOrganization.categoryTags?.split(';').slice(0, -1)
      ), 
      categories: new FormControl(this.publicOrganization.categories),
      newsletterCategories: new FormControl(
        this.publicOrganization.newsletterCategories
      ),
    });

    this.loadingData = false;
  }

  onSubmit(): void {
    this.loadingData = true;
    this.prepareCategoriesBeforeSubmit();

    this.publicOrganizationService
      .editPublicOrganization(this.publicOrganizationId, this.editForm.value)
      .subscribe(
        (publicOrganization: PublicOrganization) => {
          this.publicOrganization = publicOrganization as PublicOrganization;
          this.setEditPublicOrganizationForm();
          this.notificationService.successMessage(
            NotificationMessages.UPDATE_SUCCESS
          );
        },
        (error) => {
          this.notificationService.errorMessage(
            NotificationMessages.UPDATE_ERROR
          );
        }
      );
  }

  prepareCategoriesBeforeSubmit(): void {
    const realCategories = this.editForm.get('categories').value.map((x) => x.id);
    const realnewsCategories = this.editForm
      .get('newsletterCategories')
      .value.map((x) => x.id);
    this.editForm.get('categories').setValue(realCategories);
    this.editForm.get('newsletterCategories').setValue(realnewsCategories);
  }
}
