import { EntityType } from 'src/app/shared/enums/entity-type';
import { AuthUnit } from './../shared/models/auth/auth-unit';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { Observable, Subject } from 'rxjs';
import jwtDecode from 'jwt-decode';
import { AuthToken } from '../shared/models/auth/auth-token';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  authUrl = environment.apiUrl + '/auth';

  loginSubject = new Subject<any>();

  constructor(private http: HttpClient, private router: Router) {}

  loginUser(authUnit: AuthUnit, loginType: EntityType) {
    switch (loginType) {
      case EntityType.Company:
        return this.loginCompany(authUnit);
      case EntityType.PublicOrganization:
        return this.loginPublicOrganization(authUnit);
      case EntityType.Scientist:
        return this.loginScientist(authUnit);
    }
  }

  loginCompany(authCompany: AuthUnit) {
    return this.http.post(this.authUrl + '/login/company', authCompany);
  }

  loginScientist(authScientist: AuthUnit) {
    return this.http.post(this.authUrl + '/login/scientist', authScientist);
  }

  loginPublicOrganization(authPublicOrganization: AuthUnit) {
    return this.http.post(
      this.authUrl + '/login/publicOrganization',
      authPublicOrganization
    );
  }

  loginSuperAdmin(authSuperAdmin: AuthUnit) {
    return this.http.post(this.authUrl + '/login/superadmin', authSuperAdmin);
  }

  userLogged(): boolean {
    const tokenStorageValue = localStorage.getItem('token');

    if (tokenStorageValue === null) {
      return false;
    }

    const decodedToken = jwtDecode(tokenStorageValue) as AuthToken;

    const now = Date.now().valueOf() / 1000;
    if (decodedToken.exp < now) {
      return false;
    }

    if (
      decodedToken.role === 'Scientist' ||
      decodedToken.role === 'PublicOrganization' ||
      decodedToken.role === 'Company'
    ) {
      return true;
    }

    return false;
  }

  logOut(): void {
    localStorage.clear();
    this.router.navigateByUrl('login');
  }

  getLogoutUrl(): string {
    const tokenStorageValue = localStorage.getItem('token');
    const decodedToken = jwtDecode(tokenStorageValue) as AuthToken;
    if (decodedToken.role === 'SuperAdmin') {
      return '';
    } else {
      return '/user-login';
    }
  }

  userSuccessFullyLogged(): void {
    this.loginSubject.next({ success: true });
  }

  getUserLoginStatus(): Observable<any> {
    return this.loginSubject.asObservable();
  }

  resetPassword(username: string, entityType: EntityType) {
    return this.http.post(this.getPasswordResetUrl(entityType), {
      username,
    });
  }

  isSuperAdminLogged(): boolean {
    const tokenStorageValue = localStorage.getItem('token');

    if (tokenStorageValue === null) {
      return false;
    }

    const decodedToken = jwtDecode(tokenStorageValue) as AuthToken;
    const now = Date.now().valueOf() / 1000;
    if (decodedToken.exp < now) {
      return false;
    }
    if (decodedToken.role === 'SuperAdmin') {
      return true;
    }
    return false;
  }

  getPasswordResetUrl(entityType: EntityType) {
    switch (entityType) {
      case EntityType.Company:
        return this.authUrl + '/resetPassword/company';
      case EntityType.PublicOrganization:
        return this.authUrl + '/resetPassword/publicOrganization';
      case EntityType.Scientist:
        return this.authUrl + '/resetPassword/scientist';
    }
  }
}
