import { Router } from '@angular/router';
import { AuthService } from './../../services/auth.service';
import { Component, OnInit } from '@angular/core';
import jwtDecode from 'jwt-decode';
import { AuthToken } from 'src/app/shared/models/auth/auth-token';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css'],
})
export class NavigationComponent implements OnInit {
  userLogged: boolean;

  constructor(private service: AuthService, private router: Router) {}

  ngOnInit() {
    this.checkIfUserLogged();
    this.listenToLoginStatus();
  }

  listenToLoginStatus(): void {
    this.service.getUserLoginStatus().subscribe((response: any) => {
      this.checkIfUserLogged();
    });
  }

  checkIfUserLogged(): void {
    if (localStorage.getItem('token') != null) {
      const tokenStorageValue = localStorage.getItem('token');
      const decodedToken = jwtDecode(tokenStorageValue) as AuthToken;

      if (decodedToken.role === 'SuperAdmin') {
        this.userLogged = false;
      } else {
        this.userLogged = true;
      }
    } else {
      this.userLogged = false;
    }
  }

  logOut(): void {
    this.service.logOut();
    this.checkIfUserLogged();
    this.router.navigateByUrl('');
  }
}
