<h1>Zadnje aktivnosti na platformi</h1>

<app-loader *ngIf="loadingData"></app-loader>

<mat-tab-group *ngIf="!loadingData">
    <mat-tab label="Novo na platformi">
        <h4 *ngIf="activity.createdCompanies.length > 0">Nove tvrtke</h4>
        <ul *ngIf="activity.createdCompanies.length > 0">
            <li *ngFor="let newCompany of activity.createdCompanies">
                <strong>{{newCompany.name}}</strong> - vrijeme kreiranja: {{newCompany.createdAt | date:
                'dd.MM.yyyy.HH:mm'}}
            </li>
        </ul>
        <h4 *ngIf="activity.createdPublicOrganizations.length > 0">Nove javne organizacije</h4>
        <ul *ngIf="activity.createdPublicOrganizations.length > 0">
            <li *ngFor="let newPublicOrganization of activity.createdPublicOrganizations">
                <strong>{{newPublicOrganization.name}}</strong> - vrijeme kreiranja:
                {{newPublicOrganization.createdAt | date: 'dd.MM.yyyy. HH:mm'}}
            </li>
        </ul>
        <h4 *ngIf="activity.createdScientists.length > 0">Novi istraživači</h4>
        <ul *ngIf="activity.createdScientists.length > 0">
            <li *ngFor="let newScientist of activity.createdScientists">
                <strong>{{newScientist.firstname}} {{newScientist.lastname}}</strong> -
                vrijeme kreiranja: {{newScientist.createdAt | date: 'dd.MM.yyyy. HH:mm'}}
            </li>
        </ul>
        <h4 *ngIf="activity.companiesWithCreatedResearchProblems.length > 0">Novi razvojni prijedlozi tvrtki</h4>
        <ul *ngIf="activity.companiesWithCreatedResearchProblems.length > 0">
            <li *ngFor="let company of activity.companiesWithCreatedResearchProblems">
                <h6><strong>Tvrtka: </strong>{{company.name}}</h6>
                <ul>
                    <li *ngFor="let researchProblem of company.researchProblems">
                        {{researchProblem.title}} - vrijeme kreiranja: {{researchProblem.createdAt | date:
                        'dd.MM.yyyy. HH:mm'}}
                    </li>
                </ul>
            </li>
        </ul>
        <h4 *ngIf="activity.companiesWithCreatedJobOffers.length > 0">Nove potrebe za ljudskim resursima</h4>
        <ul *ngIf="activity.companiesWithCreatedJobOffers.length > 0">
            <li *ngFor="let company of activity.companiesWithCreatedJobOffers">
                <strong>Tvrtka:</strong> {{company.name}}
                <ul>
                    <li *ngFor="let jobOffer of company.jobOffers">
                        {{jobOffer.name}} - vrijeme kreiranja: {{jobOffer.createdAt | date:
                        'dd.MM.yyyy. HH:mm'}}
                    </li>
                </ul>
            </li>
        </ul>
        <h4 *ngIf="activity.publicOrganizationsWithCreatedResearchProblems.length > 0">Novi razvojni prijedlozi javnog sektora</h4>
        <ul *ngIf="activity.publicOrganizationsWithCreatedResearchProblems.length > 0">
            <li *ngFor="let publicOrganization of activity.publicOrganizationsWithCreatedResearchProblems">
                <strong>Javna organizacija:</strong> {{publicOrganization.name}}
                <ul>
                    <li *ngFor="let researchProblem of publicOrganization.researchProblems">
                        {{researchProblem.title}} - vrijeme kreiranja: {{researchProblem.createdAt | date:
                        'dd.MM.yyyy. HH:mm'}}
                    </li>
                </ul>
            </li>
        </ul>
        <h4 *ngIf="activity.scientistsWithCreatedEquipment.length > 0">Nova istraživačka oprema</h4>
        <ul *ngIf="activity.scientistsWithCreatedEquipment.length > 0">
            <li *ngFor="let scientist of activity.scientistsWithCreatedEquipment">
                <strong>Istraživač:</strong> {{scientist.firstname}} {{scientist.lastname}}
                <ul>
                    <li *ngFor="let equipment of scientist.equipment">
                        {{equipment.title}} - vrijeme kreiranja: {{equipment.createdAt | date: 'dd.MM.yyyy. HH:mm'}}
                    </li>
                </ul>
            </li>
        </ul>
        <h4 *ngIf="activity.scientistsWithCreatedSpecificKnowledge.length > 0">Nove usluge i kompetencije istraživača</h4>
        <ul *ngIf="activity.scientistsWithCreatedSpecificKnowledge.length > 0">
            <li *ngFor="let scientist of activity.scientistsWithCreatedSpecificKnowledge">
                <strong>Istraživač:</strong> {{scientist.firstname}} {{scientist.lastname}}
                <ul>
                    <li *ngFor="let specificKnowledge of scientist.specificKnowledge">
                        {{specificKnowledge.title}} - vrijeme kreiranja: {{specificKnowledge.createdAt |
                        date: 'dd.MM.yyyy. HH:mm'}}
                    </li>
                </ul>
            </li>
        </ul>
    </mat-tab>
    <mat-tab label="Ažurirano na platformi">
        <h4 *ngIf="activity.updatedCompanies.length > 0">Ažurirane tvrtke</h4>
        <ul *ngIf="activity.updatedCompanies.length > 0">
            <li *ngFor="let newCompany of activity.updatedCompanies">
                <strong>{{newCompany.name}}</strong> - vrijeme ažuriranja: {{newCompany.updatedAt | date:
                'dd.MM.yyyy.HH:mm'}}
            </li>
        </ul>
        <h4 *ngIf="activity.updatedPublicOrganizations.length > 0">Ažurirane javne organizacije</h4>
        <ul *ngIf="activity.updatedPublicOrganizations.length > 0">
            <li *ngFor="let newPublicOrganization of activity.updatedPublicOrganizations">
                <strong>{{newPublicOrganization.name}}</strong> - vrijeme ažuriranja:
                {{newPublicOrganization.updatedAt | date: 'dd.MM.yyyy. HH:mm'}}
            </li>
        </ul>
        <h4 *ngIf="activity.updatedScientists.length > 0">Ažurirani istraživači</h4>
        <ul *ngIf="activity.updatedScientists.length > 0">
            <li *ngFor="let newScientist of activity.updatedScientists">
                <strong>{{newScientist.firstname}} {{newScientist.lastname}}</strong> -
                vrijeme ažuriranja: {{newScientist.updatedAt | date: 'dd.MM.yyyy. HH:mm'}}
            </li>
        </ul>
        <h4 *ngIf="activity.companiesWithUpdatedResearchProblems.length > 0">Ažurirani razvojni prijedlozi tvrtki</h4>
        <ul *ngIf="activity.companiesWithUpdatedResearchProblems.length > 0">
            <li *ngFor="let company of activity.companiesWithUpdatedResearchProblems">
                <h6><strong>Tvrtka: </strong>{{company.name}}</h6>
                <ul>
                    <li *ngFor="let researchProblem of company.researchProblems">
                        {{researchProblem.title}} - vrijeme ažuriranja: {{researchProblem.updatedAt | date:
                        'dd.MM.yyyy. HH:mm'}}
                    </li>
                </ul>
            </li>
        </ul>
        <h4 *ngIf="activity.companiesWithUpdatedJobOffers.length > 0">Ažurirane potrebe za ljudskim resursima</h4>
        <ul *ngIf="activity.companiesWithUpdatedJobOffers.length > 0">
            <li *ngFor="let company of activity.companiesWithUpdatedJobOffers">
                <strong>Tvrtka:</strong> {{company.name}}
                <ul>
                    <li *ngFor="let jobOffer of company.jobOffers">
                        {{jobOffer.name}} - vrijeme ažuriranja: {{jobOffer.updatedAt | date:
                        'dd.MM.yyyy. HH:mm'}}
                    </li>
                </ul>
            </li>
        </ul>
        <h4 *ngIf="activity.publicOrganizationsWithUpdatedResearchProblems.length > 0">Ažurirani razvojni prijedlozi javnog sektora</h4>
        <ul *ngIf="activity.publicOrganizationsWithUpdatedResearchProblems.length > 0">
            <li *ngFor="let publicOrganization of activity.publicOrganizationsWithUpdatedResearchProblems">
                <strong>Javna organizacija:</strong> {{publicOrganization.name}}
                <ul>
                    <li *ngFor="let researchProblem of publicOrganization.researchProblems">
                        {{researchProblem.title}} - vrijeme ažuriranja: {{researchProblem.updatedAt | date:
                        'dd.MM.yyyy. HH:mm'}}
                    </li>
                </ul>
            </li>
        </ul>
        <h4 *ngIf="activity.scientistsWithUpdatedEquipment.length > 0">Ažurirana istraživačka oprema</h4>
        <ul *ngIf="activity.scientistsWithUpdatedEquipment.length > 0">
            <li *ngFor="let scientist of activity.scientistsWithUpdatedEquipment">
                <strong>Istraživač:</strong> {{scientist.firstname}} {{scientist.lastname}}
                <ul>
                    <li *ngFor="let equipment of scientist.equipment">
                        {{equipment.title}} - vrijeme ažuriranja: {{equipment.updatedAt | date: 'dd.MM.yyyy. HH:mm'}}
                    </li>
                </ul>
            </li>
        </ul>
        <h4 *ngIf="activity.scientistsWithUpdatedSpecificKnowledge.length > 0">Ažurirane usluge i kompetencije istraživača</h4>
        <ul *ngIf="activity.scientistsWithUpdatedSpecificKnowledge.length > 0">
            <li *ngFor="let scientist of activity.scientistsWithUpdatedSpecificKnowledge">
                <strong>Istraživač:</strong> {{scientist.firstname}} {{scientist.lastname}}
                <ul>
                    <li *ngFor="let specificKnowledge of scientist.specificKnowledge">
                        {{specificKnowledge.title}} 
                    </li>
                </ul>
            </li>
        </ul>
    </mat-tab>
</mat-tab-group>