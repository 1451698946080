import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CategoryService } from 'src/app/services/category.service';
import { NotificationService } from 'src/app/services/notification.service';
import { NotificationMessages } from 'src/app/shared/constants/notification-messages';
import { Category } from 'src/app/shared/models/category/category';

@Component({
  selector: 'app-category-search-picker',
  templateUrl: './category-search-picker.component.html',
  styleUrls: ['./category-search-picker.component.css'],
})
export class CategorySearchPickerComponent implements OnInit {
  @Input() parentForm: FormGroup;

  loadingData: boolean;
  categoriesList: Category[];

  constructor(
    private categoryService: CategoryService,
    private notificationService: NotificationService
  ) {}

  ngOnInit() {
    this.loadingData = true;
    this.setCategories();
  }

  onSelectedCategory(): void {
    this.categoryService.pingCategorySelected();
  }

  setCategories(): void {
    this.categoryService.getCategories().subscribe(
      (response) => {
        this.categoriesList = response;
        this.loadingData = false;
      },
      (error) => {
        this.notificationService.errorMessage(NotificationMessages.GET_ERROR);
      }
    );
  }
}
